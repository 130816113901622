import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./DrinkingWater.css";
import jsonData from "./StateViolationCounts.json";
import contaminantData from "./ContaminantMonitoringSummary.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
import UnderConstruction from "../../components/UnderConstruction/UnderConstruction";

function DrinkingWater() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tap Water Database | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Superfund sites within 5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Learn more about the public drinking water systems serving you</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro's
                    </a>{" "}
                    <strong>tap water database</strong>. Gain invaluable insights not only into your drinking water
                    quality but also into 40 other critical environmental factors impacting your home and well-being.
                    From EPA Superfund, Brownfield, and Toxics Release Inventory sites to public drinking water systems,
                    air and noise pollution sources, natural hazards, and livability factors, EnviroHomePro provides
                    comprehensive data to empower you to make informed decisions about your surroundings. Take control
                    of your environmental awareness today and safeguard your health and home with EnviroHomePro's
                    comprehensive reporting. Explore now and ensure a healthier, safer environment for you and your
                    family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Public Water Systems with Health Based Violations by State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Map of PFAS Contaminated Public Water Systems</h2>
                    <img
                        title="EnviroHomePro's PFAS contaminated public water systems"
                        className="map-image"
                        src={mapImage}
                        alt="PFAS contaminated public water systems"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>

                    <div class="superfund-legend">
                        <div className="superfund-legend-item">
                            <div class="circle active-site"></div>
                            <div class="superfund-legend-item">Active NPL Site</div>
                        </div>
                        <div className="superfund-legend-item">
                            <div class="circle proposed-site"></div>
                            <div class="superfund-legend-item">Proposed NPL Site</div>
                        </div>

                        <div className="superfund-legend-item">
                            <div class="circle deleted-site"></div>
                            <div class="superfund-legend-item">Deleted NPL Site</div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">What is a Public Water System</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A public water system refers to any system that provides drinking water to the public for
                            consumption, cooking, bathing, and other domestic uses. These systems are typically operated
                            by government agencies or private entities under government regulation and oversight. The
                            primary goal of a public water system is to deliver safe and clean drinking water to
                            residents within a specific geographic area.
                            <br />
                            <br />
                            Public water systems can vary in size and complexity, ranging from small community wells
                            serving a few hundred people to large municipal systems supplying water to millions of
                            residents. These systems may utilize various water sources, including surface water from
                            rivers, lakes, and reservoirs, as well as groundwater from wells and aquifers.
                            <br />
                            <br />
                            The process of delivering safe drinking water through a public water system involves several
                            key components. Firstly, water is sourced from its origin and transported to a treatment
                            facility where it undergoes purification processes such as filtration, disinfection, and
                            chemical treatment to remove contaminants and pathogens. Next, the treated water is
                            distributed through a network of pipes and storage tanks to homes, businesses, and other
                            consumers.
                            <br />
                            <br />
                            Public water systems are subject to stringent regulations and standards established by
                            governmental agencies, such as the Environmental Protection Agency (EPA) in the United
                            States. These regulations set limits on the levels of various contaminants in drinking water
                            and require regular testing and monitoring to ensure compliance.
                            <br />
                            <br />
                            In addition to providing safe drinking water, public water systems also play a crucial role
                            in protecting public health and promoting sanitation. Adequate access to clean water is
                            essential for preventing waterborne diseases and maintaining overall well-being. Public
                            water systems also support economic development, agriculture, industry, and various other
                            aspects of daily life by supplying water for irrigation, manufacturing, and other purposes.
                            <br />
                            <br />
                            Overall, public water systems are vital infrastructure components that contribute to the
                            health, safety, and quality of life in communities around the world by ensuring reliable
                            access to clean and safe drinking water.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Monitoring Public Water Systmes</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Health violations at public water systems can have significant impacts on public health,
                            environmental quality, and community well-being. These violations occur when water quality
                            standards and regulations are not met, leading to potential risks for consumers. Common
                            health violations in public water systems include contamination by bacteria, viruses,
                            chemicals, and other pollutants, as well as failure to properly disinfect or treat drinking
                            water.
                            <br />
                            <br />
                            The impacts of health violations at public water systems can be far-reaching. Contaminated
                            water can cause immediate health issues such as gastrointestinal illnesses, skin rashes,
                            respiratory problems, and other waterborne diseases. Vulnerable populations such as
                            children, elderly individuals, and those with compromised immune systems are particularly at
                            risk. Long-term exposure to certain contaminants, such as lead or arsenic, can also lead to
                            chronic health conditions like neurological disorders, cancer, and developmental issues.
                            <br />
                            <br />
                            In addition to health effects, water system violations can erode public trust and confidence
                            in the safety of drinking water. Communities may experience economic repercussions due to
                            decreased property values, loss of tourism revenue, and costs associated with remediation
                            efforts. Furthermore, environmental consequences such as contamination of aquatic
                            ecosystems, harm to wildlife, and degradation of water quality can result from water system
                            violations.
                            <br />
                            <br />
                            Monitoring and regulation play crucial roles in identifying and addressing health violations
                            at public water systems. Governmental agencies such as the Environmental Protection Agency
                            (EPA) enforce regulations that set standards for water quality and treatment processes.
                            Public water systems are required to conduct regular testing and monitoring of drinking
                            water to ensure compliance with these standards. When violations occur, corrective actions
                            must be taken promptly, including notification of affected consumers, implementation of
                            treatment upgrades, and enforcement of regulatory measures.
                            <br />
                            <br />
                            Community engagement and transparency are also essential for effective monitoring of public
                            water systems. Regular communication between water authorities, regulatory agencies, and the
                            public helps to identify concerns, address issues, and build trust in the safety of drinking
                            water. By maintaining vigilant monitoring practices and promptly addressing violations,
                            public water systems can uphold their commitment to providing safe and reliable drinking
                            water for all residents.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Contaminants - Fifth Unregulated Contaminant Monitoring Rule Data
                    </h2>
                    <div className="info-block">
                        <SEOTable header="" data={contaminantData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default DrinkingWater;
