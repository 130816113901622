import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import jsonData from "./ParkCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function Parks() {
    const navigate = useNavigate();

    const login = (event) => {
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover National, State, and Local Parks Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Parks within 7 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>
                    Find National, State, and Local Parks Near You and Learn the Benefits
                    </strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover <strong>National, State and Local parks within a 10-mile radius</strong> of your property
                    by leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into parks but also into 40 other critical environmental factors
                    impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics Release Inventory to
                    public drinking water system quality, air and noise pollution sources, natural hazards, and
                    livability factors, EnviroHomePro provides comprehensive data to empower you to make informed
                    decisions about your surroundings. Take control of your environmental awareness today and safeguard
                    your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure a
                    healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        <a>Benefits of Living Near a National, State, or Local Park</a>
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a state or national park offers many benefits that can enhance one's quality of
                            life in numerous ways. From access to outdoor recreation to improved physical and mental
                            well-being, living in close proximity to these natural wonders provides a unique opportunity
                            to connect with nature and enjoy a host of recreational activities.
                            {/* <a
                                className="category-header-link"
                                target="_blank"
                                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5437751/">
                                Studies have shown that exposure to high levels of aircraft noise at night can increase
                                the risk of cardiovascular problems, including hypertension, heart disease, and stroke.
                            </a> */}
                            <br />
                            <br />
                            One of the most significant benefits of living near a state or national park is the easy
                            access to outdoor recreation opportunities. Whether it's hiking, camping, fishing, or
                            wildlife watching, parks offer a diverse range of activities that cater to individuals of
                            all ages and interests. Living nearby allows residents to explore these recreational
                            opportunities on a regular basis, promoting an active and healthy lifestyle.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Are There Other Benefits When Living Near a Park?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Spending time in nature has been shown to have positive effects on mental well-being.
                            Studies have demonstrated that exposure to natural environments can reduce stress, anxiety,
                            and depression, while improving mood and overall mental health. Living near a state or
                            national park provides residents with convenient access to these therapeutic landscapes,
                            allowing them to reap the psychological benefits of spending time in nature.
                            <br />
                            <br />
                            Proximity to a state or national park can increase property values and attract tourists,
                            benefiting local economies and communities. Many people are drawn to areas near parks for
                            their recreational opportunities and scenic beauty, leading to higher demand for real estate
                            and tourism-related businesses. This can result in economic growth, job creation, and
                            increased revenue for local businesses and government agencies.
                            <br />
                            <br />
                            Living near a state or national park offers a host of benefits that contribute to overall
                            health, well-being, and quality of life. From access to outdoor recreation and improved
                            mental health to economic opportunities and environmental education, parks play a vital role
                            in enhancing the lives of residents and communities. As such, proximity to these natural
                            treasures is highly prized by those seeking a lifestyle that embraces the beauty and
                            serenity of the great outdoors.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">National, State, and Local Park Counts per State by Access Type</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Parks;
