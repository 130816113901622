export async function setScoreLimits(settings, setTotalPossibleScores) {
    const categorySumMap = {
        TotalRiskSum: 0,
        TotalBenefitSum: 0,
    };
    // console.dir(coldWavescore)
    settings.forEach((obj) => {
        const { Active, Category, MinScore, MaxScore, Classification, Topic } = obj;

        if (Active) {
            const minScoreKey = `${Category}-MinScore`;
            const maxScoreKey = `${Category}-MaxScore`;

            categorySumMap[minScoreKey] = (categorySumMap[minScoreKey] || 0) + MinScore;
            categorySumMap[maxScoreKey] = (categorySumMap[maxScoreKey] || 0) + MaxScore;

            categorySumMap.TotalRiskSum += MinScore;
            categorySumMap.TotalBenefitSum += MaxScore;
        }
    });

    setTotalPossibleScores(categorySumMap);
}

export async function setScores(
    settings,
    setTotalPossibleScores,

    //Noise
    trainScore,
    trafficScore,
    airportNoiseScore,

    //AirPollution
    landfillScore,
    incineratorScore,
    naturalGasWellScore,
    oilWellScore,
    pulpAndPaperMillScore,
    powerPlantScore,
    refineries,
    trafficAirPollution,

    //Health
    drinkingWaterScore,
    triScore,
    radonScore,
    superfundScore,
    brownfieldScore,
    wastewaterScore,
    formerDefenseScore,
    cellTowerScore,
    transmissionLineScore,
    nuclearPowerPlantScore,
    asbestos,

    //Community
    fireStationScore,
    policeStationScore,
    emergencyMedicalServiceStationScore,
    parkScore,
    hospitalScore,

    //Natural Hazards
    earthquakeScore,
    wildFireScore,
    lightningScore,
    windScore,
    hurricaneScore,
    coastalFloodingscore,
    coldWavescore,
    droughtscore,
    heatWavescore,
    iceStormscore,
    tornadoscore,
    tsunamiscore,
    volcanicActivityscore,
    winterWeatherscore,

    //Overall Scores
    noiseScoreSum,
    airPollutionScoreSum,
    healthScoreSum,
    naturalHazardScoreSum,
    communityScoreSum,
    setNoiseScoreSum,
    setAirPollutionScoreSum,
    setHealthScoreSum,
    setNaturalHazardScoreSum,
    setCommunityScoreSum,
    setOverallScore,
    setDisplayData,

    setNoiseSummary,
    setAirPollutionSummary,
    setHealthAndSafetySummary,
    setNaturalHazardsSummary,
    setLivabilitySummary
) {
    // Define categories and their corresponding scores
    const categories = {
        "Noise Pollution": ["trainScore", "trafficScore", "airportNoiseScore"],
        "Air Pollution and Environmental Concerns": [
            "landfillScore",
            "incineratorScore",
            "naturalGasWellScore",
            "oilWellScore",
            "pulpAndPaperMillScore",
            "powerPlantScore",
            "refineries",
            "trafficAirPollution",
        ],
        "Health and Safety": [
            "drinkingWaterScore",
            "triScore",
            "radonScore",
            "superfundScore",
            "brownfieldScore",
            "wastewaterScore",
            "formerDefenseScore",
            "cellTowerScore",
            "transmissionLineScore",
            "nuclearPowerPlantScore",
            "asbestos",
        ],
        "Natural Hazards": [
            "earthquakeScore",
            "wildFireScore",
            "lightningScore",
            "windScore",
            "hurricaneScore",
            "coastalFloodingscore",
            "coldWavescore",
            "droughtscore",
            "heatWavescore",
            "iceStormscore",
            "tornadoscore",
            "tsunamiscore",
            "volcanicActivityscore",
            "winterWeatherscore",
        ],
        Livability: [
            "policeStationScore",
            "fireStationScore",
            "emergencyMedicalServiceStationScore",
            "parkScore",
            "hospitalScore",
        ],
    };

    //Set Overall Score Totals for each Category
    const categorySumMap = {
        TotalRiskSum: 0,
        TotalBenefitSum: 0,
    };

    settings.forEach((obj) => {
        const { Active, Category, MinScore, MaxScore, Classification, Topic } = obj;

        if (Active) {
            const minScoreKey = `${Category}-MinScore`;
            const maxScoreKey = `${Category}-MaxScore`;

            categorySumMap[minScoreKey] = (categorySumMap[minScoreKey] || 0) + MinScore;
            categorySumMap[maxScoreKey] = (categorySumMap[maxScoreKey] || 0) + MaxScore;

            categorySumMap.TotalRiskSum += MinScore;
            categorySumMap.TotalBenefitSum += MaxScore;
        }
    });

    const calculateCategoryScore = (category, data, totalPossibleScores, scoringFunction) => {
        const categoryScores = categories[category].map((score) => data[score]);
        return scoringFunction(category, categoryScores, categorySumMap);
    };

    const scoringFunction = (category, scores, categorySumMap) => {
        const scoreSum = scores.reduce((sum, score) => sum + score, 0);
        const minScore = categorySumMap[category + "-MinScore"];
        const maxScore = categorySumMap[category + "-MaxScore"];
        // console.dir(category)
        // console.dir(scoreSum)
        // console.dir(minScore)
        // console.dir(maxScore)
        // const percent  = Math.abs(scoreSum) / (maxScore - minScore).toFixed(2);

        const percent = ((scoreSum - minScore) / Math.abs(maxScore - minScore)).toFixed(2);

        // console.dir(percent)
        return 20 * percent;
    };

    //Calculate noise score
    const noiseScore = calculateCategoryScore(
        "Noise Pollution",
        {
            airportNoiseScore,
            trainScore,
            trafficScore,
        },
        categorySumMap,
        scoringFunction
    );
    // console.dir("Noise Score: " + noiseScore)

    const airPollutionScore = calculateCategoryScore(
        "Air Pollution and Environmental Concerns",
        {
            landfillScore,
            incineratorScore,
            naturalGasWellScore,
            oilWellScore,
            pulpAndPaperMillScore,
            powerPlantScore,
            refineries,
            trafficAirPollution,
        },
        categorySumMap,
        scoringFunction
    );
    // console.dir("Air Pollution Score: " + airPollutionScore)

    const healthScore = calculateCategoryScore(
        "Health and Safety",
        {
            drinkingWaterScore,
            triScore,
            radonScore,
            superfundScore,
            brownfieldScore,
            wastewaterScore,
            formerDefenseScore,
            cellTowerScore,
            transmissionLineScore,
            nuclearPowerPlantScore,
            asbestos,
        },
        categorySumMap,
        scoringFunction
    );
    // console.dir("Health Risk Score: " + healthScore)

    const naturalHazardScore = calculateCategoryScore(
        "Natural Hazards",
        {
            earthquakeScore,
            wildFireScore,
            lightningScore,
            windScore,
            hurricaneScore,
            coastalFloodingscore,
            coldWavescore,
            droughtscore,
            heatWavescore,
            iceStormscore,
            tornadoscore,
            tsunamiscore,
            volcanicActivityscore,
            winterWeatherscore,
        },
        categorySumMap,
        scoringFunction
    );
    // console.dir("Natural Hazard Score: " + naturalHazardScore)

    const livabilityScore = calculateCategoryScore(
        "Livability",
        {
            policeStationScore,
            fireStationScore,
            emergencyMedicalServiceStationScore,
            parkScore,
            hospitalScore,
        },
        categorySumMap,
        scoringFunction
    );
    // console.dir("Livability Score: " + livabilityScore)

    setNoiseScoreSum(noiseScore);
    setAirPollutionScoreSum(airPollutionScore);
    setHealthScoreSum(healthScore);
    setNaturalHazardScoreSum(naturalHazardScore);
    setCommunityScoreSum(livabilityScore);
    setTotalPossibleScores(categorySumMap);

    setDisplayData(true);
}

export function getScoreColor(score, minValue, maxValue, type) {
    if (score == null || score == "null") {
        return "rgba(178, 190, 181, .6)";
    }
    let maxRisk = minValue == undefined ? 0 : minValue;
    let maxBenefit = maxValue == undefined ? 0 : maxValue;

    const rankingScale = [
        "rgba(255,0,0, 1)",
        "rgba(255, 167, 0, .7)",
        "rgba(255, 167, 0, .7)",
        "rgba(255, 167, 0, .7)",
        "rgba(255, 244, 0, .7)",
        "rgba(255, 244, 0, .7)",
        "rgba(255, 244, 0, .7)",
        "rgba(163, 255, 0, .7)",
        "rgba(163, 255, 0, .7)",
        "rgba(163, 255, 0, .7)",
        "rgba(44, 186, 0, .7)",
        "rgba(44, 186, 0, .7)",
        "rgba(44, 186, 0, .7)",
    ];

    // Normalize the score within the given range
    const normalizedScore = (score - maxRisk) / (maxBenefit - maxRisk) - 0.01;

    const rankingIndex = Math.min(
        Math.max(Math.floor(normalizedScore * rankingScale.length), 0),
        rankingScale.length - 1
    );

    // Return the ranking
    return rankingScale[rankingIndex];
}

export function getSummaryScoreColor(score, minValue, maxValue, type) {
    if (score == null || score == "null") {
        return "rgba(178, 190, 181, .6)";
    }

    let maxRisk = minValue == undefined ? 0 : minValue;
    let maxBenefit = maxValue == undefined ? 0 : maxValue;

    const rankingScale = [
        "rgba(255,0,0, 1)",
        "rgba(255, 167, 0, .7)",
        "rgba(255, 167, 0, .7)",
        "rgba(255, 167, 0, .7)",
        "rgba(255, 244, 0, .7)",
        "rgba(255, 244, 0, .7)",
        "rgba(255, 244, 0, .7)",
        "rgba(163, 255, 0, .7)",
        "rgba(163, 255, 0, .7)",
        "rgba(163, 255, 0, .7)",
        "rgba(44, 186, 0, .7)",
        "rgba(44, 186, 0, .7)",
        "rgba(44, 186, 0, .7)",
    ];

    // Normalize the score within the given range
    const normalizedScore = (score - maxRisk) / (maxBenefit - maxRisk);

    // Adjust the starting point to 50%
    const percentage = normalizedScore * 100;

    if (percentage >= 90) {
        return "rgba(44, 186, 0, .7)";
    } else if (percentage >= 80) {
        return "rgba(163, 255, 0, .7)";
    } else if (percentage >= 70) {
        return "rgba(255, 244, 0, .7)";
    } else if (percentage >= 60) {
        return "rgba(255, 167, 0, .7";
    } else return "rgba(255, 0, 0, .7)";
}

export function getScoreValue(score, minValue, maxValue, type) {
    if (score == null || score == "null") {
        return "U";
    }

    let maxRisk = minValue == undefined ? 0 : minValue;
    let maxBenefit = maxValue == undefined ? 0 : maxValue;

    const rankingScale = ["F", "D-", "D", "D+", "C-", "C", "C+", "B-", "B", "B+", "A-", "A", "A+"];

    // Normalize the score within the given range
    const normalizedScore = (score - maxRisk) / (maxBenefit - maxRisk) - 0.01;

    const rankingIndex = Math.min(
        Math.max(Math.floor(normalizedScore * rankingScale.length), 0),
        rankingScale.length - 1
    );

    return rankingScale[rankingIndex];
}

export function getSummaryScoreValue(score, minValue, maxValue, type) {
    if (score == null || score == "null") {
        return "U";
    }
 
    let maxRisk = minValue == undefined ? 0 : minValue;
    let maxBenefit = maxValue == undefined ? 0 : maxValue;

    const rankingScale = ["F", "D-", "D", "D+", "C-", "C", "C+", "B-", "B", "B+", "A-", "A", "A+"];

    // Normalize the score within the given range
    // const normalizedScore = (score - maxRisk) / (maxBenefit - maxRisk);
    const normalizedScore  = Math.abs(score) / (maxBenefit - maxRisk);
    console.dir(maxBenefit)
    console.dir(maxRisk)
    console.dir(score)

    // Adjust the starting point to 50%
    const percentage = normalizedScore * 100;

    if (percentage >= 97) {
        return "A+";
    } else if (percentage >= 93) {
        return "A";
    } else if (percentage >= 90) {
        return "A-";
    } else if (percentage >= 87) {
        return "B+";
    } else if (percentage >= 83) {
        return "B";
    } else if (percentage >= 80) {
        return "B-";
    } else if (percentage >= 77) {
        return "C+";
    } else if (percentage >= 73) {
        return "C";
    } else if (percentage >= 70) {
        return "C-";
    } else if (percentage >= 67) {
        return "D+";
    } else if (percentage >= 65) {
        return "D";
    } else if (percentage >= 60) {
        return "D-";
    } else return "F";
}
