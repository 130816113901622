import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import jsonData from "./RefineryCount.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";


function Superfund() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Refineries Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover refineries within 10 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>
                    Find Refineries Near You and Learn the Risks
                    </strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong>refineries within a 10-mile radius</strong> of your property by leveraging the
                    power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into refineries but also into 40 other critical environmental
                    factors impacting your home and well-being. From EPA Brownfield sites to public drinking water
                    systems, air and noise pollution sources, natural hazards, and livability factors, EnviroHomePro
                    provides comprehensive data to empower you to make informed decisions about your surroundings. Take
                    control of your environmental awareness today and safeguard your health and home with
                    EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment for
                    you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        <a
                            className="category-header-link"
                            target="_blank"
                            href="https://www.eia.gov/energyexplained/oil-and-petroleum-products/refining-crude-oil.php">
                            What is a Refinery?
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A refinery is a complex industrial facility designed to process crude oil or other raw
                            materials into various refined products such as gasoline, diesel, jet fuel, heating oil, and
                            petrochemicals. Crude oil is a mixture of hydrocarbons extracted from underground reservoirs
                            and is typically composed of different grades of oil with varying densities and
                            compositions. Refineries play a crucial role in the oil and gas industry by converting crude
                            oil into usable fuels and chemicals that meet specific quality and regulatory standards.
                            <br />
                            <br />
                            The refining process involves several key stages, including distillation, conversion,
                            purification, and blending. During distillation, crude oil is heated and separated into
                            fractions based on their boiling points, with lighter components such as gasoline and diesel
                            rising to the top and heavier components like residual fuel oil settling at the bottom.
                            Conversion processes, such as cracking and reforming, are then used to modify the molecular
                            structure of certain fractions to enhance their properties and increase their value.
                            <br />
                            <br />
                            Purification processes remove impurities such as sulfur, nitrogen, and metals from the
                            refined products to meet environmental regulations and improve performance. Finally,
                            blending involves mixing different refined products and additives to achieve the desired
                            specifications and properties required by end-users.
                            <br />
                            <br />
                            Refineries vary in size and complexity, with some facilities capable of processing hundreds
                            of thousands of barrels of crude oil per day. They are typically located near oil-producing
                            regions, major transportation hubs, or coastal areas with access to shipping routes.
                            Refineries also require extensive infrastructure, including storage tanks, pipelines, and
                            processing units, to facilitate the transportation and distribution of refined products to
                            markets and consumers worldwide. Despite their importance in meeting global energy demand,
                            refineries also pose environmental and health risks due to air and water pollution,
                            greenhouse gas emissions, and the potential for accidents and spills. Effective regulation,
                            monitoring, and mitigation measures are essential to minimize these risks and ensure the
                            safe and sustainable operation of refineries.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Are There Air Pollution Risks Living Near a Refinery?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a refinery exposes residents to significant air pollution risks due to the
                            release of various hazardous pollutants during the refining process. Refineries emit a range
                            of air contaminants, including volatile organic compounds (VOCs), nitrogen oxides (NOx),
                            sulfur dioxide (SO2), particulate matter (PM), and greenhouse gases. These pollutants can
                            contribute to respiratory problems, cardiovascular diseases, and exacerbate existing health
                            conditions such as asthma and bronchitis. Additionally, refinery emissions may contain toxic
                            compounds such as benzene, which is known to cause cancer and other serious health effects.
                            Living in close proximity to a refinery increases the likelihood of exposure to these
                            harmful pollutants, leading to adverse health outcomes for nearby communities. Children, the
                            elderly, and individuals with pre-existing health conditions are particularly vulnerable to
                            the impacts of refinery-related air pollution. Efforts to reduce emissions and improve air
                            quality around refineries are essential to protect public health and mitigate the health
                            risks associated with living near these industrial facilities.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                    Are There Any Other Risks Associated with Living Near a Refinery?
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a refinery poses additional risks beyond air pollution, including potential
                            accidents such as fires, explosions, and chemical releases. Refineries handle hazardous
                            materials and operate complex machinery, increasing the risk of accidents that can endanger
                            nearby residents and workers. These incidents may result from equipment malfunctions, human
                            error, or natural disasters, leading to property damage, injuries, and even loss of life.
                            Furthermore, the presence of refineries can impact property values, with nearby homes
                            experiencing decreased market appeal due to concerns about safety, noise, and environmental
                            pollution. Effective emergency preparedness and response measures are essential to mitigate
                            these risks and protect surrounding communities.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Refinery Count by City, State, and Type</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Superfund;
