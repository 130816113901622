import React, { useState, useMemo } from "react";
import { useSearchParams, useParams, useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import "./ReportSummary.css";
import { Helmet } from "react-helmet";
import Loading from "../../components/Loading/Loading.js";
import Table from "../../components/ReportTable/ReportTable";
import ReportSummaryTitle from "../../components/ReportSummaryTitle/ReportSummaryTitle";
import ReportMenu from "../../components/ReportMenu/ReportMenu";
import { getProximityScore } from "../../service/PropertySearchMetrics.js";
import { Box, IconButton, Tooltip } from "@mui/material";
import { apiCall } from "../../service/UserServices.js";
import { setScoreLimits } from "../../helpers/helpers.js";
import { setScores } from "../../helpers/helpers.js";
import { getScoreColor } from "../../helpers/helpers.js";
import { getScoreValue } from "../../helpers/helpers.js";
import { getSummaryScoreColor } from "../../helpers/helpers.js";
import { getSummaryScoreValue } from "../../helpers/helpers.js";

function ReportSummary() {
    const location = useLocation();
    const { state } = useLocation();
    const [isLoading, setIsLoading] = React.useState(true);
    const [displayData, setDisplayData] = React.useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [sub, setSub] = useState("");
    const id = searchParams.get("id");
    const [selectedTab, setSelectedTab] = useState("summary");
    const [tabHistory, setTabHistory] = useState("summary");

    //States to properly load results.
    //1. Get/Set Topic settings
    //2. Get/Set Individual Topic Results
    //3. Calculate/Set Scores and roll up to Summary
    //4. Set Obj to be used throughout app
    const [topics, setTopics] = useState();
    const [topicsComplete, setTopicsComplete] = useState();
    const [summaryScores, setSummaryScores] = useState();

    const [zipCode, setZipCode] = useState();
    const [lat, setLat] = useState();
    const [long, setLong] = useState();
    const [allResults, setAllResults] = useState();

    //Summary
    const [noiseSummary, setNoiseSummary] = useState();
    const [airPollutionSummary, setAirPollutionSummary] = useState();
    const [healthAndSafetySummary, setHealthAndSafetySummary] = useState();
    const [naturalHazardsSummary, setNaturalHazardsSummary] = useState();
    const [livabilitySummary, setLivabilitySummary] = useState();

    // Noise
    const [trainData, setTrainData] = useState();
    const [trafficNoiseData, setTrafficNoiseData] = useState();
    const [airportNoiseData, setAirportNoiseData] = useState();

    //Air Pollution
    const [incineratorData, setIncineratorData] = useState();
    const [landfillData, setLandfillData] = useState();
    const [naturalGasWellData, setNaturalGasWellData] = useState();
    const [oilWellData, setOilWellData] = useState();
    const [pulpandPaperMillData, setPulpAndPaperMillData] = useState();
    const [refineryData, setRefineryData] = useState();
    const [trafficAirPollutionData, setTrafficAirPollutionData] = useState();

    //Community
    const [policeStationData, setPoliceStationData] = useState();
    const [fireStationData, setFireStationData] = useState();
    const [emergencyMedicalServiceStationData, setEmergencyMedicalServiceStationData] = useState();
    const [parkData, setParkData] = useState();
    const [hospitalData, setHospitalData] = useState();

    //Health
    const [drinkingWaterData, setDrinkingWaterData] = useState();
    const [brownfieldData, setBrownfieldData] = useState();
    const [superfundSiteData, setSuperfundSiteData] = useState();
    const [wastewaterData, setWastewaterData] = useState();
    const [formerDefenseSiteData, setFormerDefenseSiteData] = useState();
    const [powerPlantData, setPowerPlantData] = useState();
    const [triData, setTRIData] = useState();
    const [cellTowerData, setCellTowerData] = useState();
    const [transmissionLineData, setTransmissionLineData] = useState();
    const [nuclearPowerPlantData, setNuclearPowerPlantData] = useState();
    const [asbestosData, setAsbestosData] = useState();
    const [radonData, setRadonData] = useState();

    //Natural Hazard
    const [earthquakeData, setEarthquakeData] = useState();
    const [wildFireData, setWildFireData] = useState();
    const [lightningData, setLightningData] = useState();
    const [windData, setWindData] = useState();
    const [hurricaneData, setHurricaneData] = useState();
    const [coastalFloodingData, setCoastalFloodingData] = useState();
    const [coldWaveData, setColdWaveData] = useState();
    const [droughtData, setDroughtData] = useState();
    const [heatWaveData, setHeatWaveData] = useState();
    const [iceStormData, setIceStormData] = useState();
    const [tornadoData, setTornadoData] = useState();
    const [tsunamiData, setTsunamiData] = useState();
    const [volcanicActivityData, setVolcanicActivityData] = useState();
    const [winterWeatherData, setWinterWeatherData] = useState();

    const [addressLine1, setAddressLine1] = useState();
    const [addressLine2, setAddressLine2] = useState();
    const [city, setCity] = useState();
    const [stateLong, setStateLong] = useState();
    const [postalCode, setPostalCode] = useState();
    const [totalPossibleScores, setTotalPossibleScores] = useState();
    const [noiseScoreSum, setNoiseScoreSum] = useState();
    const [airPollutionScoreSum, setAirPollutionScoreSum] = useState();
    const [healthScoreSum, setHealthScoreSum] = useState();
    const [naturalHazardScoreSum, setNaturalHazardScoreSum] = useState();
    const [livabilityScoreSum, setLivabilityScoreSum] = useState();
    const [overallScore, setOverallScore] = useState();

    // const [noiseTableRows, setNoiseTableRows] = useState();

    // Define a reusable function to calculate the weight
    const calculateWeight = (data, settings, totalPossibleScores) => {
        if (settings !== undefined && data.score !== null) {
            const isScoreOutOfRange = data.score > settings.MaxScore || data.score < settings.MinScore;
            const scoreDifference = Math.abs(settings.MinScore - settings.MaxScore);
            const totalScoreDifference = Math.abs(
                totalPossibleScores[settings.Category + "-MaxScore"] -
                    totalPossibleScores[settings.Category + "-MinScore"]
            );

            if (isScoreOutOfRange) {
                // return (((scoreDifference + data.score) / totalScoreDifference) * 100).toFixed(2) + "%";
                return ((scoreDifference / totalScoreDifference) * 100).toFixed(2) + "%";
            } else {
                return ((scoreDifference / totalScoreDifference) * 100).toFixed(2) + "%";
            }
        } else {
            return "0%";
        }
    };

    React.useEffect(() => {
        const getAddress = async () => {
            const attributes = await Auth.currentUserInfo();
            setSub(attributes.attributes.sub);
            let sub = attributes.attributes.sub;

            let address = await apiCall("/reports/address?", { id, sub });
            
            //Error Handling
            const statuses = [address.status];
            const messages = [address.response?.data ?? null];

            const index = statuses.findIndex((status) => status !== 200);

            if (index !== -1) {
                navigate(`/error?message=${messages[index]}`);
            }
            
            let lat = address.data.address.Lat;
            let long = address.data.address.Long;
            // console.dir(address.data.address)
            setZipCode(address.data.address.PostalCode);
            // console.dir(address.data)
            setLat(address.data.address.Lat);
            setLong(address.data.address.Long);
            setAddressLine1(address.data.address.AddressLine1);
            setAddressLine2(address.data.address.AddressLine2);
            setCity(address.data.address.City);
            setStateLong(address.data.address.State);
            setPostalCode(address.data.address.PostalCode);

            const [
                //Topics
                topicSettings,
            ] = await Promise.all([
                //Topics Settings
                apiCall("/settings/topics?", { sub }),
            ]);

            setTopics(topicSettings.data.topics);

            setScoreLimits(topicSettings.data.topics, setTotalPossibleScores);
        };
        getAddress().catch(console.error);
    }, []);

    const processData = async (apiEndpoint, type, topicId) => {
        // if(type == "EPA Superfund Sites"){
        //     console.dir(type)
        // }
        const response = await apiCall(
            apiEndpoint,
            type === "Public Drinking Water Systems"
            ? { lat, long, zipCode, stateLong, sub }
            : type == "Radon Gas Exposure"
              ? { zipCode, sub }
              : { lat, long, sub }
        );

        // if(type == "EPA Superfund Sites"){
        //     console.dir(type)
        // }
        const settings = topics.find((topic) => topic.Id === topicId);
        return {
            data: {
                type,
                settings: settings,
                score: response.data.score,
                scoreValue: getScoreValue(response.data.score, settings.MinScore, settings.MaxScore, type),
                scoreColor: getScoreColor(response.data.score, settings.MinScore, settings.MaxScore, type),
                weight: calculateWeight(response.data, settings, totalPossibleScores),
                results: response.data.results,
            },
        };
    };

    React.useEffect(() => {
        const setTopicData = async () => {
            const [
                train,
                airport,
                traffic,

                //Air Pollution
                landfill,
                incinerator,
                naturalGasWell,
                oilWell,
                pulpAndPaperMill,
                powerPlant,
                refinery,
                trafficAirPollution,

                //Health
                drinkingWater,
                tri,
                radon,
                superfund,
                brownfield,
                wastewater,
                formerDefenseSite,
                cellTower,
                transmissionLine,
                nuclearPowerPlant,
                asbestos,

                //Community
                fireStation,
                policeStation,
                emergencyMedicalServiceStation,
                park,
                hospital,

                //Natural Hazards
                earthquake,
                wildFire,
                lightning,
                wind,
                hurricane,
                coastalFlooding,
                coldWave,
                drought,
                heatWave,
                iceStorm,
                tornado,
                tsunami,
                volcanicActivity,
                winterWeather,
            ] = await Promise.all([
                processData("/proximity-score/trains?", "Trains", 28),
                processData("/proximity-score/airport?", "Airports", 30),
                processData("/proximity-score/roads?", "Major Roads and Highways", 29),

                //Air Pollution
                processData("/proximity-score/landfill?", "Landfills", 14),
                processData("/proximity-score/incinerator?", "Incinerators", 15),
                processData("/proximity-score/natural-gas-well?", "Natural Gas Wells", 16),
                processData("/proximity-score/oil-well?", "Oil Wells", 17),
                processData("/proximity-score/pulp-and-paper-mill?", "Pulp and Paper Mills", 18),
                processData("/proximity-score/power-plant?", "Power Plants", 13),
                processData("/proximity-score/refineries?", "Refineries", 25),
                processData("/proximity-score/traffic-air-pollution?", "Traffic", 41),

                //Health
                processData("/proximity-score/drinking-water-systems?", "Public Drinking Water Systems", 31),
                processData("/proximity-score/toxics-release-inventory?", "EPA Toxics Release Inventory Sites", 8),
                processData("/proximity-score/radon-data?", "Radon Gas Exposure", 43),
                processData("/proximity-score/superfund-site?", "EPA Superfund Sites", 12),
                processData("/proximity-score/brownfield?", "EPA Brownfield Sites", 11),
                processData("/proximity-score/wastewater-treatment-plant?", "Wastewater Treatment Plants", 9),
                processData("/proximity-score/formerly-used-defense-site?", "Formerly Used Defense Sites", 10),
                processData("/proximity-score/cell-tower?", "Cell Towers", 6),
                processData("/proximity-score/transmission-line?", "High Power Electrical Transmission Lines (EMF)", 7),
                processData("/proximity-score/nuclear-power-plant?", "Nuclear Power Plants", 24),
                processData("/proximity-score/asbestos?", "Asbestos Mines and Occurances", 26),

                //Community
                processData("/proximity-score/fire-station?", "Fire Stations", 2),
                processData("/proximity-score/police-station?", "Police Stations", 3),
                processData(
                    "/proximity-score/emergency-medical-service-station?",
                    "Emergency Medical Service Stations",
                    4
                ),
                processData("/proximity-score/park?", "National, State, City or Local Parks", 5),
                processData("/proximity-score/hospital?", "Hospitals", 1),

                //Natural Hazards
                processData("/proximity-score/earthquake-fault-and-fold?", "Earthquake Fault or Folds", 19),
                processData("/proximity-score/wildfire?", "Wild Fires", 21),
                processData("/proximity-score/lightning?", "Lightning", 22),
                processData("/proximity-score/wind?", "Wind", 23),
                processData("/proximity-score/hurricane?", "Hurricanes", 20),
                processData("/proximity-score/coastal-flooding?", "Coastal Flooding", 32),
                processData("/proximity-score/cold-wave?", "Cold Wave", 33),
                processData("/proximity-score/drought?", "Drought", 34),
                processData("/proximity-score/heat-wave?", "Heat Wave", 35),
                processData("/proximity-score/ice-storm?", "Ice Storm", 36),
                processData("/proximity-score/tornado?", "Tornado", 37),
                processData("/proximity-score/tsunami?", "Tsunami", 38),
                processData("/proximity-score/volcanic-activity?", "Volcanic Activity", 39),
                processData("/proximity-score/winter-weather?", "Winter Weather", 40),
            ]);

            // Noise
            setTrainData(train);
            setTrafficNoiseData(traffic);
            setAirportNoiseData(airport);

            //Air Pollution()
            setIncineratorData(incinerator);
            setLandfillData(landfill);
            setNaturalGasWellData(naturalGasWell);
            setOilWellData(oilWell);
            setPulpAndPaperMillData(pulpAndPaperMill);
            setPowerPlantData(powerPlant);
            setRefineryData(refinery);
            setTrafficAirPollutionData(trafficAirPollution);

            //Community
            setPoliceStationData(policeStation);
            setFireStationData(fireStation);
            setEmergencyMedicalServiceStationData(emergencyMedicalServiceStation);
            setParkData(park);
            setHospitalData(hospital);

            //Health
            setDrinkingWaterData(drinkingWater);
            setTRIData(tri);
            setRadonData(radon);
            setSuperfundSiteData(superfund);
            setBrownfieldData(brownfield);
            setWastewaterData(wastewater);
            setFormerDefenseSiteData(formerDefenseSite);
            setCellTowerData(cellTower);
            setTransmissionLineData(transmissionLine);
            setNuclearPowerPlantData(nuclearPowerPlant);
            setAsbestosData(asbestos);

            //Natural Hazard
            setEarthquakeData(earthquake);
            setWildFireData(wildFire);
            setLightningData(lightning);
            setWindData(wind);
            setHurricaneData(hurricane);
            setCoastalFloodingData(coastalFlooding);
            setColdWaveData(coldWave);
            setDroughtData(drought);
            setHeatWaveData(heatWave);
            setIceStormData(iceStorm);
            setTornadoData(tornado);
            setTsunamiData(tsunami);
            setVolcanicActivityData(volcanicActivity);
            setWinterWeatherData(winterWeather);

            await setScores(
                topics,
                setTotalPossibleScores,
                //Noise
                train.data.score,
                traffic.data.score,
                airport.data.score,

                //Air Pollution
                landfill.data.score,
                incinerator.data.score,
                naturalGasWell.data.score,
                oilWell.data.score,
                pulpAndPaperMill.data.score,
                powerPlant.data.score,
                refinery.data.score,
                trafficAirPollution.data.score,

                //Health
                drinkingWater.data.score,
                tri.data.score,
                radon.data.score,
                superfund.data.score,
                brownfield.data.score,
                wastewater.data.score,
                formerDefenseSite.data.score,
                cellTower.data.score,
                transmissionLine.data.score,
                nuclearPowerPlant.data.score,
                asbestos.data.score,

                //Community
                fireStation.data.score,
                policeStation.data.score,
                emergencyMedicalServiceStation.data.score,
                park.data.score,
                hospital.data.score,

                //Natural Hazards
                earthquake.data.score,
                wildFire.data.score,
                lightning.data.score,
                wind.data.score,
                hurricane.data.score,
                coastalFlooding.data.score,
                coldWave.data.score,
                drought.data.score,
                heatWave.data.score,
                iceStorm.data.score,
                tornado.data.score,
                tsunami.data.score,
                volcanicActivity.data.score,
                winterWeather.data.score,

                //Overall
                noiseScoreSum,
                airPollutionScoreSum,
                healthScoreSum,
                naturalHazardScoreSum,
                livabilityScoreSum,
                setNoiseScoreSum,
                setAirPollutionScoreSum,
                setHealthScoreSum,
                setNaturalHazardScoreSum,
                setLivabilityScoreSum,
                setOverallScore,
                setDisplayData,
                setNoiseSummary,
                setAirPollutionSummary,
                setHealthAndSafetySummary,
                setNaturalHazardsSummary,
                setLivabilitySummary
            );

            setTopicsComplete(true);
        };
        if (topics !== undefined) {
            setTopicData();
        }
    }, [topics]);

    //Calculate Summary Scores
    React.useEffect(() => {
        async function setSummaryData(score, type, value) {
            // const settings = topics.find((topic) => topic.Id === topicId);
            if (type != "Overall") {
                let maxScore = 20;
                let minScore = 0;
                return {
                    data: {
                        type,
                        score: score,
                        scoreValue: getScoreValue(score, minScore, maxScore, type),
                        scoreColor: getScoreColor(score, minScore, maxScore, type),
                        weight: "20%",
                        value: value,
                    },
                };
            } else {
                let maxScore = 100;
                let minScore = 0;
                return {
                    data: {
                        type,
                        score: score,
                        scoreValue: getScoreValue(score, minScore, maxScore, type),
                        scoreColor: getScoreColor(score, minScore, maxScore, type),
                        // weight: "20%",
                        // value: value
                    },
                };
            }
        }

        const setResults = async () => {
            setNoiseSummary(await setSummaryData(noiseScoreSum, "Noise Pollution", "noise"));
            setAirPollutionSummary(
                await setSummaryData(airPollutionScoreSum, "Air Pollution and Environmental Concerns", "airPollution")
            );
            setHealthAndSafetySummary(await setSummaryData(healthScoreSum, "Health and Safety", "healthRisks"));
            setNaturalHazardsSummary(await setSummaryData(naturalHazardScoreSum, "Natural Hazards", "naturalHazard"));
            setLivabilitySummary(await setSummaryData(livabilityScoreSum, "Livability", "livability"));
            let totalScore =
                noiseScoreSum + airPollutionScoreSum + healthScoreSum + naturalHazardScoreSum + livabilityScoreSum;
            setOverallScore(await setSummaryData(totalScore, "Overall"));

            setSummaryScores(true);
        };
        if (topicsComplete) {
            setResults();
        }
    }, [topicsComplete]);

    //Set Object sent to PDF builder
    React.useEffect(() => {
        const setResultsObject = async () => {
            setAllResults({
                Summary: [overallScore],
                Overall: [
                    noiseSummary,
                    airPollutionSummary,
                    healthAndSafetySummary,
                    naturalHazardsSummary,
                    livabilitySummary,
                ],
                "Noise Pollution": [trainData, trafficNoiseData, airportNoiseData],
                "Air Pollution and Environmental Concerns": [
                    landfillData,
                    incineratorData,
                    naturalGasWellData,
                    oilWellData,
                    pulpandPaperMillData,
                    powerPlantData,
                    refineryData,
                    trafficAirPollutionData,
                ],
                "Health and Safety": [
                    drinkingWaterData,
                    triData,
                    radonData,
                    superfundSiteData,
                    brownfieldData,
                    wastewaterData,
                    formerDefenseSiteData,
                    cellTowerData,
                    transmissionLineData,
                    nuclearPowerPlantData,
                    asbestosData,
                ],
                Livability: [
                    fireStationData,
                    policeStationData,
                    emergencyMedicalServiceStationData,
                    parkData,
                    hospitalData,
                ],
                "Natural Hazards": [
                    earthquakeData,
                    wildFireData,
                    lightningData,
                    windData,
                    hurricaneData,
                    coastalFloodingData,
                    coldWaveData,
                    droughtData,
                    heatWaveData,
                    iceStormData,
                    tornadoData,
                    tsunamiData,
                    volcanicActivityData,
                    winterWeatherData,
                ],
            });
            setIsLoading(false);
        };
        if (summaryScores && overallScore != undefined) {
            setResultsObject();
        }
    }, [summaryScores]);

    const handleTabChange = (event) => {
        //Summary table clicks are different than select
        if (event.target.value == undefined) {
            setSelectedTab(event.currentTarget.dataset.value);
        } else {
            setSelectedTab(event.target.value);
        }
    };

    const handleBackButton = () => {
        if (selectedTab == "summary") {
        }
        setSelectedTab("summary");
    };

    return isLoading ? (
        <Loading title="Report Summary"/>
    ) : (
        <div className="remaining-page">
                 <Helmet>
                <title>Report Summary | EnviroHomePro</title>
            </Helmet>
            <div className="report-page">
                <div className="ranking-group">
                    <div className={`tab-panel ${selectedTab === "summary" ? "active" : ""}`} id="summary-panel">
                        <ReportSummaryTitle
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            city={city}
                            state={stateLong}
                            postalCode={postalCode}
                            overallScore={overallScore.data.score}
                            scoreValue={overallScore.data.scoreValue}
                            scoreColor={overallScore.data.scoreColor}
                            totalPossibleScores={totalPossibleScores}
                            type="Summary"
                        />
                        <ReportMenu
                            reportData={allResults}
                            overallScore={overallScore}
                            totalPossibleScores={totalPossibleScores}
                            handleTabChange={handleTabChange}
                            handleBackButton={handleBackButton}
                            selectedTab={selectedTab}
                            lat={lat}
                            long={long}
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            city={city}
                            stateLong={stateLong}
                            postalCode={postalCode}
                        />
                        <Table
                            handleTabChange={handleTabChange}
                            tableType="Summary"
                            rows={[
                                noiseSummary.data,
                                airPollutionSummary.data,
                                healthAndSafetySummary.data,
                                naturalHazardsSummary.data,
                                livabilitySummary.data,
                            ]}
                            totalPossibleScores={totalPossibleScores}
                        />
                    </div>
                </div>
                <div className={`tab-panel ${selectedTab === "noise" ? "active" : ""}`} id="noise-panel">
                    <ReportSummaryTitle
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        city={city}
                        state={stateLong}
                        postalCode={postalCode}
                        overallScore={noiseSummary.data.score}
                        scoreValue={noiseSummary.data.scoreValue}
                        scoreColor={noiseSummary.data.scoreColor}
                        totalPossibleScores={totalPossibleScores}
                        type="Noise Pollution"
                    />
                    <ReportMenu
                        reportData={allResults}
                        overallScore={overallScore}
                        totalPossibleScores={totalPossibleScores}
                        handleTabChange={handleTabChange}
                        handleBackButton={handleBackButton}
                        selectedTab={selectedTab}
                        lat={lat}
                        long={long}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        city={city}
                        stateLong={stateLong}
                        postalCode={postalCode}
                    />
                    <div>
                        <Table
                            handleTabChange={handleTabChange}
                            tableType="Noise Pollution"
                            rows={[trainData.data, trafficNoiseData.data, airportNoiseData.data]}
                            totalPossibleScores={totalPossibleScores}
                        />
                    </div>
                    {/* <HeatMap lat={lat} long={long}/> */}
                </div>
                <div className={`tab-panel ${selectedTab === "airPollution" ? "active" : ""}`} id="air-pollution-panel">
                    <ReportSummaryTitle
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        city={city}
                        state={stateLong}
                        postalCode={postalCode}
                        overallScore={airPollutionSummary.data.score}
                        scoreValue={airPollutionSummary.data.scoreValue}
                        scoreColor={airPollutionSummary.data.scoreColor}
                        totalPossibleScores={totalPossibleScores}
                        type="Air Pollution and Environmental Concerns"
                    />
                    <ReportMenu
                        reportData={allResults}
                        overallScore={overallScore}
                        totalPossibleScores={totalPossibleScores}
                        handleTabChange={handleTabChange}
                        handleBackButton={handleBackButton}
                        selectedTab={selectedTab}
                        lat={lat}
                        long={long}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        city={city}
                        stateLong={stateLong}
                        postalCode={postalCode}
                    />
                    <div>
                        <Table
                            handleTabChange={handleTabChange}
                            tableType="Air Pollution"
                            rows={[
                                landfillData.data,
                                incineratorData.data,
                                powerPlantData.data,
                                refineryData.data,
                                naturalGasWellData.data,
                                oilWellData.data,
                                pulpandPaperMillData.data,
                                trafficAirPollutionData.data,
                            ]}
                            totalPossibleScores={totalPossibleScores}
                        />
                    </div>
                </div>
                <div className={`tab-panel ${selectedTab === "healthRisks" ? "active" : ""}`} id="health-risks-panel">
                    <ReportSummaryTitle
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        city={city}
                        state={stateLong}
                        postalCode={postalCode}
                        overallScore={healthAndSafetySummary.data.score}
                        scoreValue={healthAndSafetySummary.data.scoreValue}
                        scoreColor={healthAndSafetySummary.data.scoreColor}
                        totalPossibleScores={totalPossibleScores}
                        type="Health and Safety"
                    />
                    <div>
                        <ReportMenu
                            reportData={allResults}
                            overallScore={overallScore}
                            totalPossibleScores={totalPossibleScores}
                            handleTabChange={handleTabChange}
                            handleBackButton={handleBackButton}
                            selectedTab={selectedTab}
                            lat={lat}
                            long={long}
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            city={city}
                            stateLong={stateLong}
                            postalCode={postalCode}
                        />
                        <Table
                            handleTabChange={handleTabChange}
                            tableType="Health Risks"
                            rows={[
                                drinkingWaterData.data,
                                triData.data,
                                radonData.data,
                                superfundSiteData.data,
                                brownfieldData.data,
                                nuclearPowerPlantData.data,
                                asbestosData.data,
                                wastewaterData.data,
                                formerDefenseSiteData.data,
                                cellTowerData.data,
                                transmissionLineData.data,
                            ]}
                            totalPossibleScores={totalPossibleScores}
                        />
                    </div>
                </div>
                <div
                    className={`tab-panel ${selectedTab === "naturalHazard" ? "active" : ""}`}
                    id="natural-hazard-panel">
                    <ReportSummaryTitle
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        city={city}
                        state={stateLong}
                        postalCode={postalCode}
                        overallScore={naturalHazardsSummary.data.score}
                        scoreValue={naturalHazardsSummary.data.scoreValue}
                        scoreColor={naturalHazardsSummary.data.scoreColor}
                        totalPossibleScores={totalPossibleScores}
                        type="Natural Hazards"
                    />
                    <div>
                        <ReportMenu
                            reportData={allResults}
                            overallScore={overallScore}
                            totalPossibleScores={totalPossibleScores}
                            handleTabChange={handleTabChange}
                            handleBackButton={handleBackButton}
                            selectedTab={selectedTab}
                            lat={lat}
                            long={long}
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            city={city}
                            stateLong={stateLong}
                            postalCode={postalCode}
                        />
                        <Table
                            handleTabChange={handleTabChange}
                            tableType="Natural Hazards"
                            rows={[
                                earthquakeData.data,
                                wildFireData.data,
                                lightningData.data,
                                windData.data,
                                hurricaneData.data,
                                coastalFloodingData.data,
                                coldWaveData.data,
                                droughtData.data,
                                heatWaveData.data,
                                iceStormData.data,
                                tornadoData.data,
                                tsunamiData.data,
                                volcanicActivityData.data,
                                winterWeatherData.data,
                            ]}
                            totalPossibleScores={totalPossibleScores}
                        />
                    </div>
                </div>
                <div className={`tab-panel ${selectedTab === "livability" ? "active" : ""}`} id="livability-panel">
                    <ReportSummaryTitle
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        city={city}
                        state={stateLong}
                        postalCode={postalCode}
                        overallScore={livabilitySummary.data.score}
                        scoreValue={livabilitySummary.data.scoreValue}
                        scoreColor={livabilitySummary.data.scoreColor}
                        totalPossibleScores={totalPossibleScores}
                        type="Livability"
                    />
                    <div>
                        <ReportMenu
                            reportData={allResults}
                            overallScore={overallScore}
                            totalPossibleScores={totalPossibleScores}
                            handleTabChange={handleTabChange}
                            handleBackButton={handleBackButton}
                            selectedTab={selectedTab}
                            lat={lat}
                            long={long}
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            city={city}
                            stateLong={stateLong}
                            postalCode={postalCode}
                        />
                        <Table
                            handleTabChange={handleTabChange}
                            tableType="Livability"
                            rows={[
                                parkData.data,
                                hospitalData.data,
                                policeStationData.data,
                                emergencyMedicalServiceStationData.data,
                                fireStationData.data,
                            ]}
                            totalPossibleScores={totalPossibleScores}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportSummary;
