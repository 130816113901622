import * as React from "react";
import { Helmet } from "react-helmet";
import { useSearchParams, useParams, useNavigate, useLocation } from "react-router-dom";
import "./Error.css";

function Error() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const message = searchParams.get("message");

    return (
        <div className="error-screen">
            <Helmet>
                <title>Error | EnviroHomePro</title>
            </Helmet>
            <h1 className="error-h1">Houston we have a problem!</h1>
            <h2 className="error-h2">Please refresh the page and try again.</h2>
            <p className="error-message">
                <br />
                If the issue continues, please email info@envirohomepro.com with the error message below:
            </p>
            <p className="error-message-details">Error Message: {message}</p>
        </div>
    );
}

export default Error;
