import axios, { isCancel, AxiosError } from "axios";

export async function apiCall(url, params, method = "GET") {
    try {
        const apiUrl = process.env.REACT_APP_BACKEND_URL + url;
        const headers = {};

        if (process.env.REACT_APP_ENV === "PROD") {
            // add production headers if needed
            headers["origin"] = "https://propertysearch.envirohomepro.com";
        }

        
        const axiosConfig = {
            method: method,
            url: apiUrl,
            headers: headers,
        };

        if (method.toUpperCase() === "GET") {
            axiosConfig["params"] = params;
        } else {
            axiosConfig["data"] = params;
        }
        const response = await axios(axiosConfig);
        const results = response;
        return results;
    } catch (error) {
        console.error(error);
        return error;
    }
}
