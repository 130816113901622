import "./ReportSummaryTitle.css";
import { getScoreColor } from "../../helpers/helpers.js";
import { getScoreValue } from "../../helpers/helpers.js";

const ReportSummaryTitle = (props) => {
    let hasPlusOrMinus = null;

    if (props.type == "Map") {
        return (
            <>
                <h1 className="report-summary-address-header">
                    {props.addressLine1} {props.addressLine2}{" "}
                    <p className="report-summary-sub-header">
                        {props.city}, {props.state}, {props.postalCode}
                    </p>
                </h1>
            </>
        );
    } else {
        hasPlusOrMinus = props.scoreValue.includes("-") || props.scoreValue.includes("+");

        return (
            <>
                <h1 className="report-summary-address-header">
                    {props.addressLine1} {props.addressLine2}{" "}
                    <p className="report-summary-sub-header">
                        {props.city}, {props.state}, {props.postalCode}
                    </p>
                    <b>
                        <div className="cateogory-score">
                            {props.type == "Summary" ? "Overall" : props.type} Rating:{" "}
                            <p
                                className="cateogory-score-background"
                                style={{
                                    backgroundColor: props.scoreColor,
                                }}>
                                <span className={hasPlusOrMinus ? "offset" : ""}>{props.scoreValue} </span>
                                {/* <span id="score-value">{props.overallScore}</span> */}
                            </p>
                        </div>
                    </b>
                </h1>
            </>
        );
    }
};

export default ReportSummaryTitle;
