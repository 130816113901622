
import React, { useState } from "react";
import "./EnviroHomePro.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const EnviroHomePro = (props) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const images = [
        "https://envirohomepro-media.s3.amazonaws.com/SampleImage.png",
        "https://envirohomepro-media.s3.amazonaws.com/SampleImage2.png",
        "https://envirohomepro-media.s3.amazonaws.com/SampleImage3.png",
        // Add more image URLs as needed
    ];

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
    };

    return (
        <div className="envirohomepro-section">
            <h2 className="envirohomepro-h2">
                Ready to dive deeper? Learn more about your property with <b className="envirohomepro-logo"></b>!
            </h2>

            <div className="envirohomepro-description">
                <p className="paragraph">
                    Discover your property's true potential with our cutting-edge <b>EnviroHomePro</b> technology. Our
                    expert environmental and data engineers have meticulously gathered data from reputable sources,
                    including the EPA, Homeland Security, U.S. Energy Information Administration, and FEMA, to create
                    your comprehensive property score. Whether you're looking to purchase, invest, or build, our
                    in-depth analysis empowers you to make confident decisions. Unlock the possibilities today!
                </p>
                <p className="paragraph">
                    With over 30 years in the Environmental Engineering field, <b>EnviroHomePro</b> is bringing this
                    experience to the residential sector. Using one of the largest consolidated environmental databases
                    in the country, rest comfortable knowing you have the best information regarding your property.
                </p>
            </div>
            <div className="app-image">
                <div className="slideshow">
                    <button className="slide-button" id="left-slide-button" onClick={prevSlide}><IoIosArrowBack className="slide-icon"/></button>
                    <img src={images[currentSlide]} alt={`Slide ${currentSlide + 1}`} className="sampleEnviroHomeImages"/>
                    <button className="slide-button" id="right-slide-button" onClick={nextSlide}><IoIosArrowForward className="slide-icon"/></button>
                </div>
                {/* <img className="sampleMapImage" src={mapImage} alt="EnviroHomePro Map Example" /> */}
            </div>
            <a className="appLink envirohomepro-link" href="/">
                Learn More!
            </a>
        </div>
    );
};

export default EnviroHomePro;
