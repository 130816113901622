import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet";
import { apiCall } from "../../service/UserServices.js";
import "./PurchaseConfirmation.css";
import { BsCheck2Circle } from "react-icons/bs";
import Loading from "../../components/Loading/Loading.js";


function SuccessDisplay() {
    const [searchParams, setSearchParams] = useSearchParams();
    const success = searchParams.get("success");
    const session_id = searchParams.get("session_id");
    const [reportId, setReportId] = useState("");
    const [stripeDetails, setStripeDetails] = useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const getUserData = async () => {
            const attributes = await Auth.currentUserInfo();
            let sub = attributes.attributes.sub;

            const data = {
                sub: sub,
                session: session_id,
            };

            let apiResponse = await apiCall("/users/stripe-id?", { sub });

            let response = await apiCall("/users/reports/single?", data, "POST");
            setReportId(response.data.id);
            setStripeDetails(true);
        };
        getUserData().catch(console.error);
    }, []);

    const goToReport = async () => {
        navigate({
            pathname: "/residential-environmental-search/report-summary",
            search: createSearchParams({
                id: reportId,
            }).toString(),
        });
    };

    return !stripeDetails ? (
        <Loading title="Purchase Confirmation"/>
    ) : (
        <div className="purchase-confirmation-screen">
                <Helmet>
                <title>Purchase Confirmation | EnviroHomePro</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="purchase-confirmation-div">
                <div>
                    <BsCheck2Circle className="purchase-confirmation-check" />
                    <h3 className="purchase-confirmation-header">Your purchase is complete!</h3>
                    <p className="purchase-confirmation-text">
                        To view your report, click on the button below.
                        <br />
                        Reciepts, invoices and previous reports can be found in your account dashboard.
                    </p>
                    <a onClick={(event) => goToReport(reportId)} className="purchase-confirmation-report-link">
                        View Report
                    </a>
                </div>
            </div>
        </div>
    );
}

export default SuccessDisplay;
