import "./ErrorDisplay.css";
import { BiError } from "react-icons/bi";

const ErrorDisplay = (props) => {
    return (
        <div className="loading-error">
            <BiError className="loading-error-icon" />
            <p className="loading-error-message">
                Unable to load your {props.type},
                <br />
                refresh page to try again.{" "}
            </p>
        </div>
    );
};

export default ErrorDisplay