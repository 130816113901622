import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Superfund.css";
import jsonData from "./ProposedNPLSites.json";
import contaminantData from "./Contaminants.json";
import stateCountData from "./StateCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function Superfund() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Superfund Sites Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Superfund sites within 5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Superfund Sites Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> Superfund sites within a 5-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into Superfund sites but also into 40 other critical
                    environmental factors impacting your home and well-being. From Brownfield sites to public
                    drinking water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            <div className="info-div">
                    <div className="map-image-div">
                        <h2 className="info-section-h2">Superfund Site Map</h2>
                        <img title="EnviroHomePro's Superfund Site Map" className="map-image" src="https://envirohomepro-media.s3.amazonaws.com/EPASuperfundSiteMap.png" alt="Superfund site map by NPL status" />
                        <p className="attribute-text">© Mapbox, © OpenStreetMap</p>

                        <div class="superfund-legend">
                            <div className="superfund-legend-item">
                                <div class="circle active-site"></div>
                                <div class="superfund-legend-item">Active NPL Site</div>
                            </div>
                            <div className="superfund-legend-item">
                                <div class="circle proposed-site"></div>
                                <div class="superfund-legend-item">Proposed NPL Site</div>
                            </div>

                            <div className="superfund-legend-item">
                                <div class="circle deleted-site"></div>
                                <div class="superfund-legend-item">Deleted NPL Site</div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        What is a{" "}
                        <a className="category-header-link" href="https://www.epa.gov/Superfund" target="_blank">
                            Superfund Site
                        </a>
                        ?
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A Superfund site is an area designated by the United States Environmental Protection Agency
                            (EPA) for cleanup and remediation due to hazardous contamination. These sites typically
                            contain pollutants or toxins that pose significant risks to human health and the
                            environment. The term "Superfund" originates from the Comprehensive Environmental Response,
                            Compensation, and Liability Act (CERCLA) of 1980, which established a federal program to
                            address abandoned or uncontrolled hazardous waste sites across the country.
                            <br />
                            <br />
                            Superfund sites can vary widely in size, scope, and complexity. They may include former
                            industrial facilities, landfills, mining sites, chemical plants, or areas where hazardous
                            substances were illegally dumped or released. The contaminants found at these sites can
                            range from heavy metals like lead and mercury to industrial solvents, pesticides, and
                            radioactive materials.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">How Does a Property Become Listed?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            The process of designating a site as a Superfund site typically begins with the
                            identification of potential contamination through site assessments, environmental
                            monitoring, or reports of pollution from concerned individuals or organizations. Once a site
                            is determined to pose a significant risk to human health or the environment, it may be
                            proposed for inclusion on the National Priorities List (NPL), which is a list of the most
                            hazardous waste sites in the United States eligible for long-term cleanup under the
                            Superfund program.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Proposed NPL Superfund Sites</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                    What Happens Once a Site Is Listed on the National Priority List?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Being listed on the NPL grants a superfund site access to federal resources for
                            investigation, cleanup, and remediation efforts. The cleanup process at Superfund sites is
                            often complex and can involve a combination of techniques such as excavation, soil and
                            groundwater treatment, containment, and long-term monitoring. The goal of these efforts is
                            to eliminate or reduce the risks posed by hazardous substances, restore affected ecosystems,
                            and protect public health and safety.
                            <br />
                            <br />
                            Community involvement and stakeholder engagement are integral parts of the Superfund
                            process. The EPA encourages collaboration with local residents, businesses, tribal nations,
                            and other stakeholders to ensure that cleanup decisions take into account community
                            concerns, cultural considerations, and economic impacts. Public meetings, outreach efforts,
                            and opportunities for public comment are often organized to solicit input from affected
                            communities throughout the cleanup process.
                            <br />
                            <br />
                            In addition to cleanup activities, the Superfund program also addresses liability and
                            financial responsibility for remediation efforts. The "polluter pays" principle is a central
                            tenet of the program, holding responsible parties accountable for the costs associated with
                            investigating and cleaning up contaminated sites. This may include current or former owners
                            and operators of the site, as well as parties that contributed to the pollution through
                            waste disposal or other activities.
                            <br />
                            <br />
                            Despite the progress made in cleaning up Superfund sites since the inception of the program,
                            challenges remain. Many sites require long-term monitoring and maintenance to ensure that
                            contamination does not reoccur or spread to surrounding areas. Funding shortages, legal
                            disputes, and technical complexities can also hinder cleanup efforts, prolonging the time
                            and resources needed to address these environmental challenges effectively. Superfund sites
                            represent a legacy of industrialization and environmental degradation in the United States.
                            The Superfund program plays a vital role in identifying, assessing, and remedying hazardous
                            waste sites to protect human health and the environment. Through collaboration, innovation,
                            and sustained effort, the EPA and its partners continue to work towards the goal of ensuring
                            that all communities have access to clean and safe environments for generations to come.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Counts by State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={stateCountData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Health Risks</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near an EPA Superfund site poses significant health risks due to potential exposure
                            to hazardous substances and pollutants. These sites often contain a variety of toxic
                            chemicals, heavy metals, and other contaminants, which can leach into the air, soil, and
                            water, posing serious health hazards to nearby residents. Exposure to these pollutants has
                            been linked to various health issues, including respiratory problems, neurological
                            disorders, cancer, reproductive issues, and developmental delays, among others. Children,
                            pregnant women, the elderly, and individuals with pre-existing health conditions are
                            particularly vulnerable. Additionally, long-term exposure to contaminated environments can
                            have lasting health effects, even after the site has been remediated. Therefore, living near
                            an EPA Superfund site requires heightened awareness, proactive measures to minimize
                            exposure, and ongoing monitoring of health risks to ensure the well-being of affected
                            communities.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Contaminants</h2>
                    <div className="info-block">
                        <SEOTable header="" data={contaminantData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Statistics</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <h3 className="info-section-h3">
                                Timelines - Proposed NPL addition to being deleted from the NPL
                            </h3>
                            Average duration: 16 years
                            <br />
                            Minimum duration: 9 months
                            <br />
                            Maximum duration: 40 years
                            <h3 className="info-section-h3">
                                Top 3 states with the highest percentage of Superfund sites
                            </h3>
                            1. New Jersey: 152 sites - 8.31%
                            <br />
                            2. Pennsylvania: 126 sites - 6.89%
                            <br />
                            3. New York: 122 sites - 6.67%
                            <h3 className="info-section-h3">
                                Top 3 states or districts with the lowest percentage of Superfund sites
                            </h3>
                            1. District of Columbia: 1 site - 0.05%
                            <br />
                            2. North Dakota: 2 sites - 0.10%
                            <br />
                            3. Nevada: 2 sites - 0.10%
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Superfund;
