import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./CellTowers.css";
import jsonData from "./CellTowerCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function CellTowers() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Cell Towers Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover cellular towers within 1.5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Cell Towers Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> cellular towers within a 1.5 mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into natural gas wells but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics
                    Release Inventory sites to public drinking water systems, air and noise pollution sources, natural
                    hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to make
                    informed decisions about your surroundings. Take control of your environmental awareness today and
                    safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure
                    a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 5 Cell Tower Licensees by State & Number of Towers</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Cell Towers & RF radiation</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            The widespread use of cell phones in recent decades has led to a large increase in the
                            number of cell phone towers (also known as base stations) being placed in communities. These
                            towers have electronic equipment and antennas that receive and transmit cell phone signals
                            using radiofrequency (RF) waves. When cell phone signals are transmitted back and forth to
                            cell towers, the RF waves at the tower radiate into the environment, where people can be
                            exposed to them. Exposure to high levels of RF waves can be harmful because of the ability
                            of the waves to heat up body tissues, but the levels of energy used by cell phones and
                            towers are much lower and are not known to cause significant heating. Other health effects
                            from low-level exposure have not been proven, but it's generally agreed that more research
                            needs to be done in this area.
                            <br />
                            <br />
                            On the electromagnetic spectrum, RF waves fall between FM radio waves and microwaves. RF
                            waves are a form of non-ionizing radiation, which means they do not directly damage DNA
                            inside cells. Stronger, or ionizing, types of radiation — x-rays, gamma rays and ultraviolet
                            rays — are thought to cause cancer through direct damage to cell DNA. More research is
                            needed, but there is not a lot of evidence to support the idea that living, working or going
                            to school or day care near a cell tower increases the risk of cancer or other health issues.
                            National and international health agencies have not classified RF waves from cell towers as
                            cancer-causing. The International Agency for Research on Cancer does consider RF waves more
                            generally as potentially carcinogenic, so more research is needed.
                            <br />
                            <br />
                            The FCC limits public exposure from cell towers to a maximum of 580 microwatts per square
                            centimeter. A person would have to be in the main transmitting beam and within a few feet of
                            the antennae for several minutes or longer to be exposed to RF levels near that limit. RF
                            wave exposure in the vicinity of a tower or near a tower's base is generally much lower. In
                            other words, it's very unlikely that a person could be exposed to RF levels exceeding the
                            limit just by being near a cell phone tower. According to the FDA, the limit set by the FCC
                            is acceptable for protecting public health. RF waves can potentially interfere with several
                            types of medical devices, including pacemakers and insulin pumps, depending on the strength
                            of the waves. Research shows cell phones themselves do not seem to pose a significant health
                            risk for pacemaker wearers.
                            <br />
                            <br />
                            At this time, there’s no strong evidence that exposure to RF waves from cell phone towers
                            causes any noticeable health effects. However, this does not mean that the RF waves from
                            cell phone towers have been proven to be absolutely safe. Most expert organizations agree
                            that more research is needed to help clarify this, especially for any possible long-term
                            effects.
                            <br />
                            <br />
                            <b>What the International community says about RF radiation in general:</b>
                            <br />
                            Based on a review of studies published up until 2011, the International Agency for Research
                            on Cancer (IARC) has classified RF radiation as “possibly carcinogenic to humans,” based on
                            limited evidence of a possible increase in risk for brain tumors among cell phone users, and
                            inadequate evidence for other types of cancer. (For more information on the IARC
                            classification system, see Known and Probable Human Carcinogens.)
                            <br />
                            <br />
                            More recently, the US Food and Drug Administration (FDA) issued a technical report based on
                            results of studies published between 2008 and 2018, as well as national trends in cancer
                            rates. The report concluded: “Based on the studies that are described in detail in this
                            report, there is insufficient evidence to support a causal association between
                            radiofrequency radiation (RFR) exposure and [tumor formation].”
                            <br />
                            <br />
                            So far, the National Toxicology Program (NTP) has not included RF radiation in its Report on
                            Carcinogens, which lists exposures that are known to be or reasonably anticipated to be
                            human carcinogens.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default CellTowers;
