import React, { useState, useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { apiCall } from "../../service/UserServices.js";
import { BsCheck2Circle } from "react-icons/bs";
import Loading from "../../components/Loading/Loading.js";
import { Helmet } from "react-helmet";

// http://www.localhost:3000/account-confirmation?session={CHECKOUT_SESSION_ID}
function SubscriptionConfirmation() {
    const [searchParams, setSearchParams] = useSearchParams();
    const session = searchParams.get("session");
    const [stripeDetails, setStripeDetails] = useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const getUserData = async () => {
            const attributes = await Auth.currentUserInfo();
            let sub = attributes.attributes.sub;
            const data = {
                sub: attributes.attributes.sub,
                session: session,
            };
            //Verify Stripe Webhook has updated user records
            let apiResponse = await apiCall("/users/stripe-id?", { sub });
            if (apiResponse.stripeId == undefined) {
                let response = await apiCall("/users/subscriptions?", data, "POST");
                if (response) {
                    setStripeDetails(true);
                }
            } else {
                setStripeDetails(true);
            }
        };
        getUserData().catch(console.error);
    }, []);

    const createReport = async () => {
        navigate({
            pathname: "/residential-environmental-search",
        });
    };

    return !stripeDetails ? (
        <Loading title="Subscription Confirmation"/>
    ) : (
        <div className="purchase-confirmation-screen">
                 <Helmet>
                <title>Subscription Confirmation | EnviroHomePro</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="purchase-confirmation-div">
                <div>
                    <BsCheck2Circle className="purchase-confirmation-check" />
                    <h3 className="purchase-confirmation-header">Thank you for subscribing!</h3>
                    <p className="purchase-confirmation-text">
                        Start creating reports by clicking the button below.
                        <br />
                        Reciepts, invoices and previous reports can be found in your account dashboard.
                    </p>
                    <a onClick={(event) => createReport()} className="purchase-confirmation-report-link">
                        Create Report
                    </a>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionConfirmation;
