import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./ToxicsReleaseInventory.css";
import jsonData from "./TRIChemicals.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function ToxicsReleaseInventory() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>EPA Toxics Release Inventory Sites Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover EPA Toxics Release Inventory sites within 2 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find EPA Toxics Release Inventory Sites Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> EPA Toxics Release Inventory sites within a 2-mile radius</strong> of your
                    property by leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into Brownfield sites but also into 40 other critical
                    environmental factors impacting your home and well-being. From Brownfield sites to public drinking
                    water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">EPA Toxics Release Inventory Site Map</h2>
                    <img
                        title="EnviroHomePro's EPA Toxics Release Invenotry Site Map"
                        className="map-image"
                        src="https://envirohomepro-media.s3.amazonaws.com/EPAToxicsReleaseInventorySiteMap.png"
                        alt="EPA Toxics Release Invenotry Site Map"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        What is the{" "}
                        <a
                            className="category-header-link"
                            href="https://www.epa.gov/toxics-release-inventory-tri-program"
                            target="_blank">
                            EPA's Toxics Release Inventory
                        </a>
                        ?
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            The Toxics Release Inventory (TRI) is a public database managed by the United States
                            Environmental Protection Agency (EPA) that provides information on the release and transfer
                            of toxic chemicals from industrial facilities across the United States. Established under
                            the Emergency Planning and Community Right-to-Know Act (EPCRA) in 1986, the TRI serves as a
                            vital tool for promoting environmental awareness, empowering communities, and facilitating
                            informed decision-making.
                            <br />
                            <br />
                            Under TRI regulations, certain industries are required to report their annual emissions of
                            over 650 designated toxic chemicals, including pollutants such as lead, mercury, benzene,
                            and dioxins. This reporting encompasses direct releases to the environment, such as air and
                            water discharges, as well as transfers of chemicals to waste management facilities or
                            off-site recycling.
                            <br />
                            <br />
                            The data collected through TRI provides valuable insights into the environmental impact of
                            industrial activities, allowing policymakers, researchers, and the public to track trends in
                            chemical usage and pollution over time. Additionally, TRI data supports efforts to mitigate
                            risks to public health and the environment by informing regulatory decisions, facilitating
                            pollution prevention strategies, and fostering community engagement and advocacy for
                            environmental justice.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Health Risks Associated with Living Near a TRI Site?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a Toxics Release Inventory (TRI) site can have significant health impacts due to
                            potential exposure to toxic chemicals released into the environment. TRI facilities are
                            industrial sites required to report their emissions of over 650 designated toxic chemicals
                            to the United States Environmental Protection Agency (EPA). These chemicals, including
                            carcinogens, neurotoxins, and reproductive toxins, pose risks to human health even at low
                            levels of exposure.
                            <br />
                            <br />
                            Exposure to TRI emissions can occur through various pathways, such as inhalation of polluted
                            air, ingestion of contaminated water or food, and dermal contact with contaminated soil or
                            surfaces. This exposure can lead to a range of health problems, including respiratory
                            issues, neurological disorders, reproductive complications, and increased risk of cancer.
                            Vulnerable populations, such as children, pregnant women, and the elderly, are particularly
                            susceptible to the adverse effects of toxic chemical exposure.
                            <br />
                            <br />
                            Living near a TRI site can also contribute to environmental justice concerns, as
                            disadvantaged communities disproportionately bear the burden of exposure to pollution and
                            its associated health risks. Addressing these health impacts requires robust monitoring,
                            regulation, and community engagement to mitigate exposure, promote public awareness, and
                            advocate for policies that prioritize environmental and public health.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Reported TRI Chemicals</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ToxicsReleaseInventory;
