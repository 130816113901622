import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Checkout.css";
import { Auth } from "aws-amplify";
import { apiCall } from "../../service/UserServices.js";
import Loading from "../../components/Loading/Loading.js";
import { Helmet } from "react-helmet";

function ProductDisplay() {
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const attributes = Auth.currentUserInfo();
    const [sub, setSub] = React.useState();
    const [email, setEmail] = React.useState();
    const [perReportProduct, setPerReportProduct] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const getData = async () => {
            const attributes = await Auth.currentUserInfo();
            setSub(attributes.attributes.sub);
            setEmail(attributes.attributes.email);

            const [products] = await Promise.all([apiCall("/settings/products?")]);

            //Error Handling
            const statuses = [products.status];
            const messages = [products.response?.data ?? null];
            const index = statuses.findIndex((status) => status !== 200);

            if (index !== -1) {
                navigate(`/error?message=${messages[index]}`);
            }

            setPerReportProduct(products.data.products.find((obj) => obj.Id === 1));
            setIsLoading(false);
        };

        getData().catch(console.error);
    }, []);

    let address = location.state.address;
    let address1 = location.state.address.address1;
    let addressLine2 = location.state.addressLine2;
    let formattedAddress = location.state.address.formattedAddress;
    let city = location.state.address.locality;
    let adminArea1Long = location.state.address.adminArea1Long;
    let postal = location.state.address.postalCode;
    let lat = location.state.address.lat;
    let long = location.state.address.long;

    return isLoading ? (
        <Loading title="Checkout"/>
    ) : (
        <section>
            <Helmet>
                <title>Checkout | EnviroHomePro</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="checkout-div">
                <div className="product-div">
                    <div className="product">
                        <div className="product-address">
                            <h2 className="product-address-header">Property Details</h2>
                            <p className="product-address-line-item">
                                <p className="product-address-line-header">Address</p> {address1}
                            </p>
                            {addressLine2 != "" ? (
                                <p className="product-address-line-item">
                                    <p className="product-address-line-header">Optional</p>
                                    {addressLine2}
                                </p>
                            ) : null}
                            <p className="product-address-line-item">
                                <p className="product-address-line-header">City, State</p>
                                {city}, {adminArea1Long}
                            </p>
                            <p className="product-address-line-item">
                                <p className="product-address-line-header">Postal Code</p>
                                {postal}
                            </p>
                            <p className="product-address-line-item">
                                <p className="product-address-line-header">Latitude</p>
                                {lat}
                            </p>
                            <p className="product-address-line-item">
                                <p className="product-address-line-header">Longitude</p>
                                {long}
                            </p>
                        </div>
                        <div className="description">
                            <h3 className="plan-title">Property Report</h3>
                            <h5 className="purchase-price">Price: ${perReportProduct.Price}</h5>
                            <form
                                action={process.env.REACT_APP_BACKEND_URL + "/stripe/create-checkout-session"}
                                enctype="multipart/form-data"
                                method="POST">
                                <input type="hidden" name="lookup_key" value={perReportProduct.ProductId} />
                                <input type="hidden" name="sub" value={sub} />
                                <input type="hidden" name="address" value={address1} />
                                <input type="hidden" name="addressLine2" value={addressLine2} />
                                <input type="hidden" name="city" value={city} />
                                <input type="hidden" name="state" value={adminArea1Long} />
                                <input type="hidden" name="postal" value={postal} />
                                <input type="hidden" name="lat" value={lat} />
                                <input type="hidden" name="long" value={long} />
                                <input type="hidden" name="customer_email" value={email} />
                                <button id="checkout-button" type="submit">
                                    Purchase
                                </button>
                                <p className="checkout-disclaimer">
                                    EnviroHomePro does not store any PII or banking information as we partner with
                                    Stripe for all payment processing and account management. Receipts and Invoices can
                                    be found in Stripes customer portal which can be accessed from the Account
                                    dashboard.
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductDisplay;
