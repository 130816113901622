import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-questions">
                <p>
                    Questions? <br/>Drop us a line at <b><a href="mailto:info@envirohomepro.com">info@envirohomepro.com</a></b>
                </p>
            </div>
            <div className="footer-col-div">
                <div className="footer-col">
                    <ul>
                    <li className="footer-link-li">
                            <a target="_blank" href="/terms-of-service" className="footer-link">Terms and Conditions</a>
                        </li>
                        <li className="footer-link-li">
                            <a target="_blank" href="/privacy-policy" className="footer-link">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-col">
                    <ul> 
                        <li className="footer-link-li">
                            <a target="_blank" href="/noise-pollution" className="footer-link">Noise Pollution</a>
                        </li>
                        <li className="footer-link-li">
                            <a target="_blank" href="/air-pollution" className="footer-link">Air Pollution</a>
                        </li>
                        <li className="footer-link-li">
                            <a target="_blank" href="/health-risks" className="footer-link">Health & Safety Risks</a>
                        </li>
                        <li className="footer-link-li">
                            <a target="_blank" href="/livability" className="footer-link">Livability</a>
                        </li>
                        <li className="footer-link-li">
                            <a target="_blank" href="/natural-hazards" className="footer-link">Natural Hazards</a>
                        </li>
                    </ul>
                </div>
                {/* <div className="footer-col">
                    <ul>
                        <li>
                           <a href="tel:888-810-2228" className="footer-link">Call: 888-810-2228</a>
                        </li>
                    </ul>
                </div> */}
            </div>
            <div className="footer-copyright">
                <p className="copyright-Text">
                © 2024 Copyright: EnviroHomePro.com </p>
                <p className="copyright-Text">an <a target="_blank" className="copyright-link" href="https://www.envirohome.com">EnviroHome</a> Product 
                </p>
            </div>
        </footer>
    );
};

export default Footer;
