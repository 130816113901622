import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useNavigate, useSearchParams, Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./BlogPage.css";
import Loading from "../../components/Loading/Loading.js";
import SEOTable from "../../components/SEOTable/SEOTable.js";
import EnviroHomePro from "../../components/EnviroHomePro/EnviroHomePro";
import { blogPosts } from "./BlogPosts.js";

function BlogPage() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { category, topic } = useParams();
    const [isTopicValid, setIsTopicValid] = useState("pending");
    const [isCategoryValid, setIsCategoryValid] = useState("pending");
    const [isLoading, setIsLoading] = useState("true");
    const [tableData, setTableData] = useState(null);

    async function loadData(topic, blogPosts) {
        let post = blogPosts.filter((post) => post.topic === topic);
        // Load JSON data dynamically based on the topic
        let obj = await import(post[0].data);
        let data = obj.default;
        setTableData(data);
    }

    //Check url pattern leads to valid category or topic
    useEffect(() => {
        // Check if the topic exists in the blog array
        const topicExists = blogPosts.find((post) => post.topic === topic);
        setIsTopicValid(!!topicExists); // !! converts truthy/falsy values to true/false
        // Check if the category exists in the blog array
        const categoryExists = blogPosts.find((post) => post.category === category);
        setIsCategoryValid(!!categoryExists); // !! converts truthy/falsy values to true/false
    }, []); // Empty dependency array ensures this effect runs only once, on component mount

    useEffect(() => {
        if (isTopicValid !== "pending" && isCategoryValid !== "pending") {
            if (!isTopicValid && isCategoryValid) {
                // If topic is invalid but category exists, navigate to the category page
                navigate(`/blog/${category}`);
            } else if (!isTopicValid && !isCategoryValid) {
                // If neither topic nor category is valid, navigate to the default blog page
                navigate(`/blog`);
            }
            loadData(topic, blogPosts);
            setIsLoading(false);
        }
    }, [isTopicValid, isCategoryValid]);

    const filteredPosts = useMemo(() => {
        if (topic) return blogPosts.filter((post) => post.topic === topic);
        if (category) return blogPosts.filter((post) => post.category === category);
        return blogPosts;
    }, [topic, category]);

    const getCategoryTitle = useCallback((category) => {
        const categoryMap = {
            "health-and-safety": "Health & Safety",
            "noise-pollution": "Noise Pollution",
            "air-pollution": "Air Pollution",
            livability: "Livability",
            "natural-hazards": "Natural Hazards",
            // Add more mappings as needed
        };
        return categoryMap[category] || category;
    }, []);

    const handleCategoryClick = useCallback(
        (category) => {
            setSearchParams({});
            navigate(`/blog/${category}`);
        },
        [navigate, setSearchParams]
    );

    const handleCardClick = useCallback(
        (topic, category) => {
            setSearchParams({ topic, category });
            navigate(`/blog/${category}/${topic}`);
        },
        [navigate, setSearchParams]
    );

    const handleCTAClick = (e) => {
        navigate(`/`);
    };

    const Breadcrumb = ({ category, topic }) => {
        return (
            <div className="breadcrumb-menu">
                <Link className="breadcrumb-item" to="/blog">
                    All
                </Link>
                {category && (
                    <>
                        <span> &gt; </span>
                        <span className="breadcrumb-item" onClick={() => handleCategoryClick(category)}>
                            {getCategoryTitle(category)}
                        </span>
                    </>
                )}
                {topic && (
                    <>
                        <span> &gt; </span>
                        <span className="breadcrumb-item">{topic}</span>
                    </>
                )}
            </div>
        );
    };

    const BlogCategories = () => {
        const categories = ["health-and-safety", "noise-pollution", "air-pollution", "livability", "natural-hazards"];

        return (
            <div className="blog-categories">
                {categories.map((cat) => (
                    <div
                        key={cat}
                        className={`category-card ${category === cat ? "selected-category" : ""}`}
                        onClick={() => handleCategoryClick(cat)}>
                        <h2 className={`category-card-title ${category === cat ? "selected-category-title" : ""}`}>
                            {getCategoryTitle(cat)}
                        </h2>
                    </div>
                ))}
            </div>
        );
    };

    const renderSinglePostDetails = (post) => (
        <div className="blog-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{post.title} | EnviroHomePro</title>
                <meta name="description" content={post.metaDescription} />
            </Helmet>
            <BlogCategories />
            <Breadcrumb category={post.category} topic={post.title} />
            <div className="blog-cta-div">
                <a className="button blog-cta-button" onClick={(e) => handleCTAClick(e)}>
                    {post.ctaMessage}
                                </a>
            </div>
            <div className="blog-hero-section">
                <h1 className="blog-h1">
                    <strong>{post.h1}</strong>
                </h1>
                <p className="blog-published-date">Published: {post.date}</p>

                <div className="blog-paragraph">{post.p1}</div>

                {post.data !== "" && (
                    <div className="table-div">
                        <h2 className="blog-h2">{post.dataHeader}</h2>
                        <SEOTable data={require(`${post.data}`)} />
                    </div>
                )}
                {/* <div className="blog-div"> */}
                {/* <img
                    title={post.thumbnailTitle}
                    className="blog-picture"
                    src={require(`../../images/${post.thumbnail}`)}
                    alt={post.thumbnailAlt}
                /> */}
                {/* </div> */}
            </div>
            {/* <EnviroHomePro /> */}
        </div>
    );

    const renderCategoryPosts = () => {
        const categoryTitle = getCategoryTitle(category);

        return (
            <div className="blog-page">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{category ? `${categoryTitle} Blog | EnviroHomePro` : "All Posts | EnviroHomePro"}</title>
                    <meta
                        name="description"
                        content={
                            category
                                ? `Learn more about ${categoryTitle} | EnviroHomePro`
                                : "All blog posts | EnviroHomePro"
                        }
                    />
                </Helmet>
                <BlogCategories />
                <Breadcrumb category={category} />

                {filteredPosts.length === 0 ? (
                    <div className="no-posts-message">
                        No posts available, please check back again later or try a different topic!
                    </div>
                ) : (
                    <div className="category-grid">
                        {filteredPosts.map((post) => (
                            <div
                                key={post.topic}
                                className="category-item"
                                onClick={() => handleCardClick(post.topic, post.category)}>
                                <img
                                    title={post.thumbnailTitle}
                                    className="blog-thumbnail"
                                    loading="lazy"
                                    src={post.thumbnail}
                                    alt={post.thumbnailAlt}
                                />
                                <h2 className="thumbnail-category">{post.thumbnailCategory}</h2>
                                <h2 className="thumbnail-title">{post.h1}</h2>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return isLoading ? (
        <Loading title="Dashboard" />
    ) : topic ? (
        renderSinglePostDetails(filteredPosts[0])
    ) : (
        renderCategoryPosts()
    );
}

export default BlogPage;
