import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./TransmissionLines.css";
// import jsonData from "./CellTowerCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function TransmissionLines() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Transmission Lines Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover transmission lines within a half mile of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Transmission Lines Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> transmission lines within a half mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into natural gas wells but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics
                    Release Inventory sites to public drinking water systems, air and noise pollution sources, natural
                    hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to make
                    informed decisions about your surroundings. Take control of your environmental awareness today and
                    safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure
                    a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            {/* <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 5 Cell Tower Licensees by State & Number of Towers</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Transmission Lines & Electromagnetic Fields</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Power lines are one of many sources of electromagnetic fields in our environment. Power
                            lines carry energy created by power plants to your home or business. The constant flow of
                            electricity through these transmission lines creates low-frequency, non-ionizing radiation.
                            Electromagnetic fields like those from power lines decrease dramatically as you move or live
                            farther away from them. At a distance of 300 feet, and with average electricity demand,
                            magnetic fields from power transmission lines are similar to magnetic fields found in your
                            home, according to a 2002 report by the National Institute of Environmental Health Sciences
                            and the National Institutes of Health.
                            <br />
                            <br />
                            <b>What does the research say about whether power lines can impact cancer risk?</b>
                            <br />
                            Concerns about power lines and cancer heightened after a 1979 study found a relationship
                            between power lines and childhood cancer. However, other studies in the 2000s did not find
                            an association or found an association only in homes with very high levels of magnetic
                            forces, which is rare, according to the National Cancer Institute.
                            <br />
                            <br />
                            In 2005, researchers at the University of Oxford found an association between the distance
                            from power lines and childhood cancer in Britain. Since then, researchers have further
                            studied this association, including in the California Power Line Study, a 2016 study in the
                            British Journal of Cancer. Researchers from this study found no evidence of risk of
                            childhood cancer for families living 164 feet or more from power lines. And, at most,
                            researchers found weak support for increased risk of childhood cancer for those living
                            closer than 164 feet.
                            <br />
                            <br />
                            <b>Are there other health risks associated with electromagnetic fields?</b>
                            <br />
                            Exposure to low-level electromagnetic fields has been studied extensively, and there is no
                            evidence that it is harmful to human health, according to the World Health Organization. In
                            fact, according to the WHO website, most scientists and doctors agree that if there are any
                            health effects from low-level electromagnetic fields, they are likely to be very small
                            compared to other health risks that people face in daily life.
                            <br />
                            <br />
                            Other agencies, like the European Commission Scientific Committee on Emerging and Newly
                            Identifiable Health Risks, take a more cautious stance. They say that it’s possible, but far
                            from conclusive, that extremely low-frequency magnetic fields could be carcinogenic, or have
                            the potential to cause cancer, in a 2015 report.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TransmissionLines;
