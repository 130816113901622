import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import jsonData from "./AirportCount.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function Airports() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Airports Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Airports within 7 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>
                    Find Airports Near You and Learn the Risks
                                        </strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover <strong>airports within a 7-mile radius</strong> of your property by leveraging the power
                    of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into airport but also into 40 other critical environmental
                    factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics Release
                    Inventory to public drinking water system quality, air and noise pollution sources, natural hazards,
                    and livability factors, EnviroHomePro provides comprehensive data to empower you to make informed
                    decisions about your surroundings. Take control of your environmental awareness today and safeguard
                    your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure a
                    healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        <a>Noise Pollution Living Near an Airport</a>
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Noise pollution from airplanes is more than just an annoyance; it can have profound effects
                            on physical health, mental well-being, and overall quality of life for those living in close
                            proximity to airports. The constant barrage of aircraft noise can disrupt sleep patterns,
                            leading to fatigue, irritability, and cognitive impairment.{" "}
                            <a
                                className="category-header-link"
                                target="_blank"
                                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5437751/">
                                Studies have shown that exposure to high levels of aircraft noise at night can increase
                                the risk of cardiovascular problems, including hypertension, heart disease, and stroke.
                            </a>
                            <br />
                            <br />
                            Moreover, the incessant noise from airplanes can interfere with daily activities and social
                            interactions, making it difficult to concentrate, communicate, or relax. Simple tasks such
                            as reading a book, watching television, or enjoying a meal can become challenging endeavors
                            in the midst of constant noise pollution. For children, the noise can disrupt learning and
                            development, affecting academic performance and social behavior.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Are There Other Risks When Living Near an Airport?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            The detrimental effects of aircraft noise extend beyond individual health and well-being to
                            encompass entire communities. Property values in areas near airports often decline due to
                            the perceived negative impact of noise pollution, making it difficult for homeowners to sell
                            their properties or attract new buyers. Businesses located in these areas may also suffer,
                            as the noise can deter customers and drive away potential clients.
                            <br />
                            <br />
                            Additionally, the adverse effects of aircraft noise can be felt disproportionately by
                            vulnerable populations, including children, the elderly, and individuals with pre-existing
                            health conditions. For example, children exposed to high levels of aircraft noise may
                            experience delays in speech and language development, as well as behavioral problems such as
                            hyperactivity and aggression. Similarly, elderly individuals may be more susceptible to the
                            negative effects of noise pollution due to age-related hearing loss and other health issues.
                            <br />
                            <br />
                            Noise pollution from airplanes is a pervasive and significant problem for those living near
                            airports. From disrupted sleep and impaired cognitive function to decreased property values
                            and social isolation, the adverse effects of aircraft noise can have far-reaching
                            consequences for individuals and communities alike.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Airport Counts Per State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Airports;
