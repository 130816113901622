export const reportDescriptions = [
    {
        Topic: "Public Drinking Water Systems",
        Title: "Public Drinking Water System Recommendation",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    If the rating for the PWS is anything less than “A” find out what contaminants are present in the
                    water supply. Get a detailed summary of the contaminants present and their chemical toxicity to
                    determine if special filtration systems (RO, whole house, etc.) are needed or bottled water may be
                    needed to protect you and your family.
                </p>
            </>,
        ],
    },
    {
        Topic: "EPA Toxics Release Inventory Sites",
        Title: "EPA Toxics Release Inventory Information",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    The{" "}
                    <a target="_blank" href="https://www.epa.gov/toxics-release-inventory-tri-program">
                        Toxic Release Inventory (TRI)
                    </a>{" "}
                    is a public database that chronicles toxic chemical releases, transfers, waste management, and
                    pollution-prevention activities from registered facilities throughout the United States.
                </p>
                <p className="text-align-left" key="paragraph2">
                    The TRI tracks the management of certain toxic chemicals that may pose a threat to human health and
                    the environment where released. Approximately
                    <a
                        target="_blank"
                        href="https://www.epa.gov/toxics-release-inventory-tri-program/tri-listed-chemicals">
                        {" "}
                        695 chemicals and chemical categories{" "}
                    </a>
                    are included in the reporting list, based on acute or chronic human-health or environmental effects.
                    Facilities that manufacture, process, or otherwise use these chemicals in amounts above established
                    levels must report how they manage each chemical. The information submitted by facilities to the EPA
                    and states is compiled and stored in a publicly accessible database. U.S. facilities in different
                    industry sectors must submit an annual report on:
                    <li className="tab">The amount of each listed chemical released to the air, water, or soil</li>
                    <li className="tab">The amount of each listed chemical recycled, treated, or disposed</li>
                    <li className="tab">Source reduction activities undertaken to prevent pollution</li>
                </p>
                <p className="text-align-left" key="paragraph3">
                    Note: materials on the TRI are “released” when the company that produces or processes them emits
                    them into the air or water or disposes of them in a landfill or similar holding facility. The TRI
                    data provides us with a starting point for assessing environmental conditions and potential risks to
                    residents that may live, work or commute in close proximity to these facilities.
                </p>
            </>,
        ],
    },
    {
        Topic: "EPA Superfund Sites",
        Title: "EPA Superfund Site Background",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    Thousands of contaminated sites exist nationally due to hazardous waste being dumped, left out in
                    the open, or otherwise improperly managed. These sites include manufacturing facilities, processing
                    plants, landfills and mining sites.
                </p>
                <p className="text-align-left" key="paragraph2">
                    In the late 1970s, toxic waste dumps such as{" "}
                    <a
                        target="_blank"
                        href="https://cumulis.epa.gov/supercpad/cursites/csitinfo.cfm?id=0201290&msspp=med">
                        Love Canal
                    </a>{" "}
                    and{" "}
                    <a target="_blank" href="https://cumulis.epa.gov/supercpad/cursites/csitinfo.cfm?id=0402072">
                        Valley of the Drums
                    </a>{" "}
                    received national attention when the public learned about the risks to human health and the
                    environment posed by contaminated sites. In response, Congress established the Comprehensive
                    Environmental Response, Compensation and Liability Act (CERCLA) in 1980.
                </p>
                <p className="text-align-left" key="paragraph3">
                    CERCLA is informally called Superfund. It allows the EPA to clean up contaminated sites. It also
                    forces the parties responsible for the contamination to either perform cleanups or reimburse the
                    government for EPA-led cleanup work. When there is no viable responsible party, Superfund gives EPA
                    the funds and authority to clean up contaminated sites. There are 40,000 federal Superfund sites
                    across the country, and approximately 1,300 of those sites have been listed on the{" "}
                    <a target="_blank" href="https://en.wikipedia.org/wiki/National_Priorities_List">
                        National Priorities List (NPL)
                    </a>
                    . Sites on the NPL are considered the most highly contaminated and undergo longer-term remedial
                    investigation and remedial action (cleanups).
                </p>
            </>,
        ],
    },
    {
        Topic: "EPA Brownfield Sites",
        Title: "EPA Brownfield Site Background",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    A brownfield is a property, the expansion, redevelopment, or reuse of which may be complicated by
                    the presence or potential presence of a hazardous substance, pollutant, or contaminant. It is
                    estimated that there are more than 450,000 brownfields in the U.S. Cleaning up and reinvesting in
                    these properties increases local tax bases, facilitates job growth, utilizes existing
                    infrastructure, takes development pressures off of undeveloped, open land, and both improves and
                    protects the environment.
                </p>
            </>,
        ],
    },
    {
        Topic: "Nuclear Power Plants",
        Title: "Nuclear Power Plant Information",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    As a nuclear power plant produces electricity, its fuel becomes very radioactive. High levels of
                    radiation are dangerous if released outside the nuclear reactor. To protect the workers and people
                    living nearby, this radiation must be kept inside the reactor and away from the environment.
                </p>
                <p className="text-align-left" key="paragraph2">
                    Nuclear power plants have safety and security procedures in place and are closely monitored by the
                    Nuclear Regulatory Commission (NRC). While an accident at a nuclear power plant is very rare, it
                    could result in the release of dangerous levels of radiation over a local area such as what occurred
                    during the accidents at Chernobyl (Ukraine), Fukushima (Japan) and Three Mile Island in
                    Pennsylvania.
                </p>

                <p className="text-align-left" key="paragraph3">
                    If you live near a nuclear power plant, you can get emergency information materials from the power
                    company that operates your local nuclear power plant or your local emergency services office.
                </p>
                <p className="text-align-left" key="paragraph4">
                    Unlike fossil fuel-fired power plants, nuclear power plants do not produce air pollution or carbon
                    dioxide while operating. These power generating units are generally considered a greener alternative
                    to coal, oil or gas electrical power generation. As such, while the environmental concerns
                    associated with nuclear power plants are low the public safety concern associated with a nuclear
                    power plant are higher. Homeowners should be aware of the presence of a nuclear power plant within
                    close proximity to one’s home.
                </p>
            </>,
        ],
    },
    {
        Topic: "Wastewater Treatment Plants",
        Title: "Wastewater Treatment Plant Information",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    Almost every non-rural community has a water treatment plant. When low pressure sets in or the wind
                    blows in the right direction, the stench associated with biological gas biproducts from a WWTP can
                    be horrific. The WWTP can not only be a source of local air pollution, but also a major odor
                    nuisance and chemical hazard to the local community.
                </p>

                <p className="text-align-left" key="paragraph2">
                    The main gases of concern in wastewater treatment plants are{" "}
                    <a
                        target="_blank"
                        href="https://www.indsci.com/en/industrial-scientific-gas-types-methane-ch4?hsLang=en">
                        methane
                    </a>
                    , and{" "}
                    <a
                        target="_blank"
                        href="https://www.indsci.com/en/industrial-scientific-gas-types-hydrogen-sulfide-h2s?hsLang=en">
                        hydrogen sulfide
                    </a>
                    . Hydrogen sulfide and methane are the byproducts of the decomposition of organic materials that
                    exist in the waste flows feeding the plant. These are the primary sources of odors from WWTP, but
                    many other gas hazards exist. Beyond these gas hazards are the dangers that exist from purifying
                    chemicals used to decontaminate waste and effluent water, including{" "}
                    <a target="_blank" href="https://www.indsci.com/en/ammonia-detector-nh3?hsLang=en">
                        ammonia
                    </a>
                    ,{" "}
                    <a
                        target="_blank"
                        href="https://www.indsci.com/en/industrial-scientific-gas-types-chlorine-cl2?hsLang=en">
                        chlorine
                    </a>
                    ,{" "}
                    <a
                        target="_blank"
                        href="https://www.indsci.com/en/industrial-scientific-gas-types-chlorine-dioxide-clo2?hsLang=en">
                        chlorine dioxide
                    </a>
                    , or ozone. Elimination of these gas hazards is virtually impossible, so permanent facilities must
                    depend on reliable gas detection equipment to protect the local community.
                </p>
                <p className="text-align-left" key="paragraph3">
                    Wastewater treatment plants do handle dangerous chemicals and compounds that would be highly
                    damaging if they were released into the local water table, or into the atmosphere as gases. There
                    are many different types of wastewater plants out there — some handle human or animal waste, while
                    others handle industrial run-off. Some are civic projects, while others are privately owned. What’s
                    more, different types of plants handle different chemicals — from{" "}
                    <a target="_blank" href="https://www.epa.gov/nutrientpollution/sources-and-solutions-wastewater">
                        nitrogen and phosphorus
                    </a>{" "}
                    to detergents.
                </p>
                <p className="text-align-left" key="paragraph4">
                    The truth is, without these wastewater plants, these hazardous substances would be released into the
                    local environment. The whole point of these facilities is to provide an important line of defense
                    against ecological contamination and damage. As long as the treatment plant is properly managed and
                    operated, it represents an important asset that keeps our waterways, atmosphere, and natural
                    habitats safe.
                </p>
            </>,
        ],
    },
    {
        Topic: "Cell Towers",
        Title: "Cell Tower and Radiofrequency (RF) Waves",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    The widespread use of cell phones in recent decades has led to a large increase in the number of
                    cell phone towers (also known as base stations) being placed in communities. These towers have
                    electronic equipment and antennas that receive and transmit cell phone signals using{" "}
                    <a
                        target="_blank"
                        href="https://www.cancer.org/cancer/risk-prevention/radiation-exposure/radiofrequency-radiation.html">
                        radiofrequency (RF) waves.
                    </a>
                </p>
                <p className="text-align-left">
                    When cell phone signals are transmitted back and forth to cell towers, the RF waves at the tower
                    radiate into the environment, where people can be exposed to them. Exposure to high levels of RF
                    waves can be harmful because of the ability of the waves to heat up body tissues, but the levels of
                    energy used by cell phones and towers are much lower and are not known to cause significant heating.
                    Other health effects from low-level exposure have not been proven, but it's generally agreed that
                    more research needs to be done in this area.
                </p>
                <p className="text-align-left">
                    On the electromagnetic spectrum, RF waves fall between FM radio waves and microwaves. RF waves are a
                    form of non-ionizing radiation, which means they do not directly damage DNA inside cells. Stronger,
                    or ionizing, types of radiation — x-rays, gamma rays and ultraviolet rays — are thought to cause
                    cancer through direct damage to cell DNA. More research is needed, but there is not a lot of
                    evidence to support the idea that living, working or going to school or day care near a cell tower
                    increases the risk of cancer or other health issues. National and international health agencies have
                    not classified RF waves from cell towers as cancer-causing. The International Agency for Research on
                    Cancer does consider RF waves more generally as potentially carcinogenic, so more research is
                    needed.
                </p>
                <p className="text-align-left">
                    The FCC limits public exposure from cell towers to a maximum of 580 microwatts per square
                    centimeter. A person would have to be in the main transmitting beam and within a few feet of the
                    antennae for several minutes or longer to be exposed to RF levels near that limit. RF wave exposure
                    in the vicinity of a tower or near a tower's base is generally much lower. In other words, it's very
                    unlikely that a person could be exposed to RF levels exceeding the limit just by being near a cell
                    phone tower. According to the FDA, the limit set by the FCC is acceptable for protecting public
                    health. RF waves can potentially interfere with several types of medical devices, including
                    pacemakers and insulin pumps, depending on the strength of the waves. Research shows cell phones
                    themselves do not seem to pose a significant health risk for pacemaker wearers.
                </p>
                <p className="text-align-left">
                    At this time, there’s no strong evidence that exposure to RF waves from cell phone towers causes any
                    noticeable health effects. However, this does not mean that the RF waves from cell phone towers have
                    been proven to be absolutely safe. Most expert organizations agree that more research is needed to
                    help clarify this, especially for any possible long-term effects.
                </p>
                <p className="text-align-left">
                    <b>What the International community says about RF radiation in general</b>
                    <br></br>
                    Based on a review of studies published up until 2011, the International Agency for Research on
                    Cancer (IARC) has classified RF radiation as “possibly carcinogenic to humans,” based on limited
                    evidence of a possible increase in risk for brain tumors among cell phone users, and inadequate
                    evidence for other types of cancer. (For more information on the IARC classification system, see
                    <a
                        target="_blank"
                        href="https://www.cancer.org/cancer/risk-prevention/understanding-cancer-risk/known-and-probable-human-carcinogens.html">
                        {" "}Known and Probable Human Carcinogens.
                    </a>)
                </p>
                <p className="text-align-left">
                    More recently, the <b>US Food and Drug Administration (FDA)</b> issued a technical report based on
                    results of studies published between 2008 and 2018, as well as national trends in cancer rates. The
                    report concluded: “Based on the studies that are described in detail in this report, there is
                    insufficient evidence to support a causal association between radiofrequency radiation (RFR)
                    exposure and [tumor formation].”
                </p>
                <p className="text-align-left">
                    So far, the <b>National Toxicology Program (NTP)</b> has not included RF radiation in its Report on
                    Carcinogens, which lists exposures that are known to be or reasonably anticipated to be human
                    carcinogens.
                </p>
            </>,
        ],
    },
    {
        Topic: "High Power Electrical Transmission Lines (EMF)",
        Title: "High Power Transmission Lines and Electromagnetic Fields",
        Description: [
            <>
                <p className="text-align-left" key="paragraph1">
                    Power lines are one of many sources of electromagnetic fields in our environment. Power lines carry
                    energy created by power plants to your home or business. The constant flow of electricity through
                    these transmission lines creates low-frequency, non-ionizing radiation. Electromagnetic fields like
                    those from power lines decrease dramatically as you move or live farther away from them. At a
                    distance of 300 feet, and with average electricity demand, magnetic fields from power transmission
                    lines are similar to magnetic fields found in your home, according to a{" "}
                    <a
                        target="_blank"
                        href="https://www.niehs.nih.gov/health/materials/electric_and_magnetic_fields_associated_with_the_use_of_electric_power_questions_and_answers_english_508.pdf">
                        2002 report
                    </a>{" "}
                    by the National Institute of Environmental Health Sciences and the National Institutes of Health.
                </p>
                <p className="text-align-left">
                    <b>What does the research say about whether power lines can impact cancer risk?</b>
                    <br></br>
                    Concerns about power lines and cancer heightened after a{" "}
                    <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/453167/">
                        1979 study
                    </a>{" "}
                    found a relationship between power lines and{" "}
                    <a target="_blank" href="https://www.cancer.net/node/31318">
                        childhood cancer
                    </a>
                    . However, other studies in the 2000s did not find an association or found an association only in
                    homes with very high levels of magnetic forces, which is rare,{" "}
                    <a
                        target="_blank"
                        href="https://www.cancer.gov/about-cancer/causes-prevention/risk/radiation/electromagnetic-fields-fact-sheet">
                        according to the National Cancer Institute.
                    </a>
                </p>
                <p className="text-align-left">
                    In 2005,{" "}
                    <a target="_blank" href="https://www.bmj.com/content/330/7503/1290?ehom=">
                        researchers at the University of Oxford
                    </a>{" "}
                    found an association between the distance from power lines and childhood cancer in Britain. Since
                    then, researchers have further studied this association, including in the{" "}
                    <a target="_blank" href="https://www.nature.com/articles/bjc2016142">
                        California Power Line Study
                    </a>
                    , a 2016 study in the British Journal of Cancer. Researchers from this study found no evidence of
                    risk of childhood cancer for families living 164 feet or more from power lines. And, at most,
                    researchers found weak support for increased risk of childhood cancer for those living closer than
                    164 feet.
                </p>
                <p className="text-align-left">
                    <b>Are there other health risks associated with electromagnetic fields?</b>
                    <br></br>
                    Exposure to low-level electromagnetic fields has been studied extensively, and there is no evidence
                    that it is harmful to human health, according to the{" "}
                    <a
                        target="_blank"
                        href="https://www.who.int/news-room/questions-and-answers/item/radiation-electromagnetic-fields">
                        World Health Organization
                    </a>
                    . In fact, according to the WHO website, most scientists and doctors agree that if there are any
                    health effects from low-level electromagnetic fields, they are likely to be very small compared to
                    other health risks that people face in daily life.
                </p>
                <p className="text-align-left">
                    Other agencies, like the European Commission Scientific Committee on Emerging and Newly Identifiable
                    Health Risks, take a more cautious stance. They say that it’s possible, but far from conclusive,
                    that extremely low-frequency magnetic fields could be carcinogenic, or have the potential to cause
                    cancer, in a{" "}
                    <a
                        target="_blank"
                        href="https://health.ec.europa.eu/publications/potential-health-effects-exposure-electromagnetic-fields-emf_en">
                        2015 report
                    </a>
                    .
                </p>
            </>,
        ],
    },
];
