import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Highways.css";
// import jsonData from "./NaturalGasWellCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function Highways() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Major Roads and Highways Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover major roads and highways within a half mile of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Major Roads and Highways Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> major roads and highways within a half mile radius</strong> of your property
                    by leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into natural gas wells but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics
                    Release Inventory sites to public drinking water systems, air and noise pollution sources, natural
                    hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to make
                    informed decisions about your surroundings. Take control of your environmental awareness today and
                    safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure
                    a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            {/* <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 100 Counties by Natural Gas Counts</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Major Roads and Highways and Noise Pollution</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a major road or highway exposes residents to significant levels of noise
                            pollution, which can have adverse effects on health and well-being. The constant traffic
                            flow, combined with the rumble of vehicles and occasional honking, creates a persistent
                            background noise that disrupts sleep, increases stress levels, and impairs cognitive
                            function.
                            <br />
                            <br />
                            Numerous studies have documented the health risks associated with exposure to
                            traffic-related noise pollution. Research published in the European Heart Journal has linked
                            chronic exposure to traffic noise with an increased risk of hypertension, cardiovascular
                            diseases, and stroke [1]. Similarly, a review published in the Lancet highlighted the
                            adverse effects of environmental noise on health, including sleep disturbances, annoyance,
                            and reduced quality of life [2].
                            <br />
                            <br />
                            One of the key concerns with living near a major road or highway is the disruption of sleep
                            patterns caused by traffic noise. Studies have shown that noise levels exceeding 55 decibels
                            (dB) can interfere with sleep quality and duration, leading to fatigue, irritability, and
                            impaired cognitive function [3]. Chronic sleep disturbances have been associated with an
                            increased risk of obesity, diabetes, hypertension, and cardiovascular diseases [4].
                            <br />
                            <br />
                            Furthermore, exposure to traffic noise can have non-auditory effects on health, such as
                            increased stress levels and psychological disorders. Research published in the Journal of
                            Environmental Psychology has found that individuals living in noisy environments experience
                            higher levels of stress and anxiety compared to those in quieter areas [5]. Chronic stress
                            has been linked to a variety of health problems, including depression, anxiety disorders,
                            and compromised immune function [6].
                            <br />
                            <br />
                            In conclusion, living near a major road or highway exposes individuals to elevated levels of
                            noise pollution, which can have detrimental effects on sleep, mental health, and overall
                            well-being. Effective measures to mitigate traffic noise, such as noise barriers, road
                            surface treatments, and urban planning strategies, are essential to protect the health of
                            residents living in affected areas.
                            <br />
                            <br />
                            [1] Source: Münzel, T., Gori, T., Babisch, W., Basner, M., 2014. Cardiovascular effects of
                            environmental noise exposure. Eur. Heart J. 35, 829–836.
                            <br />
                            <br />
                            [2] Source: Basner, M., Babisch, W., Davis, A., Brink, M., Clark, C., Janssen, S.,
                            Stansfeld, S., 2014. Auditory and non-auditory effects of noise on health. Lancet 383,
                            1325–1332.
                            <br />
                            <br />
                            [3] Source: World Health Organization. Burden of disease from environmental noise:
                            Quantification of healthy life years lost in Europe. 2011.
                            <br />
                            <br />
                            [4] Source: Patel, S.R., Hu, F.B., 2008. Short sleep duration and weight gain: a systematic
                            review. Obesity (Silver Spring) 16, 643–653.
                            <br />
                            <br />
                            [5] Source: Stansfeld, S.A., Matheson, M.P., 2003. Noise pollution: non-auditory effects on
                            health. Br. Med. Bull. 68, 243–257.
                            <br /> <br />
                            [6] Source: Cohen, S., Janicki-Deverts, D., Miller, G.E., 2007. Psychological stress and
                            disease. JAMA 298, 1685–1687.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Highways;
