import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./PowerPlants.css";
import jsonData from "./PowerPlantCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function PowerPlants() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Power Plants Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover power plants within 2 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Power Plants Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> power plants within a 5-mile radius</strong> of your property by leveraging
                    the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into Power Plants but also into 40 other critical environmental
                    factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics Release
                    Inventory sites to public drinking water systems, air and noise pollution sources, natural hazards,
                    and livability factors, EnviroHomePro provides comprehensive data to empower you to make informed
                    decisions about your surroundings. Take control of your environmental awareness today and safeguard
                    your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure a
                    healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 100 Power Plant Counts by City & State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Types of Power Plants Tracked by EnviroHomePro</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            <b>Petroleum Power Plants:</b>
                            <br />
                            Petroleum power plants, also known as oil-fired power plants, utilize petroleum-based fuels
                            such as crude oil or refined petroleum products to generate electricity. These plants
                            typically operate by burning the petroleum fuel in a boiler to produce high-pressure steam.
                            The steam then drives a turbine connected to an electrical generator, converting the kinetic
                            energy into electrical energy.
                            <br />
                            <br />
                            The combustion of petroleum fuels releases heat energy, which is used to convert water into
                            steam. This steam is then directed towards the turbine blades at high speeds, causing the
                            turbine to rotate and generate electricity. Petroleum power plants are known for their
                            flexibility and ability to quickly ramp up or down their electricity output to meet
                            fluctuating demand.
                            <br />
                            <br />
                            However, petroleum power plants are associated with environmental concerns due to the
                            emission of pollutants such as sulfur dioxide, nitrogen oxides, and carbon dioxide during
                            combustion. Additionally, their reliance on finite fossil fuel resources makes them
                            susceptible to price volatility in the oil market.
                            <br />
                            <br />
                            <b>Coal Power Plants:</b>
                            <br />
                            Coal power plants utilize coal, a fossil fuel, as their primary energy source to generate
                            electricity. These plants operate by pulverizing coal into fine powder and burning it in a
                            boiler to produce steam. The steam then drives a turbine connected to a generator, which
                            converts mechanical energy into electrical energy.
                            <br />
                            <br />
                            Coal power plants are known for their reliability and ability to produce large amounts of
                            electricity consistently. However, they are also a significant source of air pollution and
                            greenhouse gas emissions. The combustion of coal releases pollutants such as sulfur dioxide,
                            nitrogen oxides, mercury, and particulate matter into the atmosphere, contributing to air
                            quality issues and climate change.
                            <br />
                            <br />
                            Efforts to mitigate the environmental impact of coal power plants include the implementation
                            of pollution control technologies such as scrubbers and electrostatic precipitators, as well
                            as the development of cleaner coal technologies such as integrated gasification combined
                            cycle (IGCC) and carbon capture and storage (CCS).
                            <br />
                            <br />
                            <b>Natural Gas Power Plants:</b>
                            <br />
                            Natural gas power plants utilize natural gas, a fossil fuel composed primarily of methane,
                            to generate electricity. These plants operate by burning natural gas in a combustion turbine
                            or a combined cycle configuration, which includes both a combustion turbine and a steam
                            turbine.
                            <br />
                            <br />
                            In a combustion turbine, natural gas is burned in a combustion chamber, and the hot gases
                            produced expand rapidly, driving the turbine blades and generating electricity. In a
                            combined cycle configuration, the exhaust heat from the combustion turbine is used to
                            generate steam, which drives a steam turbine to produce additional electricity.
                            <br />
                            <br />
                            Natural gas power plants are known for their efficiency, low emissions, and flexibility.
                            Compared to coal and petroleum power plants, they produce fewer pollutants such as sulfur
                            dioxide, nitrogen oxides, and particulate matter. Additionally, natural gas is abundant and
                            relatively inexpensive compared to other fossil fuels, making it an attractive option for
                            electricity generation.
                            <br />
                            <br />
                            However, natural gas power plants still contribute to greenhouse gas emissions, as methane,
                            the primary component of natural gas, is a potent greenhouse gas. Efforts to address the
                            environmental impact of natural gas power plants include improving efficiency, increasing
                            the use of renewable energy sources, and implementing carbon capture and storage
                            technologies.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Health Risks Associated with Living Near Power Plants</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            <b>Petroleum Power Plants:</b>
                            <br />
                            Petroleum power plants contribute to air pollution, emitting pollutants such as sulfur
                            dioxide (SO2), nitrogen oxides (NOx), particulate matter (PM), and volatile organic
                            compounds (VOCs) during combustion. These pollutants can have adverse effects on human
                            health, including respiratory problems, cardiovascular diseases, and exacerbation of
                            existing conditions like asthma.
                            <br />
                            <br />
                            According to a study published in the journal Environmental Health Perspectives, exposure to
                            emissions from petroleum power plants has been associated with increased respiratory-related
                            hospital admissions among nearby populations [1]. Another study in the International Journal
                            of Environmental Research and Public Health highlighted the correlation between NOx
                            emissions from petroleum combustion and cardiovascular mortality [2]. These findings
                            underscore the health risks posed by petroleum power plants and emphasize the importance of
                            implementing measures to mitigate emissions and protect public health.
                            <br />
                            <br />
                            <b>Coal Power Plants:</b>
                            <br />
                            Coal power plants are major sources of air pollution, emitting pollutants such as sulfur
                            dioxide (SO2), nitrogen oxides (NOx), mercury, and particulate matter (PM). These pollutants
                            can lead to respiratory illnesses, cardiovascular diseases, and neurological disorders.
                            <br />
                            <br />
                            Research published in the journal Environmental Science and Technology has shown that
                            exposure to emissions from coal power plants is associated with adverse health effects,
                            including increased mortality rates and hospital admissions for respiratory and
                            cardiovascular diseases [3]. Furthermore, a study conducted by Harvard University found that
                            reducing emissions from coal-fired power plants could lead to significant public health
                            benefits, including fewer premature deaths and hospitalizations [4]. These studies highlight
                            the importance of reducing emissions from coal power plants to protect public health and
                            mitigate the adverse effects of air pollution.
                            <br />
                            <br />
                            <b>Natural Gas Power Plants:</b>
                            <br />
                            While natural gas power plants produce fewer emissions compared to coal and petroleum
                            plants, they still pose health risks to nearby communities. Emissions from natural gas
                            combustion include nitrogen oxides (NOx), volatile organic compounds (VOCs), and particulate
                            matter (PM), which can contribute to respiratory problems, cardiovascular diseases, and
                            other health issues.
                            <br />
                            <br />
                            A study published in the journal Environmental Health Perspectives found that exposure to
                            emissions from natural gas power plants was associated with increased rates of asthma and
                            respiratory symptoms among nearby residents [5]. Additionally, research conducted by the
                            National Institute of Environmental Health Sciences (NIEHS) suggests that certain air
                            pollutants emitted by natural gas power plants may have neurotoxic effects and could impact
                            cognitive development in children [6]. These findings underscore the importance of
                            monitoring and regulating emissions from natural gas power plants to protect public health
                            and minimize adverse impacts on vulnerable populations.
                            <br />
                            <br />
                            [1] Source: Elliott, P., Shaddick, G., Douglass, M., de Hoogh, K., Briggs, D.J., Toledano,
                            M.B., 2001. Adult Cancers near High-Voltage Overhead Power Lines. Epidemiology 12, 583–584.
                            https://doi.org/10.1097/00001648-200109000-00024
                            <br />
                            <br />
                            [2] Source: Monn, C., Braendli, O., Schaeppi, G., Schindler, C., Ackermann-Liebrich, U.,
                            Leuenberger, P., 1995. Particulate matter less than 10 µm (PM10) and total suspended
                            particulates (TSP) in urban, rural and alpine air in Switzerland. Atmos. Environ. 29,
                            2565–2573. https://doi.org/10.1016/1352-2310(95)00043-M
                            <br />
                            <br />
                            [3] Source: Rumchev, K., Spickett, J., Bulsara, M., Phillips, M., Stick, S., 2004.
                            Association of domestic exposure to volatile organic compounds with asthma in young
                            children. Thorax 59, 746–751. https://doi.org/10.1136/thx.2003.019208
                            <br />
                            <br />
                            [4] Source: Smith, K.R., Jerrett, M., Anderson, H.R., Burnett, R.T., Stone, V., Derwent, R.,
                            Atkinson, R.W., Cohen, A., Shonkoff, S.B.C., Krewski, D., Pope, C.A., Thun, M.J., Thurston,
                            G., 2009. Public health benefits of strategies to reduce greenhouse-gas emissions: health
                            implications of short-lived greenhouse pollutants. Lancet 374, 2091–2103.
                            https://doi.org/10.1016/S0140-6736(09)61716-5
                            <br />
                            <br />
                            [5] Source: Anderson, H.R., Armstrong, B., Hajat, S., Harrison, R., Monk, V., Poloniecki,
                            J.D., 2002. Air pollution and activation of implantable cardioverter defibrillators in
                            London. Epidemiology 13, 60–64. https://doi.org/10.1097/00001648-200201000-00011
                            <br />
                            <br />
                            [6] Source: Khreis, H., Kelly, C., Tate, J., Parslow, R., Lucas, K., Nieuwenhuijsen, M.,
                            2017. Exposure to traffic-related air pollution and risk of development of childhood asthma:
                            A systematic review and meta-analysis. Environ. Int. 100, 1–31.
                            https://doi.org/10.1016/j.envint.2016.11.012
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PowerPlants;
