// components/Login.js
import { useEffect, useState } from "react";
import { useSearchParams, useParams} from 'react-router-dom';
import { Authenticator, useAuthenticator, View, CheckboxField, TextField } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./Login.css";
import { useNavigate, useLocation } from "react-router";
import { Helmet } from "react-helmet";


export function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const [isTermsAccepted, setIsTermsAccepted] = useState();
    // let test = useAuthenticator((context) => [context]);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get("view").toString();
    //Set the login view or sign up view in Authenticator

    let from = location.state?.from?.pathname || "/residential-environmental-search";
    
    useEffect(() => {
        if (route === "authenticated") {
            navigate(from, { replace: false });
        }
    }, [route, navigate, from]);

    return (
        <View className="auth-wrapper">
             <Helmet>
                <title>Login or Create Account | EnviroHomePro</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <Authenticator
                loginMechanisms={['email']}
                borderRadius="0.5rem"
                initialState = {view == "signUp" ? "signUp" : "signIn"}
                // Customize `Authenticator.SignUp.FormFields`
            
                components={{
                    SignUp: {
                        FormFields() {
                            const { validationErrors } = useAuthenticator();

                            return (
                                <>
                                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                                    <Authenticator.SignUp.FormFields />

                                    {/* Append & require Terms and Conditions field to sign up  */}
                                    <CheckboxField
                                        errorMessage={validationErrors.acknowledgement}
                                        hasError={!!validationErrors.acknowledgement}
                                        name="custom:acknowledgement"
                                        value="yes"
                                        label="I agree with the Terms and Conditions"
                                    />
                                    <p className="password-requirements">Passwords requirements:</p>
                                    <li className="password-requirement">Password minimum length: 8 character(s)</li>
                                    <li className="password-requirement">Contains at least 1 number</li>
                                    <li className="password-requirement">Contains at least 1 special character</li>
                                    <li className="password-requirement">Contains at least 1 uppercase letter</li>
                                    <li className="password-requirement">Contains at least 1 lowercase letter</li>

                                </>
                            );
                        },
                    },
                }}
                services={{
                    async validateCustomSignUp(formData) {
                        if (!formData['custom:acknowledgement']) {
                            return {
                                acknowledgement: "You must agree to the Terms and Conditions",
                            };
                        }
                    },
                }}>as</Authenticator>
        </View>
    );
}
