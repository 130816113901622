import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MdOutlineForest, MdOutlineFireTruck } from "react-icons/md";
import { FaHospitalSymbol, FaAmbulance } from "react-icons/fa";
import { GiPoliceBadge } from "react-icons/gi";

function Livability() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <title>Livability: Impact on Health & Home</title>
                <meta
                    name="description"
                    content="Enhance your neighborhood's livability with EnviroHomePro's insights. Find ideal locations near fire departments, parks, and more for informed decisions."
                />
            </Helmet>

            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">Livability: Health & Home</h1>
                <h2 className="info-page-pitch">
                    Residing in areas with high livability offers diverse benefits, encompassing quality education,
                    healthcare, employment opportunities, and cultural amenities. These areas often boast
                    well-maintained infrastructure, green spaces, and low crime rates, enhancing residents' overall
                    well-being. Access to reliable public services, efficient transportation, and recreational
                    facilities contributes to a high quality of life. Additionally, communities with strong social
                    connections and cultural diversity foster a sense of belonging. Living in such regions promotes a
                    healthy work-life balance, educational excellence, and a positive environment, making them
                    attractive places to call home for those seeking an enriching and enjoyable lifestyle.
                </h2>
            </div>
            <div className="info-div">
                <section className="info-section" id="parks">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/parks-near-you">
                            {" "}
                            National, State and Local Parks <MdOutlineForest className="info-icon" />
                        </a>
                    </h2>

                    <div className="info-block">
                        <div className="info-section-text">
                            National, state, and local parks are crucial for biodiversity conservation, preserving
                            ecosystems, and supporting various plant and animal species. These parks provide
                            recreational spaces that contribute to the physical and mental well-being of communities,
                            offering opportunities for outdoor activities and relaxation. Many parks hold cultural and
                            historical significance, serving as educational and cultural sites. Parks also play a vital
                            role in environmental education, fostering awareness and appreciation for nature and
                            conservation practices. However, parks face threats such as habitat degradation, pollution,
                            and climate change, requiring collaborative efforts at local, state, and national levels to
                            address and mitigate these challenges and ensure the continued well-being of these important
                            natural and cultural assets.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Biodiversity Conservation:</b> National, state, and local parks play a critical role
                                in biodiversity conservation, preserving diverse ecosystems, habitats, and plant and
                                animal species, contributing to overall ecological health.
                            </li>

                            <li className="info-list-item">
                                <b>Recreation and Well-being:</b> Parks provide recreational spaces for communities,
                                offering opportunities for outdoor activities, exercise, and relaxation, contributing to
                                the physical and mental well-being of residents.
                            </li>

                            <li className="info-list-item">
                                <b>Cultural and Historical Significance:</b> Many parks hold cultural and historical
                                significance, serving as important sites for education, cultural activities, and the
                                preservation of natural and cultural heritage.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Education:</b> Parks serve as valuable platforms for environmental
                                education, fostering awareness and appreciation for nature, wildlife, and conservation
                                practices among visitors and local communities.
                            </li>

                            <li className="info-list-item">
                                <b>Threats and Conservation Challenges:</b> Despite their importance, parks face threats
                                such as habitat degradation, pollution, and climate change, requiring concerted efforts
                                at local, state, and national levels to address and mitigate these challenges.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="hospitals">
                    <h2 className="info-section-h2 right-h2">
                    <a className="category-info-page-link" href="/hospitals-near-you">
                    Hospitals <FaHospitalSymbol className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Medical Services:</b> Hospitals are essential for providing a wide range of medical
                                services, including emergency care, surgeries, diagnostics, and specialized treatments,
                                ensuring the health and well-being of communities.
                            </li>

                            <li className="info-list-item">
                                <b>Public Health:</b> Hospitals play a crucial role in public health by preventing,
                                diagnosing, and treating diseases, contributing to disease surveillance, and managing
                                public health emergencies, ultimately safeguarding community health.
                            </li>

                            <li className="info-list-item">
                                <b>Employment and Economic Impact:</b> Hospitals generate significant employment
                                opportunities and have a substantial economic impact on local communities, providing
                                jobs, supporting related industries, and contributing to the overall economic
                                development.
                            </li>

                            <li className="info-list-item">
                                <b>Community Support:</b> Hospitals serve as community hubs, offering support not only
                                through medical services but also by hosting health education programs, supporting
                                community events, and addressing broader social and mental health needs.
                            </li>

                            <li className="info-list-item">
                                <b>Infrastructure Resilience:</b> Ensuring the resilience and preparedness of hospital
                                infrastructure is critical for responding to health crises, natural disasters, and other
                                emergencies, emphasizing the importance of ongoing investment in healthcare facilities.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Hospitals are vital institutions that provide a wide range of medical services, including
                            emergency care, surgeries, diagnostics, and specialized treatments, ensuring the health and
                            well-being of communities. They play a crucial role in public health by preventing,
                            diagnosing, and treating diseases, contributing to disease surveillance, and managing public
                            health emergencies. Hospitals also have a significant economic impact, generating employment
                            opportunities and supporting local economies. Beyond medical services, hospitals serve as
                            community hubs, offering support through health education programs, community events, and
                            addressing broader social and mental health needs. Ensuring the resilience and preparedness
                            of hospital infrastructure is critical for responding to health crises and emergencies,
                            underscoring the importance of ongoing investment in healthcare facilities.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="police-stations">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/police-stations-near-you">
                            Police Stations <GiPoliceBadge className="info-icon" />
                        </a>
                    </h2>

                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Police stations serve as central hubs for law enforcement, ensuring public safety,
                            maintaining order, and enforcing laws. They play a crucial role in emergency response,
                            addressing a wide range of incidents and providing rapid assistance. Establishing positive
                            relationships with the community is a key function, fostering trust and collaboration.
                            Police stations actively engage in crime prevention through patrolling, community outreach,
                            and proactive measures, as well as conducting investigations to bring perpetrators to
                            justice. Continuous training and professional development are crucial for law enforcement
                            officers to stay informed and effectively serve and protect the community.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Law Enforcement:</b> Police stations serve as the central hubs for law enforcement
                                activities, ensuring public safety, maintaining order, and enforcing laws to protect
                                communities.
                            </li>

                            <li className="info-list-item">
                                <b>Emergency Response:</b> Police stations play a crucial role in emergency response,
                                addressing incidents ranging from accidents and natural disasters to criminal
                                activities, providing rapid assistance and coordination.
                            </li>

                            <li className="info-list-item">
                                <b>Community Engagement:</b> Establishing positive relationships with the community is a
                                key function of police stations, fostering trust, communication, and collaboration to
                                address public concerns and enhance overall safety.
                            </li>

                            <li className="info-list-item">
                                <b>Crime Prevention and Investigation:</b> Police stations are instrumental in crime
                                prevention through patrolling, community outreach, and proactive measures. They also
                                conduct investigations to solve crimes, bringing perpetrators to justice.
                            </li>

                            <li className="info-list-item">
                                <b>Training and Professional Development:</b> Continuous training and professional
                                development are essential for law enforcement officers, ensuring they stay informed
                                about evolving laws, technologies, and best practices to effectively serve and protect
                                the community.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="emergency-medical-service-stations">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/emergency-medical-services-near-you">
                        Emergency Medical Service Stations <FaAmbulance className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Emergency Medical Response:</b> Emergency Medical Service (EMS) stations are primary
                                hubs for providing rapid and life-saving medical response to emergencies, including
                                accidents, medical crises, and natural disasters, ensuring timely healthcare
                                intervention.
                            </li>

                            <li className="info-list-item">
                                <b>Prehospital Care:</b> EMS stations are crucial for delivering prehospital care,
                                including administering first aid, stabilizing patients, and transporting them to
                                medical facilities, playing a pivotal role in the continuum of patient care.
                            </li>

                            <li className="info-list-item">
                                <b>Community Health Outreach:</b> EMS stations engage in community health outreach,
                                offering education on emergency preparedness, conducting CPR training, and promoting
                                public health initiatives to enhance community resilience.
                            </li>

                            <li className="info-list-item">
                                <b>Coordination with Hospitals:</b> Collaborative coordination with hospitals is vital
                                for EMS stations to ensure seamless patient transfers, share critical medical
                                information, and optimize the continuum of care for patients in need of advanced medical
                                treatment.
                            </li>

                            <li className="info-list-item">
                                <b>Equipment Maintenance and Preparedness:</b> Maintaining and regularly updating
                                medical equipment, ambulances, and emergency response vehicles is essential for EMS
                                stations to stay prepared for a diverse range of emergencies and deliver effective
                                medical care.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Emergency Medical Service (EMS) stations are central hubs for providing rapid and
                            life-saving medical response to emergencies, ensuring timely healthcare intervention. They
                            deliver crucial prehospital care, stabilizing patients and facilitating their transport to
                            medical facilities. EMS stations actively engage in community health outreach, offering
                            education and training to enhance emergency preparedness and public health. Collaborative
                            coordination with hospitals is vital to ensure seamless patient transfers and optimize the
                            continuum of care. Maintaining and regularly updating medical equipment, ambulances, and
                            emergency response vehicles is essential for EMS stations to stay prepared for a diverse
                            range of emergencies and deliver effective medical care.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="fire-stations">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/fire-stations-near-you">
                        Fire Stations <MdOutlineFireTruck className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Fire stations serve as crucial hubs for fire suppression, responding rapidly to fires and
                            emergencies to protect lives and property. They play a vital role in emergency rescue
                            operations, providing immediate assistance during accidents, natural disasters, and other
                            crises. Fire stations actively engage in community education, offering programs to enhance
                            fire safety, emergency preparedness, and community resilience. Contributing to hazard
                            mitigation, they identify and address potential risks, implement fire prevention measures,
                            and collaborate with residents to create safer living environments. Many fire stations also
                            provide medical first response services, delivering basic life support and emergency medical
                            care to bridge the gap between the onset of a medical emergency and the arrival of dedicated
                            medical professionals.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Fire Suppression:</b> Fire stations are central to fire suppression efforts,
                                responding rapidly to fires, rescuing people and animals, and preventing the spread of
                                flames to protect lives, property, and the environment.
                            </li>

                            <li className="info-list-item">
                                <b>Emergency Rescue:</b> Beyond firefighting, fire stations play a crucial role in
                                emergency rescue operations, responding to accidents, natural disasters, and other
                                emergencies to provide immediate assistance and support.
                            </li>

                            <li className="info-list-item">
                                <b>Community Education:</b> Fire stations engage in community education programs,
                                offering fire safety training, emergency preparedness information, and conducting drills
                                to raise awareness and enhance community resilience.
                            </li>

                            <li className="info-list-item">
                                <b>Hazard Mitigation:</b> Fire stations contribute to hazard mitigation by identifying
                                and addressing potential risks in the community, implementing fire prevention measures,
                                and collaborating with residents to create safer living environments.
                            </li>

                            <li className="info-list-item">
                                <b>Medical First Response:</b> Many fire stations provide medical first response
                                services, including basic life support and emergency medical care, bridging the gap
                                between the onset of a medical emergency and the arrival of dedicated medical
                                professionals.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Livability;
