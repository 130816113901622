import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./NaturalGasWells.css";
import jsonData from "./NaturalGasWellCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function NaturalGasWells() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Natural Gas Wells Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover natural gas wells within 1 mile of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Natural Gas Wells Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> natural gas wells within a 1-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into natural gas wells but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics
                    Release Inventory sites to public drinking water systems, air and noise pollution sources, natural
                    hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to make
                    informed decisions about your surroundings. Take control of your environmental awareness today and
                    safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure
                    a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 100 Counties by Natural Gas Counts</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">What is a Natural Gas Well</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A natural gas well is a structure drilled into the Earth's surface to extract natural gas
                            from underground reservoirs. These wells are essential for accessing and harvesting natural
                            gas, which is a valuable energy resource used for heating, electricity generation,
                            industrial processes, and transportation.
                            <br />
                            <br />
                            The process of drilling and operating a natural gas well involves several key steps. It
                            begins with site preparation, where the drilling location is selected and cleared of any
                            obstacles. Once the site is ready, the drilling rig is brought in to start the drilling
                            process. Modern drilling rigs use advanced technology and equipment to bore deep into the
                            Earth's crust, reaching depths of thousands of feet.
                            <br />
                            <br />
                            As the drill penetrates the Earth's surface, various layers of rock and sediment are
                            encountered. To prevent the wellbore from collapsing and to control the flow of fluids, such
                            as groundwater and natural gas, steel casing pipes are inserted and cemented into place.
                            This creates a secure pathway for extracting natural gas while also protecting the
                            surrounding environment.
                            <br />
                            <br />
                            Once the well reaches the target depth, specialized equipment, such as perforating guns or
                            hydraulic fracturing tools, may be used to stimulate the flow of natural gas from the
                            reservoir into the wellbore. Hydraulic fracturing, or fracking, involves injecting water,
                            sand, and chemicals into the well at high pressure to create fractures in the rock
                            formation, allowing natural gas to flow more freely.
                            <br />
                            <br />
                            After the natural gas begins to flow to the surface, it is collected and transported through
                            pipelines to processing facilities where impurities such as water, carbon dioxide, and
                            sulfur compounds are removed. The processed natural gas is then transported via pipelines or
                            tanker trucks to distribution centers where it is distributed to consumers or further
                            processed for specific applications.
                            <br />
                            <br />
                            Throughout the operation of a natural gas well, safety and environmental considerations are
                            paramount. Regulations and industry best practices are followed to prevent accidents,
                            minimize environmental impact, and ensure the responsible extraction and utilization of this
                            valuable energy resource.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Health Affects Associated with Living Near a Natural Gas Well
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a natural gas well can pose health risks and exposure to air pollution,
                            impacting nearby communities. Natural gas extraction activities, including drilling,
                            hydraulic fracturing (fracking), and transportation, release various pollutants into the
                            air, which can have adverse effects on human health.
                            <br />
                            <br />
                            One significant concern is the emission of volatile organic compounds (VOCs) during drilling
                            and fracking operations. VOCs such as benzene, toluene, and xylene are known to cause
                            respiratory irritation, headaches, nausea, and long-term exposure can lead to more severe
                            health effects including neurological disorders and cancer. A study published in the journal
                            Environmental Health Perspectives found elevated levels of VOCs in the air near natural gas
                            extraction sites, raising concerns about potential health risks for nearby residents [1].
                            <br />
                            <br />
                            Additionally, the release of methane, a potent greenhouse gas, during drilling, production,
                            and transportation of natural gas contributes to air pollution and climate change. Methane
                            emissions not only exacerbate global warming but can also contribute to the formation of
                            ground-level ozone, a harmful air pollutant linked to respiratory problems and
                            cardiovascular diseases.
                            <br />
                            <br />
                            Particulate matter (PM) emissions from natural gas operations are another concern. PM,
                            especially fine particles PM2.5 and ultrafine particles, can penetrate deep into the lungs
                            and enter the bloodstream, causing respiratory and cardiovascular health problems. A study
                            published in Environmental Science & Technology found that PM emissions from natural gas
                            operations contribute to regional air quality degradation and pose health risks for nearby
                            communities [2].
                            <br />
                            <br />
                            Furthermore, the release of air pollutants from natural gas operations can
                            disproportionately affect vulnerable populations such as children, the elderly, and
                            individuals with pre-existing health conditions. Proximity to natural gas wells has been
                            associated with increased hospital admissions for respiratory issues, as demonstrated by
                            research published in Environmental Health Perspectives [3].
                            <br />
                            <br />
                            In conclusion, living near a natural gas well exposes individuals to air pollutants that can
                            adversely affect respiratory health, exacerbate existing conditions, and contribute to
                            long-term health problems. Continued research and regulation are essential to mitigate these
                            health risks and protect the well-being of communities near natural gas extraction sites.
                            <br />
                            <br />
                            [1] Source: Colborn, T., Schultz, K., Herrick, L., Kwiatkowski, C., 2014. An Exploratory
                            Study of Air Quality near Natural Gas Operations. Environ Health Perspect 122, 87–95.
                            https://doi.org/10.1289/ehp.1307732
                            <br />
                            <br />
                            [2] Source: Moore, C.W., Zielinska, B., Pétron, G., Jackson, R.B., 2014. Air Impacts of
                            Increased Natural Gas Acquisition, Processing, and Use: A Critical Review. Environ. Sci.
                            Technol. 48, 8349–8359. https://doi.org/10.1021/es4053472
                            <br />
                            <br />
                            [3] Source: McKenzie, L.M., Guo, R., Witter, R.Z., Savitz, D.A., Newman, L.S., Adgate, J.L.,
                            2014. Birth Outcomes and Maternal Residential Proximity to Natural Gas Development in Rural
                            Colorado. Environ Health Perspect 122, 412–417. https://doi.org/10.1289/ehp.1306722
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default NaturalGasWells;
