// RequireAuth.js
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSearchParams, useParams} from 'react-router-dom';

export function RequireAuth({ children }) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const session = searchParams.get('session');

  const { route } = useAuthenticator((context) => [context.route]);

  // const { status } = useAuthenticator((context) => [context.authStatus]);

  if (route !== 'authenticated' && route !== 'configuring' && route !== 'idle') {
    return <Navigate to="/login?view=signIn" state={{ from: location, session: session }} replace />;
  }
  return children;
}