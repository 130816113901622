import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Wastewater.css";
import jsonData from "./WastewaterTreatmentPlantCount.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function Wastewater() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Wastewater Treatment Plants Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover wastewater treatment plants within 2 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Wastewater Treatment Plants Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> wastewater treatment plants within a 2 mile radius</strong> of your property
                    by leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into natural gas wells but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics
                    Release Inventory sites to public drinking water systems, air and noise pollution sources, natural
                    hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to make
                    informed decisions about your surroundings. Take control of your environmental awareness today and
                    safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure
                    a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Wastewater Treatment Plant Counts by State & Type</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">What is a Wastewater Treatment Plant</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Wastewater treatment plants play a crucial role in maintaining public health and
                            environmental sustainability by removing contaminants and pollutants from wastewater before
                            it is discharged back into natural water bodies. The process involves several stages, each
                            designed to target specific types of pollutants and ensure that the treated water meets
                            regulatory standards.
                            <br />
                            <br />
                            Firstly, wastewater enters the treatment plant through a network of pipes and channels,
                            where large debris like branches and plastics are removed through screening processes. This
                            helps prevent damage to the equipment further down the treatment line.
                            <br />
                            <br />
                            Next, the wastewater undergoes primary treatment, where gravity settles out heavier solids
                            like grit and organic matter. These settle at the bottom of large tanks called sedimentation
                            basins, forming sludge. The clarified water then moves on to secondary treatment.
                            <br />
                            <br />
                            In the secondary treatment phase, the water is aerated and mixed with microorganisms in
                            large tanks called aeration basins. These microorganisms break down organic pollutants into
                            simpler, less harmful substances through a process called activated sludge. This biological
                            treatment significantly reduces the levels of organic matter and nutrients in the water.
                            <br />
                            <br />
                            After secondary treatment, the water undergoes further clarification in settling tanks to
                            separate the activated sludge from the treated water. Some of this sludge is recycled back
                            into the aeration tanks to maintain the microbial population, while excess sludge is sent to
                            digesters for further processing.
                            <br />
                            <br />
                            In the final stage, the treated water is disinfected to kill any remaining harmful bacteria
                            and pathogens. Common disinfection methods include chlorination, ultraviolet (UV) radiation,
                            or ozonation. Once disinfected, the water is typically discharged into nearby rivers, lakes,
                            or oceans, or reused for irrigation or industrial purposes.
                            <br />
                            <br />
                            Throughout the entire process, wastewater treatment plants must adhere to strict
                            environmental regulations and monitoring to ensure that the discharged water meets quality
                            standards and does not harm aquatic ecosystems or human health. Advanced technologies and
                            continuous research aim to improve efficiency, reduce energy consumption, and enhance the
                            overall sustainability of wastewater treatment processes.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Health Risks Associated with Living Near a Wastewater Treatment Plant
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a wastewater treatment plant entails significant health and safety risks
                            primarily related to contamination of water sources and, to a lesser extent, air pollution.
                            While these facilities play a crucial role in treating wastewater, they also pose potential
                            hazards to nearby communities.
                            <br />
                            <br />
                            One of the foremost concerns is waterborne contamination. Wastewater treatment plants
                            discharge treated effluent into water bodies, which may contain residual pollutants.
                            Pathogens, heavy metals, and chemical compounds from industrial and domestic sources can
                            persist in the discharged water, potentially contaminating nearby groundwater or surface
                            water sources. A study published in the journal Water Research found that effluents from
                            wastewater treatment plants can contribute to the contamination of receiving waters with
                            pharmaceuticals and personal care products, highlighting the risks associated with treated
                            wastewater discharge.
                            <br />
                            <br />
                            Additionally, accidental spills or discharges from wastewater treatment plants can
                            exacerbate contamination issues. Mishaps during transportation or equipment failures may
                            lead to the release of untreated or partially treated wastewater into surrounding
                            environments, posing immediate risks to water quality and public health.
                            <br />
                            <br />
                            While water contamination is the primary concern, air pollution also presents a lesser but
                            notable risk. Wastewater treatment processes can generate foul-smelling gases such as
                            hydrogen sulfide and ammonia, which may cause respiratory irritation and other health issues
                            for nearby residents. Research published in the journal Environmental Health Perspectives
                            suggests that exposure to air pollutants emitted from wastewater treatment plants may
                            contribute to respiratory symptoms and other adverse health effects among nearby
                            populations.
                            <br />
                            <br />
                            In conclusion, living near a wastewater treatment plant exposes individuals to health and
                            safety risks primarily associated with waterborne contamination, supplemented by potential
                            air pollution concerns. Continued monitoring, regulatory oversight, and community engagement
                            are essential to address these risks and safeguard the well-being of residents living in
                            proximity to these facilities.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Wastewater;
