import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./FireStations.css";
import jsonData from "./FireDepartmentByCityAndState.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function FireStations() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Fire Stations Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Fire Stations within 2.5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Fire Stations Near You and Learn the Benefits</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> fire stations within a 2.5-mile radius</strong> of your property by leveraging
                    the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into fire station locations but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfunds, Toxics Release
                    Inventory, and Brownfield sites to public drinking water systems, air and noise pollution sources,
                    natural hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to
                    make informed decisions about your surroundings. Take control of your environmental awareness today
                    and safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and
                    ensure a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Fire Station Map</h2>
                    <img
                        title="EnviroHomePro's Fire Station Map"
                        className="map-image"
                        src={mapImage}
                        alt="Fire Station Map"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Fire Station Counts by City and State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Benefits of Living Near a Fire Station
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a fire station offers a multitude of benefits, providing residents with
                            increased safety, peace of mind, and potentially even economic advantages. Here's a closer
                            look at the benefits of residing in close proximity to a fire station:
                            <br />
                            <br />
                            <b>Quick Emergency Response:</b> One of the most significant benefits of living near a fire station
                            is the rapid emergency response in the event of a fire or other crises. With firefighters
                            stationed nearby, response times are typically shorter, allowing for quicker intervention to
                            contain fires and mitigate other emergencies. This swift response can significantly reduce
                            property damage and, more importantly, save lives.
                            <br />
                            <br />
                            <b>Enhanced Fire Protection:</b> Proximity to a fire station often means enhanced fire protection
                            for surrounding homes and businesses. Firefighters conduct regular inspections of
                            properties, offer safety education to residents, and may provide recommendations for fire
                            prevention measures. Additionally, living near a fire station can lead to lower insurance
                            premiums due to the reduced risk of fire-related damage.
                            <br />
                            <br />
                            <b>Medical Emergencies:</b> Fire stations are not only equipped to respond to fires but also to
                            medical emergencies. Many fire stations have trained emergency medical technicians (EMTs) or
                            paramedics on staff who can provide life-saving medical assistance. In cases of cardiac
                            arrest, severe injuries, or other medical crises, quick access to trained professionals can
                            significantly improve outcomes.
                            <br />
                            <br />
                            <b>Community Engagement:</b>  Fire stations often serve as community hubs, hosting events, offering
                            educational programs, and engaging with residents. Living nearby provides opportunities for
                            interaction with firefighters and participation in community events such as open houses,
                            safety demonstrations, and CPR training sessions. This fosters a sense of belonging and
                            community spirit, enhancing overall well-being.
                            <br />
                            <br />
                            <b>Property Value:</b> The presence of a fire station nearby can positively impact property values.
                            Potential homebuyers often prioritize safety and emergency services when choosing a place to
                            live, and proximity to a fire station can make a neighborhood more desirable. Consequently,
                            homes located near fire stations may command higher resale values and experience increased
                            demand in the real estate market.
                            <br />
                            <br />
                            <b>Sense of Security:</b> Knowing that a fire station is nearby can instill a sense of security and
                            peace of mind among residents. Whether it's a family with young children, elderly
                            individuals, or individuals living alone, having prompt access to emergency services can
                            alleviate anxiety and fear associated with potential emergencies. This sense of security
                            contributes to overall quality of life and community satisfaction.
                            <br />
                            <br />
                            <b>Prevention and Education:</b> Firefighters stationed at fire stations often engage in proactive
                            measures to prevent fires and promote safety within the community. They may offer fire
                            safety education programs for schools, businesses, and community groups, distribute smoke
                            detectors, and conduct fire drills. These initiatives not only reduce the likelihood of
                            emergencies but also empower residents to take proactive steps to protect themselves and
                            their property.
                            <br />
                            <br />
                            In conclusion, living near a fire station offers numerous benefits, including quick
                            emergency response, enhanced fire protection, access to medical assistance, community
                            engagement, increased property value, a sense of security, and proactive prevention and
                            education efforts. These advantages contribute to safer, more resilient communities where
                            residents can enjoy peace of mind and a higher quality of life.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default FireStations;
