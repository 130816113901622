import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Trains.css";
import jsonData from "./TrainTrackCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function Trains() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Train Tracks Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover train tracks within 1.25 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Train Tracks Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> train tracks within a 1.25-mile radius</strong> of your property by leveraging
                    the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into oil wells but also into 40 other critical environmental
                    factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics Release
                    Inventory sites to public drinking water systems, air and noise pollution sources, natural hazards,
                    and livability factors, EnviroHomePro provides comprehensive data to empower you to make informed
                    decisions about your surroundings. Take control of your environmental awareness today and safeguard
                    your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure a
                    healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 100 Train Track Owners by Count</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Train Tracks & Noise Pollution</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a train track can subject nearby residents to significant noise pollution,
                            impacting their quality of life and potentially leading to adverse health effects. The noise
                            generated by passing trains includes a combination of engine noise, horn blasts, braking,
                            and track vibrations, which can be disruptive and intrusive, especially during nighttime
                            hours when residents are trying to sleep.
                            <br />
                            <br />
                            The World Health Organization (WHO) identifies noise pollution as a major environmental
                            health concern, linking prolonged exposure to excessive noise levels with various health
                            problems, including sleep disturbances, cardiovascular diseases, and mental health issues.
                            According to WHO guidelines, nighttime noise levels above 40 decibels (dB) can disturb sleep
                            and increase the risk of adverse health effects.
                            <br />
                            <br />
                            Living near a train track exposes residents to noise levels well above this threshold,
                            particularly during the passage of freight trains, which are often heavier and noisier than
                            passenger trains. The intermittent nature of train noise, characterized by sudden bursts of
                            loud sounds, can disrupt sleep patterns and lead to chronic sleep deprivation, which has
                            been associated with increased risks of obesity, diabetes, and cardiovascular diseases.
                            <br />
                            <br />
                            Furthermore, noise pollution from trains can also have non-auditory effects on health, such
                            as stress, annoyance, and decreased overall well-being. Studies have shown that chronic
                            exposure to high levels of environmental noise can trigger physiological stress responses,
                            including elevated heart rate, increased blood pressure, and heightened levels of stress
                            hormones.
                            <br />
                            <br />
                            Efforts to mitigate noise pollution from train tracks may include the installation of noise
                            barriers, sound-absorbing materials, and track maintenance to reduce vibration and noise
                            emissions. Additionally, urban planning strategies such as land use zoning and building
                            design can help minimize residential exposure to train noise by creating buffer zones and
                            implementing soundproofing measures in affected areas.
                            <br />
                            <br />
                            In conclusion, living near a train track exposes residents to elevated levels of noise
                            pollution, which can have detrimental effects on sleep, health, and overall well-being.
                            Effective noise abatement measures and urban planning strategies are essential to mitigate
                            these impacts and ensure a healthier living environment for affected communities.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Trains;
