import { Helmet } from "react-helmet";
import "./Policy.css";

function Terms() {
    return (
        <div className="policy-page">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy | EnviroHomePro</title>
                <meta name="description" content="EnviroHomePro's Privacy Policy" />{" "}
            </Helmet>
            <h1 class="privacy-policy-title">Privacy Policy</h1>

            <div class="privacy-policy">
                <strong className="policy-item">1. About This Privacy Policy</strong>

                <p className="policy-description">
                    This privacy policy applies when you visit or use our websites, apps and other services that refer
                    or link to this privacy policy (each, a &ldquo;Service&rdquo;). This privacy policy may be
                    supplemented by additional privacy statements, terms or notices provided to you.
                </p>
                <p className="policy-description">
                    EnviroHomePro, LLC (&ldquo;EnviroHomePro&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;),
                    which owns or administers the Service is the primary controller of your personal information
                    provided to, or collected by or for, the Service.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">2. Information We Collect</strong>
                <p className="policy-description">
                    We may collect information about you in three ways: directly from your input, from third-party
                    sources and through automated technologies.
                </p>
                <p className="policy-description">2.1 Data You Provide to Us</p>
                <p className="policy-description">
                    The types of personal information that we collect directly from you depends on how you interact with
                    us and the Service, which may include:
                </p>
                <ul>
                    <li className="policy-lists">
                        Contact details, such as your name, email address, postal address, social media handle, and
                        phone number;
                    </li>
                    <li className="policy-lists">
                        Account login credentials, such as usernames and passwords, password hints and similar security
                        information;
                    </li>
                    <li className="policy-lists">Other account registration and profile information;</li>
                    <li className="policy-lists">
                        Payment information, such as wire instructions, ACH information or a credit or debit card
                        number; and/or
                    </li>
                    <li className="policy-lists">
                        Comments, feedback and other information you provide to us, including search query data and
                        questions or information you send to customer support.
                    </li>
                </ul>
                <p className="policy-description">2.2 Data from Your Organization</p>
                <p className="policy-description">
                    We may obtain personal information about you from the organization with which you are employed or
                    affiliated in order to activate and manage your access to and use of the organization&rsquo;s
                    subscription to the Service, including:
                </p>
                <ul>
                    <li className="policy-lists">
                        Contact&nbsp;details, such as your name and organizational email address, postal address, and
                        phone number;
                    </li>
                    <li className="policy-lists">Other account registration information such as job title; and/or</li>
                    <li className="policy-lists">Organizational user ID.</li>
                </ul>
                <p className="policy-description">2.3 Data from Other Sources</p>
                <p className="policy-description">
                    We also may obtain contact details and other information about you from our affiliates and from
                    other third parties, including:
                </p>
                <ul>
                    <li className="policy-lists">
                        Businesses with which we offer co-branded services or engage in joint marketing activities;
                        and/or
                    </li>
                    <li className="policy-lists">
                        Publicly available sources and data suppliers from which we obtain data to validate or
                        supplement the information we hold.
                    </li>
                </ul>
                <p className="policy-description">2.4 Data from Service Use, Including Cookies</p>
                <p className="policy-description">
                    The Service may automatically collect information about how you and your device interact with the
                    Service, including:
                </p>
                <ul>
                    <li className="policy-lists">
                        Computer, device and connection information, such as IP address, browser type and version,
                        operating system and other software installed on your device, mobile platform and unique device
                        identifier and other technical identifiers, error reports and performance data; and/or
                    </li>
                    <li className="policy-lists">
                        Usage data, such as the features you used, the settings you selected, your URL click stream
                        data, including date and time stamp and referring and exit pages, search terms you used, and
                        pages you visited or searched for on the Service.
                    </li>
                </ul>
                <p className="policy-description">
                    We collect this data through our servers and the use of cookies and other technologies. Cookies are
                    small text files that can be read by a web server in the domain that put the cookie on your hard
                    drive. We may use cookies and other technologies to store your preferences and settings, help you
                    with signing in, provide targeted ads and analyze site operations. You can control cookies through
                    your browser&rsquo;s settings and other tools. However, if you block certain cookies, you may not be
                    able to register, login, or access certain parts or make full use of the Service.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">3. How We Use Your Information</strong>
                <p className="policy-description">
                    Depending on how you interact with us and the Service, we use your personal information to:
                </p>
                <ul>
                    <li className="policy-lists">Provide, activate and manage your access to and use of the Service;</li>
                    <li className="policy-lists">Process and fulfill a request, order, download, subscription or other transaction;</li>
                    <li className="policy-lists">
                        Provide technical, product and other support and to help keep the Service working, safe and
                        secure;
                    </li>
                    <li className="policy-lists">
                        Enhance and improve the Service and our other products, events, and services and to develop new
                        products, services and benefits;
                    </li>
                    <li className="policy-lists">Respond to your requests, inquiries, comments and concerns;</li>
                    <li className="policy-lists">
                        Notify you about changes, updates and other announcements related to the Service and our other
                        products and services;
                    </li>
                    <li className="policy-lists">
                        Deliver targeted advertisements, promotional messages, notices and other information related to
                        the Service and your interests;
                    </li>
                    <li className="policy-lists">
                        Provide you with promotional messages and other information about products, events and services
                        of ours, our affiliates and third parties such as sponsors;
                    </li>
                    <li className="policy-lists">Invite you to participate in user testing and surveys;</li>
                    <li className="policy-lists">
                        Identify usage trends and develop data analysis, including for purposes of research, audit,
                        reporting and other business operations; and/or
                    </li>
                    <li className="policy-lists">Comply with our legal obligations, resolve disputes, and enforce our agreements.</li>
                </ul>
                <p className="policy-description">
                    If you are an administrator of an organization with a subscription to the Service, we will use your
                    details to communicate with you about your organization&rsquo;s subscription and related services.
                    If you supply us contact information of your colleagues, we may contact those individuals with
                    communications about the Service that may include reference to you.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">4. Sharing of Your Information</strong>
                <p className="policy-description">4.1 Your Organization</p>
                <p className="policy-description">
                    If you access the Service through a subscription administered by your organization, your personal
                    information and certain usage data gathered through the Service may be shared with the
                    administrators authorized by your organization for the purposes of usage analysis, subscription
                    management and compliance, training course progress, performance and remediation, cost attribution
                    and departmental budgeting.
                </p>
                <p className="policy-description">4.2 For Legal Reasons</p>
                <p className="policy-description">
                    We also will disclose your personal information if we have a good faith belief that such disclosure
                    is necessary to:
                </p>
                <ul>
                    <li className="policy-lists">meet any applicable law, regulation, legal process or other legal obligation;</li>
                    <li className="policy-lists">detect, investigate and help prevent security, fraud or technical issues; and/or</li>
                    <li className="policy-lists">protect the rights, property or safety of EnviroHomePro, our users, employees or others; and</li>
                    <li className="policy-lists">
                        as part of a corporate transaction, such as a transfer of assets to or an acquisition by or
                        merger with another company.
                    </li>
                </ul>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">5. Grounds for Processing</strong>
                <p className="policy-description">
                    When we collect or otherwise process any personal information within the scope of European or other
                    applicable data protection laws, we do so:
                </p>
                <ul>
                    <li className="policy-lists">
                        where necessary to provide the Service, fulfill a transaction or otherwise perform a contract
                        with you or at your request prior to entering into a contract;
                    </li>
                    <li className="policy-lists">where&nbsp;necessary for our compliance with applicable law or other legal obligation;</li>
                    <li className="policy-lists">where necessary for the performance of a task carried out in the public interest;</li>
                    <li className="policy-lists">where&nbsp;necessary to enable our customers to comply with their legal obligations;</li>
                    <li className="policy-lists">where applicable, with your consent; and/or</li>
                    <li className="policy-lists">
                        where&nbsp;necessary to operate our business, protect the security of our systems, customers and
                        users, detect or prevent fraud, or fulfill our other legitimate interests as described above,
                        except where our interests are overridden by your privacy rights.
                    </li>
                </ul>
                <p className="policy-description">
                    Where we rely on your consent to process personal information, you have the right to withdraw your
                    consent at any time, and where we rely on legitimate interests, you may have the right to object to
                    our processing.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">6. Data Retention</strong>
                <p className="policy-description">
                    We retain your personal information for as long as necessary to provide the Service and fulfill the
                    transactions you have requested, or for other essential purposes such as complying with our legal
                    obligations, maintaining business and financial records, resolving disputes, maintaining security,
                    detecting and preventing fraud and abuse, and enforcing our agreements. If you access the Service
                    through a subscription administered or sponsored by your organization, we may retain your
                    organizational contact details after the termination of your organization&rsquo;s subscription to
                    continue to communicate with you.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">7. Locations of Processing</strong>
                <p className="policy-description">
                    Your personal information may be stored and processed in your region or another country where
                    EnviroHomePro and its service providers maintain servers and facilities, including the United States. We
                    take steps, including through contracts, to ensure that the information continues to be protected
                    wherever it is located and, in a manner, consistent with the standards of protection required under
                    applicable law.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">8. Data Security</strong>
                <p className="policy-description">
                    We implement technical and organizational measures to seek to ensure a level of security appropriate
                    to the risk to the personal information we process. These measures are aimed at ensuring the
                    integrity, confidentiality, and availability of personal information.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">9. Children&rsquo;s Privacy</strong>
                <p className="policy-description">
                    We do not knowingly collect information from children under the age of 13 or target the Service to
                    children under 13.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">10. Accessing and Updating Your Information</strong>
                <p className="policy-description">10.1 Your Account</p>
                <p className="policy-description">
                    The Service may allow registered users to directly access and review their account information and
                    make corrections or updates upon login at any time. Keeping such information up to date is the
                    responsibility of the user. Registered users may also close their account directly through the
                    Service or by contacting the Service&rsquo;s customer support.
                </p>
                <p className="policy-description">10.2 Your Rights</p>
                <p className="policy-description">
                    You&nbsp;may have the right under European and certain other privacy and data protection laws, as
                    may be applicable, to request free of charge:
                </p>
                <ul>
                    <li className="policy-lists">access to your personal information;</li>
                    <li className="policy-lists">rectification or erasure of your personal information;</li>
                    <li className="policy-lists">
                        restriction of our processing of your personal information, or to object to our processing; and
                    </li>
                    <li className="policy-lists">portability of your personal information.</li>
                </ul>
                <p className="policy-description">
                    If you wish to exercise these rights, please submit your request in writing to:{" "}
                    <p className="policy-description">
                        <br/>
                        2336 SE Ocean Blvd
                        <br/>Stuart, FL 34996
                        <br/>
                        <br/>
                        We will respond to your request
                    consistent with applicable laws. To protect your privacy and security, we may require you to verify
                    your identity.
                </p>
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">11. Changes</strong>
                <p className="policy-description">
                    We will update this privacy policy from time to time. Any changes will be posted on this page with
                    an updated revision date. If we make any material changes, we will provide notice through the
                    Service or by other means.
                </p>
                <p className="policy-description">&nbsp;</p>
                <strong className="policy-item">12. Contact</strong>
                <p className="policy-description">
                    If you have any questions, comments, complaints or requests regarding this privacy policy or our
                    processing of your information, please contact us via email at info@EnviroHomePro.com.
                </p>
                <p className="policy-description">Effective date: February 19, 2024</p>
                <p className="policy-description">Last Updated: Feburary 19, 2024</p>
                <p className="policy-description">&nbsp;</p>
            </div>
        </div>
    );
}

export default Terms;
