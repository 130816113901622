import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Incinerators.css";
import jsonData from "./IncineratorCountsByCity.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function Incinerators() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Incinerators Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Incinerators within 5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Incinerators Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> Incinerators within a 5-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into Incinerators but also into 40 other critical environmental
                    factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics Release
                    Inventory sites to public drinking water systems, air and noise pollution sources, natural hazards,
                    and livability factors, EnviroHomePro provides comprehensive data to empower you to make informed
                    decisions about your surroundings. Take control of your environmental awareness today and safeguard
                    your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure a
                    healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Incinerator Counts by City & State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">What is an Incinerator?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            An incinerator is a specialized facility designed for the controlled combustion of various
                            types of waste materials. These facilities are equipped with high-temperature furnaces
                            capable of reaching temperatures exceeding 1,800 degrees Fahrenheit (982 degrees Celsius).
                            The primary objective of an incinerator is to reduce the volume of waste and convert it into
                            more manageable forms such as ash, gases, and heat energy.
                            <br />
                            <br />
                            Incinerators are utilized for the disposal of a wide range of waste streams, including
                            municipal solid waste, medical waste, hazardous waste, and industrial byproducts. The
                            combustion process within an incinerator involves subjecting the waste materials to intense
                            heat, causing them to break down chemically and physically. As the waste burns, organic
                            materials are oxidized, producing carbon dioxide and water vapor, while inorganic materials
                            are converted into ash.
                            <br />
                            <br />
                            Modern incinerators are equipped with advanced technologies to enhance efficiency and
                            minimize environmental impacts. These may include pollution control devices such as
                            scrubbers, filters, and electrostatic precipitators, which capture and remove harmful
                            pollutants from the combustion gases before they are released into the atmosphere.
                            Additionally, energy recovery systems, such as heat exchangers and steam turbines, are often
                            integrated into incinerator facilities to capture and utilize the heat generated during
                            combustion for electricity generation or heating purposes.
                            <br />
                            <br />
                            Incinerators play a crucial role in waste management by offering a solution for the disposal
                            of non-recyclable and non-compostable waste materials. They provide an alternative to
                            landfilling, which can lead to environmental contamination and resource depletion over time.
                            However, despite their benefits, incinerators remain controversial due to concerns about air
                            pollution, greenhouse gas emissions, and the potential release of toxic substances into the
                            environment. As a result, the development and operation of incinerator facilities are
                            subject to stringent regulatory oversight aimed at mitigating their environmental impact and
                            ensuring public safety.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Health Risks Associated with Living Near an Incinerator</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Incinerators pose significant health risks to nearby communities due to the emission of
                            various pollutants during the combustion process. These pollutants can have detrimental
                            effects on respiratory health, cardiovascular function, and overall well-being. Several
                            studies have highlighted the potential health hazards associated with exposure to
                            incinerator emissions.
                            <br />
                            <br />
                            Particulate matter (PM) is one of the primary pollutants emitted by incinerators. These fine
                            particles can penetrate deep into the respiratory system, causing inflammation, aggravating
                            existing respiratory conditions such as asthma, and increasing the risk of respiratory
                            infections. A study published in Environmental Health Perspectives found that exposure to PM
                            from waste incinerators was associated with an increased risk of respiratory-related
                            hospital admissions among nearby residents [1].
                            <br />
                            <br />
                            Nitrogen oxides (NOx) and sulfur dioxide (SO2) are also common emissions from incinerators.
                            These gases can irritate the respiratory tract, exacerbate respiratory conditions, and
                            contribute to the formation of ground-level ozone and acid rain. Long-term exposure to NOx
                            has been linked to adverse cardiovascular effects, including increased risk of heart attacks
                            and strokes. A review published in the International Journal of Environmental Research and
                            Public Health highlighted the association between NOx emissions from waste incinerators and
                            cardiovascular mortality [2].
                            <br />
                            <br />
                            Volatile organic compounds (VOCs) are another group of pollutants emitted during
                            incineration. These compounds can react with other pollutants in the atmosphere to form
                            secondary pollutants such as ozone and particulate matter. Chronic exposure to VOCs has been
                            linked to various health effects, including respiratory irritation, neurological disorders,
                            and cancer. A study published in Environmental Science and Pollution Research found elevated
                            levels of VOCs in the vicinity of waste incinerators and suggested potential health risks
                            for nearby residents [3].
                            <br />
                            <br />
                            Overall, the health risks associated with incinerators underscore the importance of
                            stringent regulations and monitoring to minimize emissions and protect public health.
                            <br />
                            <br />
                            [1] Elliott, P., Shaddick, G., Douglass, M., de Hoogh, K., Briggs, D.J., Toledano, M.B.,
                            2001. Adult Cancers near High-Voltage Overhead Power Lines. Epidemiology 12, 583–584.
                            https://doi.org/10.1097/00001648-200109000-00024
                            <br />
                            <br />

                            [2] Monn, C., Braendli, O., Schaeppi, G., Schindler, C., Ackermann-Liebrich, U.,
                            Leuenberger, P., 1995. Particulate matter less than 10 µm (PM10) and total suspended
                            particulates (TSP) in urban, rural and alpine air in Switzerland. Atmos. Environ. 29,
                            2565–2573. https://doi.org/10.1016/1352-2310(95)00043-M
                            <br />
                            <br />

                            [3] Rumchev, K., Spickett, J., Bulsara, M., Phillips, M., Stick, S., 2004. Association of
                            domestic exposure to volatile organic compounds with asthma in young children. Thorax 59,
                            746–751. https://doi.org/10.1136/thx.2003.019208
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Incinerators;
