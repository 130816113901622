import "./ReportMenu.css";
import { useNavigate } from "react-router-dom";

import JSPDFReport from "../../components/PDFReport/JSPDFReport.js";
import DownloadIcon from "@mui/icons-material/Download";
import { IoChevronBackCircle } from "react-icons/io5";
import { CiMap } from "react-icons/ci";

const ReportMenu = (props) => {
    const navigate = useNavigate();
    const data = null;
    // let reportData = props.reportData

    const goToMap = async () => {
        const extractedGeojson = [];

        // Iterate thr// Iterate through each category in the object
        Object.keys(props.reportData).forEach((category) => {
            const categoryData = props.reportData[category];

            // Iterate through each object in the category array
            categoryData.forEach((obj) => {
                if (obj.data.results?.length > 0) {
                    // Iterate through results array of each object
                    obj.data.results.forEach((result) => {
                        // Check if the result object has geojson field
                        if (result.geojson) {
                            let className = obj.data.type.toLowerCase();
                            let distance = result.Distance.toFixed(2);
                            // Replace spaces with dashes
                            className = className.replace(/\s+/g, "-");

                            const newObj = {
                                geojson: result.geojson,
                                type: obj.data.type,
                                class: className,
                                distance: distance,
                                result: result

                            };
                            // Push the new object to the extractedGeojson array
                            extractedGeojson.push(newObj);
                        }
                    });
                }
            });
        });
        navigate("/residential-environmental-search/map", {
            state: {
                data: extractedGeojson,
                lat: props.lat,
                long: props.long,
                addressLine1: props.addressLine1,
                addressLine2: props.addressLine2,
                city: props.city,
                state: props.stateLong,
                postalCode: props.postalCode,
            },
        });
    };

    return (
        <div className="panel-div">
            <div className="panel-menu">
                <div className="report-drop-down-div">
                    <IoChevronBackCircle
                        className={`back-icon ${props.selectedTab === "summary" ? "hide-back-icon" : ""}`}
                        onClick={() => props.handleBackButton()}
                    />
                </div>
                <div className="download-div">
                    <div
                        className="download-button"
                        // onClick="gtag('event','Report Download');"
                        onClick={() =>
                            JSPDFReport(
                                props.reportData,
                                props.overallScore,
                                props.totalPossibleScores,
                                props.lat,
                                props.long,
                                props.addressLine1,
                                props.addressLine2,
                                props.city,
                                props.stateLong,
                                props.postalCode
                            )
                        }>
                        <DownloadIcon className="download-icon" />
                        <p className="button-text">&nbsp;Download</p>
                    </div>
                </div>
                <div className="map-button-div">
                    <div className="map-button" onClick={() => goToMap()}>
                        <CiMap className="map-icon" />

                        <p className="button-text">&nbsp;View Map</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportMenu;
