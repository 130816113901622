import React, { useState } from "react";
import PricingOptions from "../../components/PricingOptions/PricingOptions.js";
import { Helmet } from "react-helmet";
import "./Pricing.css";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { apiCall } from "../../service/UserServices.js";
import Loading from "../../components/Loading/Loading.js";
import { Auth } from "aws-amplify";

function Pricing() {
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState(null);
    const [stripeId, setStripeId] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const sub = searchParams.get("sub");
    const navigate = useNavigate();

    React.useEffect(() => {
        const checkStripeStatus = async () => {
            const attributes = await Auth.currentUserInfo();
            setEmail(attributes.attributes.email);

            let stripeCustomer = await apiCall("/users/stripe-id?", { sub });

            //Error Handling
            const statuses = [stripeCustomer.status];
            const messages = [stripeCustomer.response?.data ?? null];
            const index = statuses.findIndex((status) => status !== 200);

            if (index !== -1) {
                navigate(`/error?message=${messages[index]}`);
            }

            setStripeId(stripeCustomer.data.stripeId);
            setIsLoading(false);
        };
        checkStripeStatus().catch(console.error);
    }, []);

    const setPayPerReportSetting = async (sub) => {
        const data = {
            sub: sub,
        };
        let response = await apiCall("/users/pricing-plan/single", data, "POST");
        response.status != 200
            ? navigate(`/error?message=${response.message}`)
            : navigate("/residential-environmental-search");
    };

    return isLoading ? (
        <Loading title="Pricing"/>
    ) : (
        <div className="pricing-table-screen">
            <Helmet>
                <meta
                    name="description"
                    content="Opt for EnviroHomePro's Subscription or Pay-As-You-Go plans for your environmental due diligence needs. Tailored for you, choose what works best today!"
                />
                <title>Pricing | EnviroHomePro</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <h1 className="pricing-plan-h1">Choose the perfect plan for you</h1>
            <h2 className="pricing-plan-h2">
                A monthly plan or pay per report option must be chosen before you can review your report.
            </h2>
            {/* <div className="pricing-table-div">
                    <>
                        <stripe-pricing-table
                            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
                            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                            client-reference-id={sub}
                            customer={stripeId}
                            customer-email={email}
                            
                            >
                                </stripe-pricing-table>{" "}
                        <div className="per-report-plan">
                            <a onClick={(e) => setPayPerReportSetting(sub)} className="per-report-link">
                                Pay per report? Click here
                            </a>
                        </div>
                    </> */}
            {/* </div> */}
            <PricingOptions page="Pricing" email={email} />
        </div>
    );
}

export default Pricing;
