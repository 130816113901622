import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Nuclear.css";
import jsonData from "./NuclearFacilities.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function Nuclear() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Nuclear Power Plants Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Nuclear Power Plants within 20 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Nuclear Power Plants Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> nuclear power plants within a 5-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into nuclear power plants but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Toxics Release Inventory & Brownfield sites to public drinking
                    water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Nuclear Power Plant Map</h2>
                    <img
                        title="EnviroHomePro's Nuclear Power Plant Map"
                        className="map-image"
                        src="https://envirohomepro-media.s3.amazonaws.com/NuclearPowerPlantMap.png"
                        alt="Nuclear Power Plant Map"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Nuclear Power Plants</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Nuclear Power Plant Basics
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A nuclear power plant operates by harnessing the energy released during nuclear fission. The
                            process begins with uranium fuel rods placed within the reactor core. When a neutron strikes
                            a uranium nucleus, it splits, releasing a large amount of energy in the form of heat and
                            additional neutrons. These newly released neutrons then collide with other uranium nuclei,
                            initiating a chain reaction.
                            <br />
                            <br />
                            The heat generated by this reaction is used to boil water and produce steam. This steam
                            drives turbines connected to generators, converting kinetic energy into electrical energy.
                            The electricity generated is then transmitted to power grids for distribution.
                            <br />
                            <br />
                            Control rods made of materials like boron or cadmium are inserted into the reactor core to
                            regulate the rate of the chain reaction by absorbing excess neutrons. Additionally, coolant,
                            typically water, circulates around the reactor core to remove heat and maintain stable
                            operating temperatures.
                            <br />
                            <br />
                            Despite its efficiency in generating large amounts of electricity with minimal greenhouse
                            gas emissions, nuclear power faces challenges such as radioactive waste disposal, safety
                            concerns, and the risk of accidents. However, advancements in reactor design and safety
                            protocols continue to improve the overall operation and reliability of nuclear power plants.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Health & Safety Risks Living Near a Nuclear Power Plant</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a nuclear power plant poses potential health and safety risks, although the
                            degree of risk varies depending on factors such as distance from the plant, design and
                            maintenance of the facility, and emergency preparedness measures in place.
                            <br />
                            <br />
                            One primary concern is the release of radioactive materials into the environment. While
                            modern nuclear power plants are equipped with multiple layers of containment and safety
                            systems to prevent such releases, accidents or malfunctions can still occur. Radiation
                            exposure can increase the risk of cancer, genetic mutations, and other health problems.
                            Although regulatory agencies establish safety standards and monitor radiation levels, the
                            fear of a catastrophic accident persists, fueled by events like the Chernobyl disaster in
                            1986 and the Fukushima Daiichi accident in 2011.
                            <br />
                            <br />
                            Additionally, routine operations of nuclear power plants produce radioactive waste, which
                            requires careful handling and disposal. Even though waste is stored securely on-site or
                            transported to specialized facilities, concerns about long-term storage and potential leaks
                            into groundwater or nearby ecosystems remain.
                            <br />
                            <br />
                            Emergency preparedness is another crucial aspect of living near a nuclear power plant. While
                            plant operators conduct regular drills and maintain emergency response plans, the
                            possibility of accidents or natural disasters—such as earthquakes or floods—can challenge
                            these measures. Evacuation plans, communication systems, and availability of protective
                            measures like potassium iodide tablets are essential for minimizing risks during
                            emergencies.
                            <br />
                            <br />
                            Furthermore, psychological stress and social impacts can affect communities living near
                            nuclear power plants. Fear of radiation exposure, uncertainty about the future, and stigma
                            associated with living in proximity to a nuclear facility can lead to anxiety and decreased
                            well-being among residents.
                            <br />
                            <br />
                            Overall, while nuclear power provides a significant source of electricity with minimal
                            greenhouse gas emissions, the health and safety risks associated with living near a nuclear
                            power plant underscore the importance of rigorous regulation, transparent communication, and
                            continuous improvement of safety measures to protect both human health and the environment.
                        </div>
                    </div>
                </section>
            </div>
          
           
        </div>
    );
}

export default Nuclear;
