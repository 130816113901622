import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./EmergencyMedicalServices.css";
import jsonData from "./EMSCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function EmergencyMedicalServices() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover EMS Locations Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover EMS locations within 3 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find EMS Locations Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> EMS locations within a 3-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into EMS locations but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Toxics Release Inventory and Brownfield sites to public drinking
                    water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
             <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">EMS Counts by City and State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Benefits of Living Near an EMS (Emergency Medical Service) Location
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near EMS locations offers several crucial benefits that
                            can make a significant difference in emergency situations:
                            <br />
                            <br />
                            <b>Rapid Emergency Response:</b> Proximity to EMS stations means quicker response times
                            during medical emergencies. EMS personnel stationed nearby can reach the scene faster,
                            providing life-saving interventions such as cardiopulmonary resuscitation (CPR),
                            administering medications, and stabilizing patients before transport to a hospital.
                            <br />
                            <br />
                            <b>Critical Care Transport:</b> EMS stations often house ambulances equipped with advanced
                            medical equipment and staffed by paramedics trained to provide critical care during
                            transport. Living near an EMS station ensures access to rapid ambulance services for
                            individuals requiring urgent medical attention, including trauma patients, stroke victims,
                            and those experiencing cardiac emergencies.
                            <br />
                            <br />
                            <b>Specialized Medical Teams:</b> Some EMS stations are equipped with specialized medical
                            teams, such as tactical paramedics, search and rescue units, or hazardous materials response
                            teams. Living nearby increases the likelihood of timely assistance from these specialized
                            teams during complex emergencies, natural disasters, or mass casualty incidents.
                            <br />
                            <br />
                            <b>Community Health Initiatives:</b> EMS agencies often engage in community health
                            initiatives aimed at promoting injury prevention, CPR training, public health education, and
                            disaster preparedness. Residents living near EMS stations may benefit from access to these
                            programs, improving community resilience and emergency response capabilities.
                            <br />
                            <br />
                            <b>Enhanced Public Safety:</b> The presence of EMS stations contributes to enhanced public
                            safety and peace of mind within communities. Knowing that trained medical professionals are
                            nearby can alleviate anxiety and fear associated with medical emergencies, accidents, or
                            sudden illnesses.
                            <br />
                            <br />
                            <b>Community Engagement:</b> EMS stations serve as community hubs, hosting events, offering
                            public tours, and providing opportunities for residents to engage with EMS personnel. Living
                            nearby facilitates interaction with paramedics, emergency medical technicians (EMTs), and
                            other healthcare professionals, fostering community relationships and collaboration.
                            <br />
                            <br />
                            <b>Access to First Aid and Medical Supplies:</b> EMS stations often serve as distribution
                            points for first aid kits, medical supplies, and public access defibrillators (PADs). Living
                            near an EMS station ensures access to these resources, which can be critical in emergencies
                            and potentially life-saving for individuals experiencing cardiac arrest, severe bleeding, or
                            other medical crises.
                            <br />
                            <br />
                            <b>Emergency Preparedness Education:</b> EMS agencies play a vital role in emergency
                            preparedness education, teaching residents how to respond effectively to emergencies,
                            administer first aid, and perform CPR. Living near an EMS station provides opportunities for
                            residents to participate in training courses, drills, and community preparedness
                            initiatives.
                            <br />
                            <br />
                            In summary, living near EMS locations offers numerous benefits, including rapid emergency
                            response, critical care transport, access to specialized medical teams, community health
                            initiatives, enhanced public safety, community engagement opportunities, access to first aid
                            supplies, and emergency preparedness education. These advantages contribute to safer, more
                            resilient communities where residents can receive timely medical assistance and support
                            during emergencies.
                        </div>
                    </div>
                </section>
            </div>

           
        </div>
    );
}

export default EmergencyMedicalServices;
