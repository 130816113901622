import React, { useState } from "react";
import { useParams, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import "./Dashboard.css";
import { Helmet } from "react-helmet";
import Loading from "../../components/Loading/Loading.js";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay.js";
import { BiVolumeFull, BiHealth } from "react-icons/bi";
import { MdSecurity, MdAdd } from "react-icons/md";
import { TbMoodAnnoyed } from "react-icons/tb";
import { CiShoppingTag } from "react-icons/ci";
import { apiCall } from "../../service/UserServices.js";
import { Auth } from "aws-amplify";
import MaterialTable from "../../components/MaterialTable/MaterialTable.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { MdInfoOutline } from "react-icons/md";
import "../../pages/ReportSummary/Popovers.css";
import Popover from "react-bootstrap/Popover";

const paymentPopover = (
    <Popover className="info-popover">
        <h3 className="info-popover-header">Payment Information</h3>
        <p className="info-popover-text">
            EnviroHomePro uses Stripe for payment processing. Updating saved payment methods, subscription information, and
            invoices can be found by clicking the "Manage Billing" button.
            <br />
            You will be redirected to Stripes secure payment portal.
        </p>
    </Popover>
);

function Dashboard() {
    const navigate = useNavigate();
    const [sub, setSub] = useState("");
    const [email, setEmail] = useState("");
    const [pricingPlanName, setPricingPlanName] = useState("");
    const [reportList, setReportList] = useState([]);
    const [reportAPIResponse, setReportAPIResponse] = useState(null);
    const [pricingAPIResponse, setPricingAPIResponse] = useState(null);
    const [stripeCustomer, setStripeCustomer] = useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const getUserData = async () => {
            const attributes = await Auth.currentUserInfo();
            let sub = attributes.attributes.sub;
            setSub(attributes.attributes.sub);
            setEmail( attributes.attributes.email);

            const [reports, pricingPlan, stripeCustomer] = await Promise.all([
                apiCall("/users/reports?", { sub }),
                apiCall("/users/pricing-plan?", { sub }),
                apiCall("/users/stripe-id?", { sub }),
            ]);

            //Error Handling
            const statuses = [reports.status, pricingPlan.status, stripeCustomer.status];
            const messages = [reports.response?.data ?? null, pricingPlan.response?.data ?? null, stripeCustomer.response?.data ?? null];
            
            const index = statuses.findIndex(status => status !== 200);
            
            if (index !== -1) {
                navigate(`/error?message=${messages[index]}`);
            }

            setReportAPIResponse(reports.status != undefined ? reports.status : reports.message);
            setPricingAPIResponse(pricingPlan.status != undefined ? pricingPlan.status : pricingPlan.message);
            setStripeCustomer(stripeCustomer);

            if (reports.status == 200) {
                let cleansedData = await fixDateFormatting(reports.data.data);
                setReportList(cleansedData);
            }
            if (pricingPlan.status == 200) {
                if (pricingPlan.data.plan == 1) {
                    setPricingPlanName("Pay Per Report");
                } else if (pricingPlan.data.plan == 2) {
                    setPricingPlanName("Monthly Premium");
                } else {
                    setPricingPlanName("No Plan");
                }
            }
            setIsLoading(false);
        };
        getUserData().catch(console.error);
    }, []);

    const goToReport = (id) => {
        navigate({
            pathname: "/residential-environmental-search/report-summary",
            search: createSearchParams({
                id: id,
            }).toString(),
        });
    };

    const goToPricing = () => {
        navigate(`/pricing?sub=${sub}`);
    };

    const fixDateFormatting = async (data) => {
        for (let i = 0; i < data.length; i++) {
            data[i]["PurchaseDate"] = data[i].PurchaseDate.split("T")[0];
        }
        return data;
    };
    return isLoading ? (
        <Loading title="Dashboard"/>
    ) : (
        <div className="dashboard-screen">
              <Helmet>
                <title>Dashboard | EnviroHomePro</title>
            </Helmet>
            <div className="dashboard-container">
                <div className="purchased-reports-container">
                    <h2 className="purchased-reports-container-h2"> Reports<p className="reports-user-email">User: {email.toLowerCase()}</p></h2>
                    {reportAPIResponse == 200 ? (
                        <MaterialTable data={reportList} goToReport={goToReport} />
                    ) : (
                        <ErrorDisplay type="report" />
                    )}
                </div>
                <div className="account-container">
                    <div className="subscription-container-grid">
                        <div className="plan-details subscription-card">
                            <h3 className="subscription-card-h3">Account Type</h3>
                            <div className="card-details">
                                {pricingAPIResponse == 200 ? (
                                    <div className="billing-value">
                                        {" "}
                                        <p className="pricing-name">{pricingPlanName}</p>
                                        {pricingPlanName == "Pay Per Report" || pricingPlanName == "No Plan" ? (
                                            <a className="change-plan-button" onClick={(e) => goToPricing()}>
                                                Choose Your Plan
                                            </a>
                                        ) : null}
                                    </div>
                                ) : (
                                    <ErrorDisplay type="plan" />
                                )}
                            </div>
                        </div>
                        <div className="manage-account subscription-card">
                            <h3 className="subscription-card-h3">Manage Your Payment Information</h3>
                            <div className="card-details">
                                {stripeCustomer.status == 200 ? (
                                    stripeCustomer.data.stripeId == undefined ? (
                                        <p className="payment-information">No Payment Information Saved</p>
                                    ) : (
                                        <form
                                            className="stripe-portal-form"
                                            action={process.env.REACT_APP_BACKEND_URL + "/stripe/create-portal-session"}
                                            encType="multipart/form-data"
                                            method="POST">
                                            <input type="hidden" id="sub" name="sub" value={sub} />
                                            <button className="billing-portal-button" type="submit">
                                                Manage Billing
                                            </button>
                                        </form>
                                    )
                                ) : (
                                    <ErrorDisplay type="plan" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
