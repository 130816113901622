import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import RouteChangeTracker from "./components/GoogleAnalytics/GoogleAnalytics.js";
import HomePage from "./pages/HomePage/HomePage";

//Categories
import NoisePollution from "./pages/NoisePollution/NoisePollution";
import AirPollution from "./pages/AirPollution/AirPollution";
import HealthRisks from "./pages/HealthRisks/HealthRisks";
import Livability from "./pages/Livability/Livability";
import NaturalHazards from "./pages/NaturalHazards/NaturalHazards";

//SEO Pages
import DrinkingWater from "./pages/DrinkingWater/DrinkingWater";
import ToxicsReleaseInventory from "./pages/ToxicsReleaseInventory/ToxicsReleaseInventory";
import Superfund from "./pages/Superfund/Superfund";
import Brownfield from "./pages/Brownfield/Brownfield";
import Nuclear from "./pages/Nuclear/Nuclear";
import NuclearPlantsByState from "./pages/NuclearPlantsByState/NuclearPlantsByState";
import Asbestos from "./pages/Asbestos/Asbestos";
import Wastewater from "./pages/Wastewater/Wastewater";
import FormerDefenseSites from "./pages/FormerDefenseSites/FormerDefenseSites";
import CellTowers from "./pages/CellTowers/CellTowers";
import TransmissionLines from "./pages/TransmissionLines/TransmissionLines";

import Airports from "./pages/Airports/Airports";
import Trains from "./pages/Trains/Trains";
import Highways from "./pages/Highways/Highways";

import Landfills from "./pages/Landfills/Landfills";
import Incinerators from "./pages/Incinerators/Incinerators";
import PowerPlants from "./pages/PowerPlants/PowerPlants";
import Refineries from "./pages/Refineries/Refineries";
import NaturalGasWells from "./pages/NaturalGasWells/NaturalGasWells";
import OilWells from "./pages/OilWells/OilWells";
import PulpAndPaperMills from "./pages/PulpAndPaperMills/PulpAndPaperMills";

import Parks from "./pages/Parks/Parks";
import Hospitals from "./pages/Hospitals/Hospitals";
import PoliceStations from "./pages/PoliceStations/PoliceStations";
import EmergencyMedicalServices from "./pages/EmergencyMedicalServices/EmergencyMedicalServices";
import FireStations from "./pages/FireStations/FireStations";

import BlogPage from "./pages/BlogPage/BlogPage";

import Terms from "./pages/Terms/Terms";
import Policy from "./pages/Policy/Policy";
import Dashboard from "./pages/Dashboard/Dashboard";
import Pricing from "./pages/Pricing/Pricing";
import SubscriptionConfirmation from "./components/Stripe/SubscriptionConfirmation";
import SearchPage from "./pages/SearchPage/SearchPage";
import ReportSummary from "./pages/ReportSummary/ReportSummary";
import Map from "./pages/Map/Map";
import Navigation from "./components/NavBar/NavBar";
import Checkout from "./components/Checkout/Checkout";
import SuccessDisplay from "./components/Stripe/PurchaseConfirmation";
import Footer from "./components/Footer/Footer";
import Error from "./pages/Error/Error";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

//Auth
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

//Auth Components
import { RequireAuth } from "./components/Login/RequireAuth";
import { Login } from "./components/Login/Login";

// Configure Amplify in index file or root file
Amplify.configure({
    Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    },
});

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG;
ReactGA.initialize(trackingId);

function MyRoutes() {
    return (
        <BrowserRouter>
            <Navigation />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/noise-pollution" element={<NoisePollution />} />
                <Route path="/air-pollution" element={<AirPollution />} />
                <Route path="/health-risks" element={<HealthRisks />} />
                <Route path="/livability" element={<Livability />} />
                <Route path="/natural-hazards" element={<NaturalHazards />} />

                {/* SEO Target Pages */}
                {/* Health */}
                <Route path="/tap-water-database" element={<DrinkingWater />} />
                <Route path="/epa-toxics-release-inventory" element={<ToxicsReleaseInventory />} />
                <Route path="/epa-superfund-sites-near-you" element={<Superfund />} />
                <Route path="/brownfield-sites-near-you" element={<Brownfield />} />
                <Route path="/nuclear-power-plants-near-you" element={<Nuclear />} />
                <Route path="/list-of-nuclear-power-plants-by-state" element={<NuclearPlantsByState />} />
                <Route path="/asbestos-mines-and-occurences-near-you" element={<Asbestos />} />
                <Route path="/wastewater-treatment-plants-near-you" element={<Wastewater />} />
                <Route path="/formerly-used-defense-sites-near-you" element={<FormerDefenseSites />} />
                <Route path="/cellular-towers-near-you" element={<CellTowers />} />
                <Route path="/transmission-lines-near-you" element={<TransmissionLines />} />

                {/* Noise Pollution */}
                <Route path="/airports-near-you" element={<Airports />} />
                <Route path="/trains-near-you" element={<Trains />} />
                <Route path="/highways-near-you" element={<Highways />} />

                {/* Air Pollution */}
                <Route path="/landfills-near-you" element={<Landfills />} />
                <Route path="/incinerators-near-you" element={<Incinerators />} />
                <Route path="/power-plants-near-you" element={<PowerPlants />} />
                <Route path="/refineries-near-you" element={<Refineries />} />
                <Route path="/natural-gas-wells-near-you" element={<NaturalGasWells />} />
                <Route path="/oil-wells-near-you" element={<OilWells />} />
                <Route path="/pulp-and-paper-mills-near-you" element={<PulpAndPaperMills />} />

                {/* Livability */}
                <Route path="/parks-near-you" element={<Parks />} />
                <Route path="/hospitals-near-you" element={<Hospitals />} />
                <Route path="/police-stations-near-you" element={<PoliceStations />} />
                <Route path="/emergency-medical-services-near-you" element={<EmergencyMedicalServices />} />
                <Route path="/fire-stations-near-you" element={<FireStations />} />
                
                {/* Blog */}
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:category/" element={<BlogPage />} />
                <Route path="/blog/:category/:topic" element={<BlogPage />} />

                <Route path="/terms-of-service" element={<Terms />} title="EnviroHomePro | Terms and Conditions" />
                <Route path="/privacy-policy" element={<Policy />} title="EnviroHomePro | Privacy Policy" />
                <Route
                    path="/checkout-canceled"
                    element={
                        <RequireAuth>
                            <Dashboard />
                        </RequireAuth>
                    }
                    title="EnviroHome's Residential Environmental Search"
                />

                <Route
                    path="/login/:view?"
                    element={<Login />}
                    title="EnviroHome's Residential Environmental Search Login"
                />

                <Route
                    path="/dashboard"
                    element={
                        <RequireAuth>
                            <Dashboard />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/pricing/:sub?"
                    element={
                        <RequireAuth>
                            <Pricing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/account-confirmation/:session?"
                    element={
                        <RequireAuth>
                            <SubscriptionConfirmation />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/checkout"
                    element={
                        <RequireAuth>
                            <Checkout />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/checkout-confirmation/:session_id?"
                    element={
                        <RequireAuth>
                            <SuccessDisplay />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/residential-environmental-search"
                    element={
                        <RequireAuth>
                            <SearchPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/residential-environmental-search/report-summary"
                    element={
                        <RequireAuth>
                            <ReportSummary />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/residential-environmental-search/map"
                    element={
                        <RequireAuth>
                            <Map />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/error/:message?"
                    element={
                        <RequireAuth>
                            <Error />
                        </RequireAuth>
                    }
                />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

function App() {
    <RouteChangeTracker />

    return (
        <HelmetProvider>
            <Authenticator.Provider>
                <div className="App">
                    <MyRoutes />
                </div>
            </Authenticator.Provider>
        </HelmetProvider>
    );
}

export default App;
