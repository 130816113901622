import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import jsonData from "./ListofNuclearPlantsByState.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function NuclearPlantsByState() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>List of Nuclear Power Plants by State | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Explore nuclear power plants across the U.S. by state. Vital for homeowners and buyers seeking insights into energy infrastructure and its impact on residential areas."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>List of Nuclear Power Plants by State</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Our comprehensive<strong> list of nuclear power plants by state</strong> and map is just the beginning.
                    Gain invaluable insights not only into nuclear power plants but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Toxics Release Inventory & Brownfield sites to public drinking
                    water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">List of Nuclear Power Plants by State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>          
            <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Nuclear Power Plant Map</h2>
                    <img
                        title="EnviroHomePro's Nuclear Power Plant Map"
                        className="map-image"
                        src="https://envirohomepro-media.s3.amazonaws.com/NuclearPowerPlantMap.png"
                        alt="Nuclear Power Plant Map"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div>
        </div>
    );
}

export default NuclearPlantsByState;
