import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./PoliceStations.css";
import policeStationsByCity from "./PoliceStationsByCity.json";
import policeStationsByState from "./PoliceStationsByStateAndType.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function PoliceStations() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Police Stations Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover police stations sites within 5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Police Stations and Other Law Enforcement Agencies Near You</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> police stations sites within a 5-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into police presence but also into 40 other critical
                    environmental factors impacting your home and well-being. From Brownfield sites to public drinking
                    water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Police Station Map By Type</h2>
                    <img
                        title="EnviroHomePro's Police Station Map Type"
                        className="map-image"
                        src="https://envirohomepro-media.s3.amazonaws.com/PoliceStationMapByType.png"
                        alt="Police station map by type"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>

                    <div class="police-stations-legend">
                        <div className="police-stations-legend-item">
                            <div class="circle local-site"></div>
                            <div class="police-stations-legend-item">Local Police Department</div>
                        </div>
                        <div className="police-stations-legend-item">
                            <div class="circle special-jurisdiction-site"></div>
                            <div class="police-stations-legend-item">Special Jurisdiction</div>
                        </div>

                        <div className="police-stations-legend-item">
                            <div class="circle sheriffs-office"></div>
                            <div class="police-stations-legend-item">Sheriff's Office</div>
                        </div>
                        <div className="police-stations-legend-item">
                            <div class="circle primary-state-agency"></div>
                            <div class="police-stations-legend-item">Primary State Agency</div>
                        </div>
                        <div className="police-stations-legend-item">
                            <div class="circle constable-marshall"></div>
                            <div class="police-stations-legend-item">Constable/Marshall</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        <a
                            className="category-header-link"
                            href="https://bjs.ojp.gov/data-collection/census-state-and-local-law-enforcement-agencies-csllea"
                            target="_blank">
                            Which Law Enforcement Agencies Are Included In This Data?
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            The US Department of Justice - Bureau of Justice Statistics (DOJ-BJS) defines law
                            enforcement agencies as "a government unit with the authority to prevent crime, maintain
                            public order, and enforce criminal laws." and "are publicly funded and employ at least one
                            full-time or part-time sworn officer with general arrest powers". This definition
                            encompasses a wide range of agencies at various levels of government, including municipal
                            police departments, county sheriff's offices, state police agencies, and specialized law
                            enforcement agencies. These agencies may vary in size, jurisdiction, and specific
                            responsibilities but are all included in the CSLLEA to provide a comprehensive understanding
                            of law enforcement in the United States.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 50 Cities Ranked by Number of Police Stations</h2>
                    <div className="info-block">
                        <SEOTable header="" data={policeStationsByCity} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Benefits of Living Near a Law Enforcement Agency</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a law enforcement agency presents numerous advantages that significantly
                            contribute to the safety, cohesion, and quality of life within a community. One primary
                            benefit is the enhanced sense of security afforded by proximity to law enforcement
                            facilities. Residents often experience shorter response times during emergencies, as law
                            enforcement officers are readily available to address urgent situations. Moreover, the
                            presence of a law enforcement agency acts as a deterrent to criminal activity, fostering a
                            safer environment for residents and businesses alike.
                            <br />
                            <br />
                            Living near a law enforcement agency also promotes community engagement and collaboration.
                            Residents have increased opportunities to interact with law enforcement officers through
                            neighborhood watch programs, community meetings, and outreach events. These interactions
                            facilitate the development of trust and mutual respect between law enforcement and community
                            members, leading to more effective crime prevention efforts and problem-solving initiatives.
                            <br />
                            <br />
                            Furthermore, the physical presence of a law enforcement agency contributes to the overall
                            upkeep and aesthetics of the neighborhood. Well-maintained law enforcement facilities serve
                            as symbols of stability and security, positively influencing property values and community
                            pride. Overall, living near a law enforcement agency not only enhances safety but also
                            fosters a sense of belonging, cooperation, and civic responsibility within the community.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Police Stations by State and Type</h2>
                    <div className="info-block">
                        <SEOTable header="" data={policeStationsByState} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PoliceStations;
