import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IoAirplaneOutline, IoTrainOutline, IoCarSportOutline, IoRestaurantOutline } from "react-icons/io5";
import { TbMicrophone2 } from "react-icons/tb";
import { RiEarthquakeLine, RiTempColdLine, RiTornadoLine } from "react-icons/ri";
import {
    GiCampfire,
    GiLightningStorm,
    GiWaterDivinerStick,
    GiThermometerHot,
    GiIceSkate,
    GiSmokingVolcano,
    GiWinterGloves,
} from "react-icons/gi";
import { FiWind } from "react-icons/fi";
import { BsHurricane } from "react-icons/bs";
import { MdOutlineFlood, MdOutlineTsunami } from "react-icons/md";

function Noise() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
             <Helmet>
                <title>Natural Hazards: Sources and Home Impact</title>
                <meta
                    name="description"
                    content="Arm yourself with knowledge on natural hazard sources, their home impact, and livability risks. EnviroHomePro provides essential insights for informed decisions."
                />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">Natural Hazards: Sources & Impact</h1>
                <h2 className="info-page-pitch">
                    Residing in high-risk natural hazard areas entails diverse dangers, spanning earthquakes, wildfires,
                    floods, and more. Seismic activities threaten structures, wildfires cause economic losses, and
                    coastal regions face storm surges and tsunamis. Flood-prone areas encounter property damage, while
                    droughts and heat waves amplify water scarcity and health risks. Volcanic regions contend with lava
                    flows and toxic gases. These areas are susceptible to winter weather challenges and tornado-induced
                    destruction. Living amidst such risks necessitates robust infrastructure, emergency plans, and
                    community resilience to mitigate the varied and often devastating impacts of natural hazards.
                </h2>
            </div>
            <div className="info-div">
                <section className="info-section" id="earthquakes">
                    <h2 className="info-section-h2 left-h2">
                        Earthquakes <RiEarthquakeLine className="info-icon" />
                    </h2>

                    <div className="info-block">
                        <div className="info-section-text">
                            Earthquakes represent a significant seismic hazard, characterized by the sudden release of
                            energy in the Earth's crust, causing ground shaking and potential damage to structures. The
                            vulnerability of buildings and infrastructure depends on factors such as construction
                            materials and adherence to seismic building codes, emphasizing the need for resilient
                            structures. Earthquakes can trigger secondary hazards like landslides, tsunamis, and
                            aftershocks, complicating emergency response efforts. Communities in earthquake-prone
                            regions must prioritize emergency preparedness, including public education, early warning
                            systems, evacuation plans, and the establishment of emergency shelters. Building community
                            resilience involves not only infrastructure preparedness but also social cohesion and
                            effective communication, enabling individuals and communities to recover and adapt in the
                            aftermath of an earthquake.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Seismic Hazards:</b> Earthquakes pose a significant seismic hazard, resulting from
                                the sudden release of energy in the Earth's crust, causing ground shaking, surface
                                rupture, and potential damage to structures and infrastructure.
                            </li>

                            <li className="info-list-item">
                                <b>Structural Vulnerability:</b> The vulnerability of buildings, bridges, and other
                                structures to earthquakes depends on factors such as construction materials, design
                                standards, and adherence to seismic building codes, highlighting the importance of
                                resilient infrastructure.
                            </li>

                            <li className="info-list-item">
                                <b>Secondary Hazards:</b> Earthquakes can trigger secondary hazards, including
                                landslides, tsunamis, liquefaction, and aftershocks, amplifying the overall impact and
                                complicating emergency response and recovery efforts.
                            </li>

                            <li className="info-list-item">
                                <b>Emergency Preparedness:</b> Communities in earthquake-prone regions must prioritize
                                emergency preparedness, including public education, early warning systems, evacuation
                                plans, and the establishment of emergency shelters to mitigate the potential impact of
                                seismic events.
                            </li>

                            <li className="info-list-item">
                                <b>Community Resilience:</b> Building community resilience involves not only
                                infrastructure preparedness but also social cohesion, effective communication, and the
                                ability of individuals and communities to recover and adapt in the aftermath of an
                                earthquake.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="wild-fires">
                    <h2 className="info-section-h2 right-h2">
                        Wild Fires <GiCampfire className="info-icon" />
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Fire Propagation Risks:</b> High-risk areas for wildfires are susceptible to rapid
                                fire spread, threatening homes, communities, and natural habitats. Residents face the
                                risk of property loss, evacuation challenges, and exposure to health hazards from smoke
                                and air pollution.
                            </li>

                            <li className="info-list-item">
                                <b>Economic Impact:</b> Living in wildfire-prone zones can lead to significant economic
                                losses due to property damage, destruction of agricultural land, and disruption of local
                                businesses. Implementing preventive measures, such as creating defensible spaces and
                                adhering to fire-safe construction practices, is crucial for economic resilience.
                            </li>

                            <li className="info-list-item">
                                <b>Ecosystem Destruction:</b> High wildfire likelihood areas are at risk of ecological
                                devastation, including habitat loss, soil erosion, and adverse effects on biodiversity.
                                Implementing sustainable land management practices, vegetation clearing, and ecosystem
                                restoration are essential for preserving natural environments.
                            </li>

                            <li className="info-list-item">
                                <b>Health Hazards:</b> Wildfires pose health risks to residents through exposure to
                                smoke, particulate matter, and other air pollutants. Living in high-risk wildfire areas
                                increases the likelihood of respiratory issues and other health concerns, necessitating
                                the adoption of protective measures and evacuation plans.
                            </li>

                            <li className="info-list-item">
                                <b>Community Evacuation Challenges:</b> High wildfire risk areas may face difficulties
                                in coordinating timely and efficient community evacuations during emergencies. Adequate
                                planning, early warning systems, and community engagement are critical for reducing the
                                risks associated with evacuation challenges.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Living in areas with a high likelihood of wildfires entails significant risks, including the
                            rapid propagation of fires, economic losses, and threats to ecosystems. Residents face the
                            peril of property damage, evacuation complexities, and exposure to health hazards from
                            smoke. The economic impact extends to property destruction, damage to agricultural land, and
                            disruptions to local businesses. Ecosystems in these zones are at risk of devastation,
                            including habitat loss and biodiversity decline. Health risks arise from exposure to air
                            pollutants during wildfires, contributing to respiratory issues. Efficient community
                            evacuation is challenging, emphasizing the need for robust planning, early warning systems,
                            and community engagement to mitigate these multifaceted risks.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="lightning">
                    <h2 className="info-section-h2 left-h2">
                        Lightning <GiLightningStorm className="info-icon" />
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living in areas with frequent lightning activity entails various risks, including the
                            ignition of wildfires that threaten homes and ecosystems. Lightning strikes can cause
                            structural and electrical system damage, leading to property loss and disruptions to
                            essential services. Individuals face an increased risk of personal injury, emphasizing the
                            importance of staying informed and seeking shelter during storms. Lightning also poses a
                            threat to communication infrastructure, potentially disrupting vital services during
                            emergencies. Residents in high lightning risk areas should adopt safety measures, invest in
                            resilient infrastructure, and follow guidelines to mitigate the diverse risks associated
                            with frequent lightning activity.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Fire Ignition:</b> Living in areas with frequent lightning activity increases the
                                risk of wildfires as lightning strikes can ignite vegetation, leading to uncontrolled
                                fires that pose threats to homes, communities, and natural habitats.
                            </li>

                            <li className="info-list-item">
                                <b>Property Damage:</b> Lightning strikes can cause structural damage to buildings,
                                electrical systems, and other property. The risk of fires resulting from lightning also
                                increases the potential for widespread property loss.
                            </li>

                            <li className="info-list-item">
                                <b>Electrical System Risks:</b> Lightning poses risks to electrical systems, including
                                power outages, equipment damage, and the potential for electrical fires. Residents in
                                high lightning risk areas may experience disruptions to essential services.
                            </li>

                            <li className="info-list-item">
                                <b>Personal Safety:</b> Individuals in regions with frequent lightning activity face an
                                increased risk of personal injury. Lightning can cause injuries or fatalities,
                                emphasizing the importance of staying informed, seeking shelter during storms, and
                                following safety guidelines.
                            </li>

                            <li className="info-list-item">
                                <b>Communication Disruptions:</b> Lightning strikes can damage communication
                                infrastructure, disrupting phone and internet services. Reliable communication during
                                emergencies is crucial, highlighting the need for resilient communication systems in
                                lightning-prone areas.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="high-speed-wind">
                    <h2 className="info-section-h2 right-h2">
                        High Speed Wind <FiWind className="info-icon" />
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Structural Damage:</b> Living in areas prone to high-speed winds, such as hurricanes
                                or tornadoes, increases the risk of structural damage to homes and infrastructure.
                                Strong winds can lead to the collapse of buildings, roofs, and other structures.
                            </li>

                            <li className="info-list-item">
                                <b>Projectile Hazards:</b> High-speed winds can turn debris into projectiles, posing a
                                threat to both property and personal safety. Flying objects can cause significant damage
                                to homes, vehicles, and other structures.
                            </li>

                            <li className="info-list-item">
                                <b>Power Outages:</b> Severe winds can lead to power outages by causing damage to
                                electrical infrastructure, such as power lines and transformers. Residents may
                                experience disruptions to essential services, including electricity.
                            </li>

                            <li className="info-list-item">
                                <b>Storm Surge:</b> Coastal areas facing high-speed winds, especially during hurricanes,
                                are at risk of storm surges that can result in coastal flooding, causing extensive
                                property damage and posing dangers to residents.
                            </li>

                            <li className="info-list-item">
                                <b>Evacuation Challenges:</b> High-speed winds often necessitate evacuations, and living
                                in areas prone to such conditions can present challenges in coordinating timely and
                                effective evacuations, emphasizing the importance of evacuation planning and
                                preparedness.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Living in areas prone to high-speed winds, such as hurricanes or tornadoes, poses various
                            risks, including structural damage to homes and infrastructure. Strong winds can turn debris
                            into projectiles, threatening both property and personal safety. Power outages are common,
                            disrupting essential services like electricity. Coastal regions facing high-speed winds,
                            particularly during hurricanes, are vulnerable to storm surges that cause coastal flooding
                            and extensive property damage. Evacuations may be necessary, and residents in such areas
                            face challenges in coordinating timely and effective evacuations. To mitigate these risks,
                            it is crucial for residents to adopt preparedness measures, invest in resilient
                            infrastructure, and follow safety guidelines during high-speed wind events.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="hurricanes">
                    <h2 className="info-section-h2 left-h2">
                        Hurricanes <BsHurricane className="info-icon" />
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living in hurricane-prone coastal areas entails various risks, including storm surges
                            causing coastal flooding and property damage. High wind speeds associated with hurricanes
                            can lead to structural damage and pose risks to personal safety. Heavy rainfall and flooding
                            extend beyond coastal areas, affecting inland communities with property damage and service
                            disruptions. Hurricanes may necessitate evacuations, presenting challenges in coordinating
                            timely and efficient evacuations. Long-term disruptions, including extended power outages
                            and limited access to resources, are common after hurricanes, emphasizing the need for
                            preparedness and resilience in communities facing the impacts of these powerful storms.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Storm Surge:</b> Living in hurricane-prone coastal areas increases the risk of storm
                                surges, causing coastal flooding that can lead to widespread property damage,
                                displacement, and threats to personal safety.
                            </li>

                            <li className="info-list-item">
                                <b>High Wind Speeds:</b> Hurricanes bring high wind speeds that can result in structural
                                damage, including the destruction of homes, buildings, and infrastructure. Residents
                                face risks of injury and property loss due to strong winds and flying debris.
                            </li>

                            <li className="info-list-item">
                                <b>Heavy Rainfall and Flooding:</b> Hurricanes often bring heavy rainfall, leading to
                                inland flooding. This poses additional risks of property damage, road closures, and the
                                disruption of essential services, affecting communities beyond coastal areas.
                            </li>

                            <li className="info-list-item">
                                <b>Evacuation Challenges:</b> Hurricanes may necessitate evacuations, and living in
                                hurricane-prone regions presents challenges in coordinating timely and efficient
                                evacuations, emphasizing the importance of preparedness, early warnings, and community
                                planning.
                            </li>

                            <li className="info-list-item">
                                <b>Long-Term Disruptions:</b> Hurricanes can result in long-term disruptions, including
                                extended power outages, limited access to resources, and challenges in rebuilding
                                infrastructure. Residents must be prepared for sustained challenges in the aftermath of
                                a hurricane.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="coastal-flooding">
                    <h2 className="info-section-h2 right-h2">
                        Coastal Flooding <MdOutlineFlood className="info-icon" />
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Storm Surges:</b> Coastal flooding is often triggered by storm surges, where strong
                                winds from storms or hurricanes push water onto the shore, causing rapid and extensive
                                flooding that poses severe risks to coastal communities and infrastructure.
                            </li>

                            <li className="info-list-item">
                                <b>Sea Level Rise:</b> Rising sea levels due to climate change contribute to an
                                increased risk of coastal flooding. Higher sea levels elevate the baseline for storm
                                surges, amplifying the impact of extreme weather events on coastal areas.
                            </li>

                            <li className="info-list-item">
                                <b>Property Damage:</b> Coastal flooding can result in substantial property damage,
                                affecting homes, businesses, and critical infrastructure. The infiltration of saltwater
                                can lead to long-term structural degradation and pose challenges for post-flood
                                recovery.
                            </li>

                            <li className="info-list-item">
                                <b>Displacement and Community Impact:</b> Coastal flooding can force residents to
                                evacuate and result in long-term displacement. Communities face the challenges of
                                rebuilding and adapting to new vulnerabilities, impacting the social and economic fabric
                                of the area.
                            </li>

                            <li className="info-list-item">
                                <b>Erosion and Habitat Loss:</b> Frequent coastal flooding contributes to erosion and
                                habitat loss, impacting coastal ecosystems and biodiversity. These environmental changes
                                further exacerbate the vulnerability of coastal communities to flooding events.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Coastal flooding, often triggered by storm surges, poses significant risks to communities
                            and infrastructure. Rising sea levels due to climate change amplify the impact of storm
                            surges, increasing the likelihood and severity of flooding events. Coastal flooding results
                            in substantial property damage, affecting homes, businesses, and critical infrastructure.
                            The infiltration of saltwater can lead to long-term structural degradation, complicating
                            post-flood recovery. Residents may face displacement, and communities must adapt to new
                            vulnerabilities, affecting the social and economic fabric of the area. Frequent coastal
                            flooding contributes to erosion and habitat loss, impacting coastal ecosystems and
                            biodiversity, further increasing the vulnerability of coastal communities to flooding
                            events.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div">
                <section className="info-section" id="cold-waves">
                    <h2 className="info-section-h2 left-h2">
                        Cold Waves <RiTempColdLine className="info-icon" />
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living in areas prone to cold waves presents various risks, including exposure to extreme
                            low temperatures leading to health hazards. Cold waves strain infrastructure, causing
                            disruptions to heating systems, water supply, and transportation networks. The surge in
                            energy demand for heating during cold waves can result in power outages and stress energy
                            grids. Prolonged cold waves have economic repercussions, affecting multiple industries and
                            contributing to disruptions in daily life and commerce. Vulnerable populations, such as the
                            elderly and those without adequate shelter, face heightened risks during cold waves,
                            emphasizing the importance of preparation, shelter availability, and community support to
                            protect these populations from the impacts of extreme cold temperatures.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Extreme Cold Temperatures:</b> Living in areas prone to cold waves exposes residents
                                to extreme low temperatures that can pose significant risks to health and safety,
                                leading to hypothermia, frostbite, and other cold-related illnesses.
                            </li>

                            <li className="info-list-item">
                                <b>Infrastructure Challenges:</b> Cold waves can strain infrastructure, particularly
                                heating systems, water supply, and transportation networks. Frozen pipes, disrupted
                                public services, and icy roads contribute to challenges for residents during prolonged
                                periods of extreme cold.
                            </li>

                            <li className="info-list-item">
                                <b>Energy Demand:</b> Cold waves often result in a surge in energy demand for heating
                                purposes, potentially leading to power outages and stressing energy grids. This can
                                further compound the challenges faced by communities dealing with extreme cold
                                temperatures.
                            </li>

                            <li className="info-list-item">
                                <b>Economic Impact:</b> Prolonged cold waves can have economic repercussions, affecting
                                industries such as agriculture, construction, and transportation. Disruptions to daily
                                life, work, and commerce contribute to economic challenges for affected regions.
                            </li>

                            <li className="info-list-item">
                                <b>Social Vulnerability:</b> Vulnerable populations, including the elderly and those
                                without adequate shelter, face heightened risks during cold waves. Adequate preparation,
                                shelter availability, and community support are crucial for protecting these
                                populations.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="droughts">
                    <h2 className="info-section-h2 right-h2">
                        Droughts <GiWaterDivinerStick className="info-icon" />
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Water Scarcity:</b> Droughts lead to water scarcity, impacting agriculture, water
                                supplies, and ecosystems. Reduced precipitation and depleted reservoirs contribute to
                                challenges in meeting the water needs of communities and sustaining natural
                                environments.
                            </li>

                            <li className="info-list-item">
                                <b>Agricultural Impact:</b> Prolonged droughts have severe consequences for agriculture,
                                causing crop failures, reduced yields, and economic losses for farmers. Livestock may
                                also face challenges accessing water and forage, exacerbating food security concerns.
                            </li>

                            <li className="info-list-item">
                                <b>Economic Consequences:</b> Droughts can result in economic downturns, particularly in
                                regions heavily dependent on agriculture. Losses in crop yields, decreased agricultural
                                output, and increased food prices can have cascading effects on local and national
                                economies.
                            </li>

                            <li className="info-list-item">
                                <b>Wildfires and Ecosystem Stress:</b> Dry conditions during droughts elevate the risk
                                of wildfires, leading to ecosystem stress, habitat destruction, and loss of
                                biodiversity. Drought-induced wildfires pose additional threats to communities and
                                natural environments.
                            </li>

                            <li className="info-list-item">
                                <b>Human Health and Migration:</b> Droughts can impact human health through reduced
                                access to clean water and food. Waterborne diseases may become more prevalent, and
                                drought-induced economic hardships can lead to migration as communities seek better
                                living conditions.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Droughts bring forth a range of challenges, including water scarcity affecting agriculture,
                            water supplies, and ecosystems. Agricultural impacts include crop failures, reduced yields,
                            and economic losses, intensifying food security concerns. Economic consequences extend to
                            downturns, especially in agriculture-dependent regions, with losses in crop yields affecting
                            local and national economies. Droughts elevate the risk of wildfires, causing ecosystem
                            stress, habitat destruction, and biodiversity loss. Dry conditions also impact human health
                            by reducing access to clean water and food, leading to increased prevalence of waterborne
                            diseases. Economic hardships during droughts may prompt migration as communities seek
                            improved living conditions, emphasizing the need for comprehensive strategies to mitigate
                            the multifaceted impacts of drought.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div">
                <section className="info-section" id="heat-wave">
                    <h2 className="info-section-h2 left-h2">
                        Heat Waves <GiThermometerHot className="info-icon" />
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living in areas prone to heat waves presents various risks, including exposure to extreme
                            high temperatures leading to heat-related illnesses. Prolonged heat waves strain
                            infrastructure, particularly power grids and cooling systems, with increased energy demand
                            contributing to potential power outages. Heat waves have environmental impacts, including
                            drought conditions, wildfires, and disruptions to ecosystems, affecting agriculture, water
                            resources, and biodiversity. Vulnerable populations, such as the elderly and those with
                            pre-existing health conditions, face heightened risks during heat waves, emphasizing the
                            importance of cooling centers, public awareness, and community support. Urban areas may
                            experience intensified heat due to the urban heat island effect, impacting public health and
                            energy consumption in urban environments during heat waves.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Extreme Heat:</b> Living in areas prone to heat waves exposes residents to extreme
                                high temperatures, leading to heat-related illnesses such as heatstroke and dehydration,
                                with vulnerable populations facing heightened health risks.
                            </li>

                            <li className="info-list-item">
                                <b>Infrastructure Strain:</b> Prolonged heat waves can strain infrastructure,
                                particularly power grids and cooling systems. Increased energy demand for air
                                conditioning may lead to power outages, impacting residents' ability to cope with
                                extreme temperatures.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Impact:</b> Heat waves contribute to environmental stress, leading to
                                drought conditions, wildfires, and ecosystem disruptions. Increased temperatures can
                                have adverse effects on agriculture, water resources, and biodiversity.
                            </li>

                            <li className="info-list-item">
                                <b>Social Vulnerability:</b> Vulnerable populations, including the elderly, children,
                                and those with pre-existing health conditions, face heightened risks during heat waves.
                                Adequate cooling centers, public awareness, and community support are crucial for
                                protecting these populations.
                            </li>

                            <li className="info-list-item">
                                <b>Urban Heat Island Effect:</b> Urban areas may experience intensified heat during heat
                                waves due to the urban heat island effect. The concentration of heat-absorbing surfaces
                                exacerbates temperatures, impacting public health and energy consumption in urban
                                environments.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="ice-storms">
                    <h2 className="info-section-h2 right-h2">
                        Ice Storms <GiIceSkate className="info-icon" />
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Ice Accumulation:</b> Living in areas prone to ice storms involves the risk of
                                significant ice accumulation on surfaces, including roads, power lines, and trees. Ice
                                accumulation poses hazards to transportation, infrastructure, and overall community
                                mobility.
                            </li>

                            <li className="info-list-item">
                                <b>Power Outages:</b> Ice storms can lead to power outages as ice accumulation on power
                                lines and utility equipment causes disruptions. Residents may face extended periods
                                without electricity, impacting daily life and posing challenges during cold
                                temperatures.
                            </li>

                            <li className="info-list-item">
                                <b>Infrastructure Damage:</b> Ice accumulation on buildings and structures can result in
                                damage, including roof collapses and structural failures. Icy conditions also contribute
                                to accidents and injuries, emphasizing the need for infrastructure resilience and safety
                                measures.
                            </li>

                            <li className="info-list-item">
                                <b>Transportation Disruptions:</b> Ice storms create hazardous road conditions,
                                affecting transportation and leading to accidents. Icy surfaces on roads and runways
                                pose risks to drivers, pedestrians, and air travel, necessitating careful planning and
                                response strategies.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Impact:</b> Ice storms have environmental consequences, affecting
                                vegetation and wildlife. Ice accumulation on trees and plants can lead to damage and
                                habitat disruption, influencing local ecosystems.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Living in areas prone to ice storms presents various risks, including significant ice
                            accumulation on surfaces, causing hazards to transportation and infrastructure. Ice storms
                            can lead to power outages as ice accumulation disrupts power lines and utility equipment.
                            Infrastructure, including buildings and structures, may suffer damage from ice accumulation,
                            contributing to accidents and injuries. Hazardous road conditions disrupt transportation and
                            pose risks to drivers and pedestrians, requiring careful planning and response strategies.
                            Ice storms also have environmental impacts, affecting vegetation and wildlife by causing
                            damage and habitat disruption in local ecosystems. Preparedness, infrastructure resilience,
                            and safety measures are crucial for communities facing the multifaceted challenges posed by
                            ice storms.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div">
                <section className="info-section" id="tornados">
                    <h2 className="info-section-h2 left-h2">
                        Tornados <RiTornadoLine className="info-icon" />
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living in tornado-prone areas exposes residents to the risks associated with violent wind
                            speeds, causing extensive damage to structures and posing threats to personal safety.
                            Tornadoes can result in severe structural damage, necessitating extensive recovery and
                            rebuilding efforts. Hazardous debris generated by tornadoes poses additional risks to
                            property and personal safety, complicating emergency response. The intensity of tornadoes
                            varies, emphasizing the importance of understanding tornado intensity and implementing early
                            warning systems. Comprehensive emergency preparedness, including designated shelters,
                            evacuation plans, and staying informed through reliable weather alerts, is crucial for
                            residents to mitigate the impact of tornado events and protect their communities.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Violent Wind Speeds:</b> Living in tornado-prone areas exposes residents to the risk
                                of violent wind speeds associated with tornadoes. These winds can cause extensive damage
                                to structures, leading to property loss and posing significant threats to personal
                                safety.
                            </li>

                            <li className="info-list-item">
                                <b>Structural Damage:</b> Tornadoes can result in severe structural damage, including
                                the destruction of homes, buildings, and infrastructure. The aftermath often requires
                                extensive recovery efforts and rebuilding to restore affected communities.
                            </li>

                            <li className="info-list-item">
                                <b>Debris Hazards:</b> Tornadoes generate debris that becomes hazardous projectiles,
                                posing risks to both property and personal safety. Flying debris can cause additional
                                damage, injuries, and complicate emergency response efforts.
                            </li>

                            <li className="info-list-item">
                                <b>Storm Intensity:</b> Tornadoes vary in intensity, with stronger tornadoes causing
                                more significant damage. Understanding tornado intensity and implementing early warning
                                systems are crucial for residents to take timely protective actions.
                            </li>

                            <li className="info-list-item">
                                <b>Emergency Preparedness:</b> Living in tornado-prone areas necessitates comprehensive
                                emergency preparedness, including having designated shelters, evacuation plans, and
                                staying informed through reliable weather alerts to mitigate the impact of tornado
                                events.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="tsunamis">
                    <h2 className="info-section-h2 right-h2">
                        Tsunamis <MdOutlineTsunami className="info-icon" />
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Seismic Activity:</b> Living in coastal areas or regions near tectonic plate
                                boundaries increases the risk of tsunamis triggered by seismic activity, such as
                                earthquakes and underwater volcanic eruptions.
                            </li>

                            <li className="info-list-item">
                                <b>Coastal Inundation:</b> Tsunamis can result in rapid and massive coastal inundation,
                                causing widespread flooding and posing immediate threats to residents, structures, and
                                infrastructure near the coastline.
                            </li>

                            <li className="info-list-item">
                                <b>Far-Reaching Impact:</b> Unlike localized waves, tsunamis have far-reaching impacts
                                that extend across ocean basins. Coastal areas even distant from the earthquake's
                                epicenter may experience tsunami waves, emphasizing the need for international
                                cooperation in monitoring and warning systems.
                            </li>

                            <li className="info-list-item">
                                <b>Evacuation Challenges:</b> Timely and effective evacuation is challenging during
                                tsunami events due to the rapid onset of waves. Coastal communities must have
                                well-established evacuation plans, early warning systems, and public education to
                                enhance preparedness.
                            </li>

                            <li className="info-list-item">
                                <b>Marine and Environmental Impact:</b> Tsunamis have significant marine and
                                environmental impacts, causing damage to coastal ecosystems, marine life, and
                                infrastructure. The aftermath requires environmental monitoring and restoration efforts.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Living in coastal areas or regions near tectonic plate boundaries increases the risk of
                            tsunamis triggered by seismic activity. Tsunamis can cause rapid and massive coastal
                            inundation, posing immediate threats to residents, structures, and infrastructure near the
                            coastline. Unlike localized waves, tsunamis have far-reaching impacts across ocean basins,
                            requiring international cooperation in monitoring and warning systems. Effective evacuation
                            during tsunamis is challenging, necessitating well-established evacuation plans, early
                            warning systems, and public education. Tsunamis also have significant marine and
                            environmental impacts, causing damage to coastal ecosystems, marine life, and
                            infrastructure, highlighting the need for environmental monitoring and restoration efforts
                            in the aftermath.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div">
                <section className="info-section" id="volcanic-activity">
                    <h2 className="info-section-h2 left-h2">
                        Volcanic Activity
                        <GiSmokingVolcano className="info-icon" />
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living in volcanic regions entails various risks associated with volcanic activity. Lava
                            flows during volcanic eruptions can cause destruction to homes, infrastructure, and
                            agricultural land. Ashfall, resulting from volcanic ash clouds, poses risks to respiratory
                            health, damages crops, and disrupts transportation. Pyroclastic flows, fast-moving clouds of
                            hot gas and volcanic rock, can cause devastation down the slopes of a volcano. Volcanic
                            gases released during eruptions, including sulfur dioxide and hydrogen fluoride, pose health
                            risks and environmental damage. Volcanic activity can trigger secondary hazards such as
                            mudflows, landslides, and tsunamis, further increasing the complexity and impact of volcanic
                            events on communities.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Lava Flows:</b> Living in volcanic regions poses the risk of lava flows during
                                volcanic eruptions. Lava can move slowly or rapidly, causing destruction to homes,
                                infrastructure, and agricultural land in its path.
                            </li>

                            <li className="info-list-item">
                                <b>Ashfall:</b> Volcanic eruptions release ash clouds that can travel great distances,
                                leading to ashfall in surrounding areas. Ashfall poses risks to respiratory health,
                                damages crops, and can disrupt transportation and infrastructure.
                            </li>

                            <li className="info-list-item">
                                <b>Pyroclastic Flows:</b> Highly dangerous, pyroclastic flows are fast-moving clouds of
                                hot gas, ash, and volcanic rock. These flows can travel down the slopes of a volcano,
                                causing devastation to everything in their path.
                            </li>

                            <li className="info-list-item">
                                <b>Volcanic Gases:</b> Eruptions release volcanic gases, including sulfur dioxide and
                                hydrogen fluoride, which can pose health risks to humans and animals. Prolonged exposure
                                may lead to respiratory problems and environmental damage.
                            </li>

                            <li className="info-list-item">
                                <b>Secondary Hazards:</b> Volcanic activity can trigger secondary hazards such as lahars
                                (mudflows), landslides, and tsunamis, further increasing the complexity and impact of
                                volcanic events on communities.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="winter-weather">
                    <h2 className="info-section-h2 right-h2">
                        Winter Weather <GiWinterGloves className="info-icon" />
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Extreme Cold Temperatures:</b> Winter weather brings extreme cold temperatures that
                                pose risks to health and safety, increasing the likelihood of hypothermia, frostbite,
                                and other cold-related illnesses for residents in affected areas.
                            </li>

                            <li className="info-list-item">
                                <b>Snow Accumulation:</b> Heavy snowfall can lead to the accumulation of snow on roads,
                                walkways, and roofs, causing transportation disruptions, accidents, and potential
                                structural damage to buildings and infrastructure.
                            </li>

                            <li className="info-list-item">
                                <b>Ice Formation:</b> Winter weather includes the formation of ice on surfaces, such as
                                roads and sidewalks, resulting in hazardous conditions for pedestrians and drivers. Icy
                                surfaces contribute to an increased risk of accidents and injuries.
                            </li>

                            <li className="info-list-item">
                                <b>Power Outages:</b> Snow and ice accumulation on power lines and utility equipment can
                                lead to power outages, disrupting electricity supply and posing challenges for
                                residents, particularly during cold temperatures.
                            </li>

                            <li className="info-list-item">
                                <b>Winter Storms:</b> Winter weather often involves the occurrence of winter storms,
                                combining elements like snow, ice, and strong winds. These storms can result in a range
                                of hazards, including reduced visibility, property damage, and transportation
                                challenges.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Winter weather presents various risks to communities. Extreme cold temperatures increase the
                            risk of cold-related illnesses, and heavy snowfall can lead to transportation disruptions
                            and structural damage. Ice formation on surfaces poses hazards for pedestrians and drivers,
                            contributing to an increased risk of accidents and injuries. Power outages may occur due to
                            snow and ice accumulation on power lines, impacting electricity supply during cold
                            temperatures. Winter storms, combining snow, ice, and strong winds, bring additional hazards
                            such as reduced visibility, property damage, and transportation challenges. Preparedness,
                            timely response, and community cooperation are essential for mitigating the impact of winter
                            weather on residents and infrastructure.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Noise;
