import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Hospitals.css";
import jsonData from "./HospitalCountByCity.json";
import traumaCounts from "./Level1TraumaCenters.json";

import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function Hospitals() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Hospitals Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Hospitals within 5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Hospitals Near You and Learn the Benefits</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> Hospitals within a 5-mile radius</strong> of your property by leveraging the
                    power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into hospital locations but also into 40 other critical
                    environmental factors impacting your home and well-being. From Brownfield sites to public drinking
                    water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 100 Cities by Hospital Count</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Cities with Level I Trauma Centers</h2>
                    <div className="info-block">
                        <SEOTable header="" data={traumaCounts} />
                    </div>
                </section>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Benefits of Living Near a Hospital</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a hospital provides numerous benefits that extend beyond access to medical care.
                            Here are several advantages of residing in close proximity to a hospital:
                            <br />
                            <br />
                            <b>Immediate Medical Assistance:</b> Living near a hospital ensures quick access to medical
                            care in the event of emergencies. Whether it's a sudden illness, injury, or medical crisis,
                            proximity to a hospital means faster response times from emergency medical services (EMS)
                            and timely treatment by healthcare professionals. This can be life-saving in critical
                            situations.
                            <br />
                            <br />
                            <b>Specialized Healthcare Services:</b> Hospitals offer a wide range of specialized medical
                            services and facilities, including emergency departments, intensive care units, surgical
                            suites, diagnostic imaging centers, and specialty clinics. Living nearby provides convenient
                            access to these services, whether for routine medical appointments or specialized treatments
                            for complex conditions.
                            <br />
                            <br />
                            <b>Maternity Care:</b> For expectant mothers, living near a hospital with maternity services
                            ensures access to obstetric care during pregnancy, labor, and delivery. Quick access to
                            maternity care facilities can be crucial in emergencies and can provide reassurance to
                            pregnant women and their families.
                            <br />
                            <br />
                            <b>Chronic Disease Management:</b> Individuals with chronic health conditions benefit from
                            living near a hospital where they can receive ongoing medical management, medication
                            refills, and specialist consultations. Proximity to healthcare providers facilitates regular
                            follow-up appointments and improves continuity of care for chronic diseases such as
                            diabetes, hypertension, and asthma.
                            <br />
                            <br />
                            <b>Community Health Programs:</b> Hospitals often engage in community health initiatives
                            aimed at promoting wellness, disease prevention, and health education. Living near a
                            hospital may grant access to programs such as free health screenings, wellness workshops,
                            support groups, and preventive care services. These initiatives contribute to improved
                            public health outcomes and community well-being.
                            <br />
                            <br />
                            <b>Employment Opportunities:</b> Hospitals are major employers in many communities, offering
                            job opportunities across various healthcare professions, including physicians, nurses,
                            allied health professionals, administrative staff, and support personnel. Living nearby may
                            provide convenient access to employment opportunities within the healthcare sector.
                            <br />
                            <br />
                            <b>Increased Property Value:</b> Proximity to a hospital can positively impact property
                            values. Potential homebuyers often value access to healthcare services when selecting a
                            place to live, which can translate to higher demand and increased property values in
                            neighborhoods near hospitals.
                            <br />
                            <br />
                            <b>Research and Innovation:</b> Hospitals are centers for medical research, innovation, and
                            academic collaboration. Living near a hospital affiliated with a medical school or research
                            institution may provide opportunities for participation in clinical trials, access to
                            cutting-edge treatments, and collaboration with leading healthcare professionals.
                            <br />
                            <br />
                            In summary, living near a hospital offers numerous benefits, including immediate access to
                            medical care, specialized healthcare services, maternity care, chronic disease management,
                            community health programs, employment opportunities, increased property value, and access to
                            research and innovation. These advantages contribute to healthier, more resilient
                            communities where residents can access high-quality healthcare services and support.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Hospitals;
