import React, { useState, useEffect } from "react";
import "./SEOTable.css";

const SEOTable = (props) => {
    const [sites, setSites] = useState([]);
    const [headers, setHeaders] = useState([]);

    function isURL(str) {
        // Regular expression to check if the string is a URL
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(str);
    }

    useEffect(() => {
        // Set the sites data when the component mounts
        setSites(props.data);
        // Extract keys from the first object to use as table headers
        if (props.data.length > 0) {
            setHeaders(Object.keys(props.data[0]));
        }
    }, []);

    return (
        <div className="table-container">
            <h2>{props.header}</h2>
            <div className="table-wrapper">
                <table className="responsive-table">
                    <thead>
                        <tr className="data-table-header-row">
                            {headers
                                .filter((header) => header !== "URL")
                                .map((header, index) => (
                                    <th className="data-table-header" key={index}>
                                        {header}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sites.map((site, index) => (
                            <tr className="data-row" key={index}>
                                {headers.map((header, index) => {
                                    if (header === "Site Name") {
                                        return (
                                            <td className="data-cell" key={index}>
                                                <a className="data-link" href={site["URL"]} target="_blank">
                                                    {site[header]}
                                                </a>
                                            </td>
                                        );
                                    } else if (header !== "URL") {
                                        return (
                                            <td className="data-cell" key={index}>
                                                {site[header]}
                                            </td>
                                        );
                                    }
                                    return null; // Skip rendering the "URL" column
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SEOTable;
