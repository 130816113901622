import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Mapbox from "../../components/Mapbox/Mapbox";
import ReportSummaryTitle from "../../components/ReportSummaryTitle/ReportSummaryTitle";
import Loading from "../../components/Loading/Loading";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";

import "./Map.css";

function Map(props) {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [collapsed, setCollapsed] = useState(false);

    const mapRef = useRef(null);

    // Access props from location.state
    const data = location.state;

    useEffect(() => {
        setIsLoading(false); // Set loading to false once component is mounted
    }, []);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    const updateColors = (mode) => {
        const legendItems = document.querySelectorAll(".legend-icon");
        const currentMode = mode === "dark" ? "dark" : "light";

        const colorMap = 
            {
                "epa-superfund-sites": ["#FF5733", "#5ae0e0"],
                "epa-brownfield-sites": ["#F2D635", "#416d3e"],
                "epa-toxics-release-inventory-sites": ["#FF0066", "#6699FF"],
                "nuclear-power-plants": ["#FF9933", "#66CCCC"],
                "asbestos-mines-and-occurances": ["#FF3366", "#99CCFF"],
                "wastewater-treatment-plants": ["#33CCFF", "#FF9933"],
                "former-defense-sites": ["#FF6633", "#3399CC"],
                "cell-towers": ["#FF3399", "#66FF99"],
                "transmission-lines": ["#F33996", "#FF99CC"],
                "airports": ["#FF6600", "#33CCCC"],
                "train-lines": ["#40E0D0", "#40E0D0"],
                "incinerators": ["#FF0066", "#66CCFF"],
                "power-plants": ["#FF3300", "#42b39c"],
                "refineries": ["#FF9933", "#66CCCC"],
                "natural-gas-wells": ["#FF3366", "#99CCFF"],
                "oil-wells": ["#FF6633", "#3399CC"],
                "pulp-and-paper-mills": ["#9ACD32", "#FF6600"],
                "landfills": ["#7FFF00", "#66CC66"],
                "hospitals": ["#FF0000", "#FF6699"],
                "police-stations": ["#00FF00", "#FF3300"],
                "emergency-medical-service-stations": ["#ADFF2F", "#6699CC"],
                "fire-stations": ["#37a36a", "#FF6600"]
        };

        legendItems.forEach((item) => {
            const legendIconClasses = item.className.split(" ");
            const secondClass = legendIconClasses[1]; // Extract the second class
            if (secondClass) {
                const legendIcons = document.querySelectorAll(`.${secondClass}`); // Select all elements by the second class

                legendIcons.forEach((legendIcon) => {
                    const computedStyles = document.defaultView.getComputedStyle(legendIcon);
                    const colorStyle = computedStyles.backgroundColor || "";
                    if (colorStyle === "rgba(0, 0, 0, 0)" || colorStyle === "transparent" || colorStyle === "") {
                        // If background color is not set, update border-bottom
                        legendIcon.style.borderBottom = `13px solid ${
                            colorMap[secondClass][currentMode === "dark" ? 0 : 1]
                        }`;
                        // Reset background color
                        legendIcon.style.backgroundColor = "";
                    } else {
                        // If background color is set, update the background color
                        legendIcon.style.backgroundColor = colorMap[secondClass][currentMode === "dark" ? 0 : 1];
                        // Reset border-bottom
                        legendIcon.style.borderBottom = "";
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (!isLoading) {
            // Initialize colors based on default mode (light mode)
            updateColors("light");

            const toggleSwitch = document.getElementById("styleToggle");
            if (toggleSwitch) {
                toggleSwitch.addEventListener("change", (event) => {
                    const mode = event.target.checked ? "dark" : "light";
                    updateColors(mode);
                });

                // Cleanup event listener
                return () => {
                    toggleSwitch.removeEventListener("change", () => {});
                };
            }
        }
    }, [isLoading]);

    return isLoading ? (
        <Loading title="Map"/>
    ) : (
        <div className="map-page">
               <Helmet>
                <title>Map | EnviroHomePro</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <Mapbox
                data={data.data}
                lat={data.lat}
                long={data.long}
                addressLine1={data.addressLine1}
                addressLine2={data.addressLine2}
                city={data.city}
                state={data.state}
                postalCode={data.postalCode}
            />
            <div className="setting-menu">
                <p className="current-color-mode">Light</p>

                <label className="toggle">
                    <input id="styleToggle" className="toggle-checkbox" type="checkbox" />
                    <div className="toggle-switch"></div>
                </label>
            </div>
            <div id="state-legend" className={`legend ${collapsed ? "collapsed" : ""}`}>
                <a className={`toggle-sidebar ${collapsed ? "left-align" : ""}`} onClick={toggleSidebar}>
                    {collapsed ? <MdOutlineArrowBackIos /> : <MdOutlineArrowForwardIos />}
                </a>
                {/* <h4 className="legend-header">Legend</h4> */}

                <div className="legend-item">
                    <p className="legend-icon epa-superfund-sites"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>EPA Superfund Sites</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon epa-brownfield-sites"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>EPA Brownfield Sites</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon epa-toxics-release-inventory-sites"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>EPA Toxics Release Inventory</p>
                </div>

                <div className="legend-item">
                    <p className="legend-icon nuclear-power-plants"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Nuclear Power Plants</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon asbestos-mines-and-occurances"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Asbestos Mines and Occurances</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon wastewater-treatment-plants"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Wastewater Treatment Plants</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon former-defense-sites"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Former Defense Sites</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon cell-towers"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Cell Towers</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon transmission-lines"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Transmission Lines (EMF)</p>
                </div>

                <div className="legend-item">
                    <p className="legend-icon airports"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Airports</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon train-lines"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Train Lines</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon incinerators"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Incinerators</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon power-plants"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Power Plants</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon refineries"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Refineries</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon natural-gas-wells"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Natural Gas Wells</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon oil-wells"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Oil Wells</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon pulp-and-paper-mills"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Pulp and Paper Mills</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon landfills"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Landfills</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon hospitals"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Hospitals</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon police-stations"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Police Stations</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon emergency-medical-service-stations"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Emergency Medical Service Stations</p>
                </div>
                <div className="legend-item">
                    <p className="legend-icon fire-stations"></p>
                    <p className={`legend-text ${collapsed ? "hidden" : ""}`}>Fire Stations</p>
                </div>
            </div>
        </div>
        //  </div>
        //  </div>
    );
}

export default Map;
