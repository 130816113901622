import { jsPDF } from "jspdf";
import React, { useState, useMemo } from "react";
import ReactDOM from "react-dom";
import JosefinSansBold from "./JosefinSans-Bold-normal.js";
import * as OpenSans from "./OpenSans-Light-normal.js";

import { useSearchParams, useParams, useNavigate, useLocation } from "react-router-dom";
import { apiCall } from "../../service/UserServices.js";
import { getScoreColor } from "../../helpers/helpers.js";
import { getScoreValue } from "../../helpers/helpers.js";
import { FaCheckCircle } from "react-icons/fa";
import { reportDescriptions } from "../../consts/ReportDescriptions.js";

function createText(doc, shiftHeight, fontSize, lineHeightRatio, text, y, alignment, optionalX) {
    doc.setFontSize(fontSize);

    var textWidth = doc.getStringUnitWidth(text) * fontSize * doc.internal.scaleFactor;

    if (alignment === "center") {
        var x = doc.internal.pageSize.width / 2;
        doc.text(text, x, y, {
            align: alignment,
            maxWidth: doc.internal.pageSize.width,
        });
    } else if (alignment === "left") {
        var x = optionalX == undefined ? 15 : optionalX;
        // Adjust the left alignment position as needed
        doc.text(text, x, y, { maxWidth: doc.internal.pageSize.width - x - 15 });
    } else if (alignment === "justify") {
        var x = optionalX == undefined ? 15 : optionalX;
        doc.text(text, x, y, { align: "justify", maxWidth: doc.internal.pageSize.width - x - 15, lineHeight: 2 });
    } else if (alignment === "right") {
        x = doc.internal.pageSize.width - textWidth - (optionalX || 15);
        doc.text(text, x, y, { align: "right" });
    }
    if (shiftHeight) {
        let sectionHeight = fontSize * lineHeightRatio;
        y += sectionHeight;
    }
    return y;
}

async function JSPDFReport(reportData, overallScore, totalPossibleScores, lat, long, addressLine1, addressLine2, city, state, postalCode) {
    // Create a new jsPDF instance
    var doc = new jsPDF();
    // Define the page dimensions
    var pageWidth = doc.internal.pageSize.width;
    var pageHeight = doc.internal.pageSize.height;
    let fillHeight = pageHeight / 2;
    doc.setFont('OpenSans-Light', 'normal');

    let y = 30;
    doc.setTextColor(0, 0, 0);
    y = createText(doc, true, 25, 0.5, "ENVIRONMENTAL SITE REPORT", y, "center");
    y = createText(doc, true, 18, 0.5, addressLine1 + addressLine2, y, "center");
    y = createText(doc, true, 15, 0.6, city + ", " + state + ", " + postalCode, y, "center");

    let summaryObj = reportData.Summary[0].data;
    let rgbaValues = null;

    rgbaValues = summaryObj.scoreColor.match(/[\d.]+/g);
    let overallRed = null;
    let overallGreen = null;
    let overallBlue = null;
    let overallAlpha = null;

    if (rgbaValues && rgbaValues.length === 4) {
        overallRed = parseInt(rgbaValues[0]);
        overallGreen = parseInt(rgbaValues[1]);
        overallBlue = parseInt(rgbaValues[2]);
        overallAlpha = parseFloat(rgbaValues[3]);               
    }
   
    y = createText(doc, true, 15, 0.6, "Overall Rating", y + 3, "center");

    doc.setFillColor(overallRed, overallGreen, overallBlue);
    doc.circle(108, y + 5 , 7.5, "F"); 

    let hasPlusOrMinus = summaryObj.scoreValue.includes("-") || summaryObj.scoreValue.includes("+");
    y = createText(doc, true, 25, 1, summaryObj.scoreValue, y + 7.5, "left", hasPlusOrMinus ? 104 : 105);


    doc.setFillColor(5, 17, 42);

    doc.rect(0, y + 15, pageWidth, 55, "F")
    doc.setTextColor(255, 255, 255);
    y = createText(doc, true, 14, 1, "Prepared by:", y + 35, "center");
    doc.setTextColor(255, 255, 255);
    doc.setFont('JosefinSans-Bold', 'normal');

    y = createText(doc, false, 30, 1.2, "EnviroHome", y, "left", 68);
    doc.setTextColor("#09abdb");
    y = createText(doc, true, 30, 1.2, "Pro", y, "left", 129.5);

    doc.setTextColor(153, 153, 153);
    // y = createText(
    //     doc,
    //     true, 
    //     11,
    //     1.2,
    //     "The materials and information contained on or accessible from this website, including data regarding historical radon testing and related results (collectively, the “Radon Data”), are for general information purposes only. You may not rely upon such materials or information, including the Radon Data, for any purpose, including as a basis for making any business, legal, financial or other decision. In particular, the Radon Data is gathered from publicly available sources that were not produced by or on behalf of EnviroHome and may not be accurate or complete. While we attempt to keep the Radon Data up to date and correct based upon information publicly available, EnviroHome undertakes no responsibility or obligation to update such information in the future. Further, EnviroHome makes no representation or warranty of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website, including the Radon Data, for any purpose. You may not reproduce, replicate, distribute, sell, monetize or commercially use any of the information contained on or accessible from this website, including the Radon Data. By viewing, accessing, downloading or otherwise reviewing the materials and information contained on or accessible from this website, including the Radon Data, you affirmatively consent and agree to each of the foregoing items and disclaim any reliance on the Radon Data for any purpose and release EnviroHome and each of its affiliates and representatives from any claims arising out of, in connection with or related thereto.",
    //     pageHeight - 100,
    //     "justify"
    //         );
    doc.setTextColor(0, 0, 0);
    doc.setFont('OpenSans-Light', 'normal');

    //Loop through each category in reportData as a new page
    Object.entries(reportData).forEach((category) => {
        // console.dir(reportData)
        // console.dir(category)
        if(category[0] != "Summary"){
            // Add a new page
            doc.addPage();
            // console.dir()
            //Begin at top of new page
            y = 20;

            doc.setFont('OpenSans-Light', 'normal');
            y = createText(doc, true, 20, .5, addressLine1 + addressLine2, y, "center");
            y = createText(doc, true, 18, .6, city + ", " + state + ", " + postalCode, y, "center");        
            y = createText(doc, true, 15, 1.4, category[0]+ " Rating:", y, "center");

            // Filtering the array where type is "Summary"
            let objectType = category[0] == "Overall" ? "Summary" : category[0];
            let overallScoreObj = null;
            if(objectType == "Summary"){
                overallScoreObj = reportData.Summary
            } else {
                overallScoreObj = reportData.Overall.filter(item => item.data.type === objectType);
            }
            
            overallScoreObj = overallScoreObj[0].data;
            let value = overallScoreObj.score;
            let type = overallScoreObj.type;
            let weight = overallScoreObj.weight;
            let scoreColor = overallScoreObj.scoreColor;
            let scoreValue = overallScoreObj.scoreValue;

            rgbaValues = scoreColor.match(/[\d.]+/g);
            let red = null;
            let green = null;
            let blue = null;
            let alpha = null;

            if (rgbaValues && rgbaValues.length === 4) {
                red = parseInt(rgbaValues[0]);
                green = parseInt(rgbaValues[1]);
                blue = parseInt(rgbaValues[2]);
                alpha = parseFloat(rgbaValues[3]);
            }

            doc.setFillColor(red, green, blue);
            doc.circle(108, y - 10, 7.5, "F"); 

            let hasPlusOrMinus = scoreValue.includes("-") || scoreValue.includes("+");
            y = createText(doc, true, 25, 1, scoreValue, y - 7, "left", hasPlusOrMinus ? 104 : 105);


            doc.setFillColor(9, 171, 219);
            doc.rect(12, y - 11, pageWidth - 27, 10, "F");

            doc.setTextColor(255, 255, 255);

            y = createText(doc, false, 12, 0.6, "Category", y - 4.5, "left");
            y = createText(doc, false, 12, 0.6, "Weight", y, "left", 150);
            y = createText(doc, false, 12, 0.6, "Rating", y, "left", 173);

            y = 87;
            doc.setTextColor(0, 0, 0);

            // Loop through each score in the category
            category[1].forEach((topic, index) => {
    
                if (y > pageHeight - 50) {
                    doc.addPage();
                    y = 30;
                    y = createText(doc, true, 15, 1.5, category[0], y, "center");
                }
                topic = topic.data;
                var value = topic.score;
                var type = topic.type;
                let weight = topic.weight;
                let scoreColor = topic.scoreColor;
                let scoreValue = topic.scoreValue;

                rgbaValues = scoreColor.match(/[\d.]+/g);
                let red = null;
                let green = null;
                let blue = null;
                let alpha = null;

                if (rgbaValues && rgbaValues.length === 4) {
                    red = parseInt(rgbaValues[0]);
                    green = parseInt(rgbaValues[1]);
                    blue = parseInt(rgbaValues[2]);
                    alpha = parseFloat(rgbaValues[3]);
                }

                doc.setDrawColor("#FFFFF"); // Red color
                doc.setLineWidth(0.1);
                doc.line(12, y + 4, pageWidth - 15, y + 4);


                y = createText(doc, false, 12, 0.6, type, y, "left");
                y = createText(doc, false, 12, 0.6, weight, y, "left", 150);

                doc.setFillColor(red, green, blue);
                doc.circle(180, y - 2.25, 5, "F");

                // Add text in the circle
                y = createText(doc, true, 12, 1, scoreValue, y - 0.5, "left", 178.5);

                // doc.setFont("Symbola");
                doc.setFontSize(12);
                let description = "";

                let possibleDescriptions = reportDescriptions[type];

                // doc.setFont("Times");
                // doc.setTextColor(0, 0, 0); // Set text color to black
                // y = createText(doc, true, 12, 0.8, description, y, "left", 25);

                y += 1;
            });
        }
    });

    addFooter(doc);

    doc.save(addressLine1.replaceAll(" ", "-").toLowerCase() + "-property-report.pdf");
}

export default JSPDFReport;

function addFooter(doc) {
    // Set the font size and text alignment for the footer
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128); // Set text color to black

    // Add a footer to each page
    var totalPages = doc.internal.getNumberOfPages();
    for (var i = 1; i <= totalPages; i++) {
        doc.setPage(i);

        // Specify the position of the footer text
        var footerX = doc.internal.pageSize.getWidth() / 2;
        var footerY = doc.internal.pageSize.getHeight() - 10; // 10 is the margin from the bottom

        // Format the footer text
        var footerText = "Page " + i + " of " + totalPages;
        var poweredByText = "Powered by EnviroHomePro";

        // Format the footer text
        var footerText;
        if (i === 1) {
            footerText = "";
        } else {
            footerText = "Page " + (i - 1) + " of " + (totalPages - 1);
        }

        // Calculate the position of the "Powered by EnviroHome" text
        var poweredByX = footerX + doc.getStringUnitWidth(footerText) / 2 + 85; // Adjust the spacing as needed
        var poweredByY = footerY;

        // Add the footer text and "Powered by EnviroHome" text
        doc.text(footerText, footerX, footerY - 3, { align: "center" });
        doc.text(poweredByText, poweredByX, poweredByY, { align: "right" });
    }
}
