import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import "./Mapbox.css";

const Mapbox = (props) => {
    let style = null;
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const mapContainerRef = useRef(null);
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/dlavine0710/cltc1l9k100n201p3enqe9c8r",
            center: [props.long, props.lat],
            zoom: 12,
        });

        // Add a marker for the center
        new mapboxgl.Marker()
            .setLngLat([props.long, props.lat])
            .setPopup(
                new mapboxgl.Popup({ offset: 25 }) // add popups
                    .setHTML(
                        `<p class="main-popover-address">${props.addressLine1}</p>${
                            props.addressLine2 ? `<p class="main-popover-address">${props.addressLine2}</p>` : ""
                        }
            <p class="main-popover-address">${props.city}, ${props.state}, ${props.postalCode}</p>
            `
                    )
            )
            .addTo(map);

        //Add in markers - Points
        props.data.forEach((lineString, index) => {
            let lineStringData = JSON.parse(lineString.geojson);
            if (lineStringData.type === "Point") {
                // Loop through lineString.result and add <p> tags for each key-value pair
                // Create popup content string
                let popupContent = `<p class="popover-header">${lineString.type}</p>`;
                for (const key in lineString.result) {
                    if (key !== "Distance" && key !== "geojson" && key != "Additional Information") {
                        popupContent += `<span class="popover-result-item"><p class="popover-result-key">${key}:</p> <p class="popover-result-value">${lineString.result[key]}</p>`;
                    } else if (key == "Additional Information") {
                        popupContent += `<span class="popover-result-item"><p class="popover-result-key">${key}:<p>
                        <a href=${lineString.result[key]} target="_blank" rel="noopener noreferrer">
                        ${new URL(lineString.result[key]).hostname}
                        </a> </p>`;
                    }
                }
                popupContent += `<span class="popover-result-item"><p class="popover-result-key">Distance:</p> <p class="popover-result-value">${lineString.distance} miles</p>`;

                popupContent += "</span>";
                // Add markers for each feature in GeoJSON
                const el = document.createElement("div");
                el.className = lineString.class;
                new mapboxgl.Marker(el)
                    .setLngLat(lineStringData.coordinates)
                    .setPopup(
                        new mapboxgl.Popup({ offset: 25 }) // add popups
                            .setHTML(popupContent)
                    )
                    .addTo(map);
            }
        });

        // Function to remove and reattach event listener after style update
        function attachStyleDataListener() {
            map.off("styledata", styleDataHandler);
            map.once("styledata", styleDataHandler);
        }

        function styleDataHandler() {
            try {
                props.data.forEach((lineString, index) => {
                    let popupContent = null;
                    let lineStringData = JSON.parse(lineString.geojson);
                    if (lineStringData.type === "LineString") {
                        popupContent = `<p class="popover-header">${lineString.type}</p>`;

                        for (const key in lineString.result) {
                            if (key !== "Distance" && key !== "geojson") {
                                popupContent += `<span class="popover-result-item"><p class="popover-result-key">${key}:</p> <p class="popover-result-value">${lineString.result[key]}</p>`;
                            }
                        }
                        popupContent += `<span class="popover-result-item"><p class="popover-result-key">Distance:</p> <p class="popover-result-value">${lineString.distance} miles</p>`;

                        popupContent += "</span>";
                        let color = "";
                        let width = null;
                        if (lineString.class === "trains") {
                            color = "#40E0D0";
                            width = 5;
                        }
                        if (lineString.class === "high-power-electrical-transmission-lines-(emf)") {
                            color = "#F33996";
                            width = 5;
                        }
                        map.addSource(`route-${lineString.class}-${index}`, {
                            type: "geojson",
                            data: {
                                type: "Feature",
                                properties: { color: color, width: width },
                                geometry: {
                                    type: "LineString",
                                    coordinates: lineStringData.coordinates,
                                },
                            },
                        });
                        map.addLayer({
                            id: `route-${lineString.class}-${index}`,
                            className: "line",
                            type: "line",
                            source: `route-${lineString.class}-${index}`,
                            layout: {
                                "line-join": "round",
                                "line-cap": "round",
                            },
                            paint: {
                                "line-color": ["get", "color"],
                                "line-width": ["get", "width"],
                            },
                        });
                    }
                    // Add popover on click
                    map.on("click", `route-${lineString.class}-${index}`, function (e) {
                        // Get the coordinates of the clicked point
                        const coordinates = e.lngLat;

                        // Create a popover
                        new mapboxgl.Popup({ offset: 25 }).setLngLat(coordinates).setHTML(popupContent).addTo(map);
                    });
                });

                // Update the style and reattach event listener after style update
                setTimeout(() => {
                    attachStyleDataListener();
                }, 5000); // Example time delay, change as needed

                // console.log("Map loaded"); // Log when map is loaded
            } catch (ex) {
                console.log(ex);
            }
        }

        map.once("styledata", styleDataHandler);

        // Add navigation control
        map.addControl(new mapboxgl.NavigationControl(), "bottom-left");

        //Dark and Light mode toggle
        var styleToggle = document.getElementById("styleToggle");
        const paragraph = document.querySelector(".current-color-mode");

        styleToggle.addEventListener("change", function () {
            if (this.checked) {
                // Update the label text
                style = "mapbox://styles/dlavine0710/cltc3vwzj00uh01qkehidbk8z";
                paragraph.textContent = "Dark";
            } else {
                style = "mapbox://styles/dlavine0710/cltc1l9k100n201p3enqe9c8r";
                paragraph.textContent = "Light";
            }
            map.setStyle(style);
        });

        return () => map.remove();
    }, []);

    return <div className="mapbox-container" ref={mapContainerRef} />;
};

export default Mapbox;
