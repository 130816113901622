import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchPage.css";
import { Helmet } from "react-helmet";
import Loading from "../../components/Loading/Loading.js"
import GooglePlacesWidget from "../../components/GoogleAutocompleteWidget/AutocompleteWidget";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { apiCall } from "../../service/UserServices.js";
import { Auth } from "aws-amplify";

function SearchPage() {
    const navigate = useNavigate();
    const [userPlanVerified, setPlanVerified] = useState(false);
    const [pricingPlan, setPricingPlan] = useState("");

    React.useEffect(() => {
        const getUserData = async () => {
            let attributes = await Auth.currentUserInfo();
            let sub = attributes.attributes.sub;
            let response = await apiCall("/users/pricing-plan?", { sub });
            
            
            //Error Handling
            const statuses = [response.status];
            const messages = [response.response?.data ?? null];
            const index = statuses.findIndex(status => status !== 200);
            
            if (index !== -1) {
                navigate(`/error?message=${messages[index]}`);
            }

            let pricingPlanId = response.data.plan;
            if (pricingPlanId == 0) {
                navigate(`/pricing?sub=${attributes.attributes.sub}`);
            } else {
                setPlanVerified(true);
            }
        };

        getUserData().catch(console.error);
    }, []);

    return !userPlanVerified ? (
        <Loading title="Property Search"/>
    ) : (
        <div className="search-div">
              <Helmet>
                <title>Property Search | EnviroHomePro</title>
            </Helmet>
            <h1 className="search-header">Environmental Property Search</h1>
            <div className="search-widget">
                <GooglePlacesWidget />
            </div>
        </div>
    );
}

export default SearchPage;
