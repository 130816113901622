import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { useParams, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import "./MaterialTable.css";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { CiRead } from "react-icons/ci";
import PageviewSharpIcon from '@mui/icons-material/PageviewSharp';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, IconButton, Tooltip } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import JSPDFReport from "../../components/PDFReport/JSPDFReport.js";

const MaterialTable = (props) => {
    const globalTheme = useTheme();

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.primary, //swap in the secondary color as the primary for the table
                    info: {
                        main: "#05112a", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(255,255,255)" //random light yellow color for the background in light mode
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "1.1rem", //override to make tooltip font size larger
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );

    //should be memoized or stable
    const columns = useMemo(
        () => [
            {
                accessorKey: "AddressLine1", //access nested data with dot notation
                header: "Address",
                size: 250,
            },
            {
                accessorKey: "AddressLine2", //access nested data with dot notation
                header: "Unit/Apt",
            },
            {
                accessorKey: "City", //access nested data with dot notation
                header: "City",
            },
            {
                accessorKey: "State", //access nested data with dot notation
                header: "State",
            },
            {
                accessorKey: "PurchaseDate",
                header: "Purchase Date",
            },
        ],
        []
    );

    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
                enableEditing
                columns={columns}
                data={props.data}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        {/* <Tooltip
                            arrow
                            placement="right"
                            title="Download"
                            onClick={() =>
                                JSPDFReport(
                                    row.original.Lat,
                                    row.original.Long,
                                    row.original.AddressLine1,
                                    row.original.AddressLine2,
                                    row.original.City,
                                    row.original.State,
                                    row.original.PostalCode

                                )
                            }> */}
                            {/* <IconButton color="info"> */}
                                {/* <PDFDownloadLink
                                    document={<PDFReport />}
                                    fileName={
                                        row.original.AddressLine1.replaceAll(" ", "-").toLowerCase() +
                                        "-property-report.pdf"
                                    }>
                                    {({ blob, url, loading, error }) => <DownloadIcon className="downloadIcon" />}
                                </PDFDownloadLink> */}
                                {/* <DownloadIcon /> */}
                                {/* <DownloadIcon onClick={() => JSPDFReportSliders(row.original)} /> */}
                            {/* </IconButton> */}
                        {/* </Tooltip> */}
                        <Tooltip
                            arrow
                            placement="right"
                            title="View"
                            onClick={() => props.goToReport(row.original.ReportGUID)}>
                            <IconButton color="info">
                                <PageviewSharpIcon className="view-icon" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: "10px",
                    },
                }}
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        "&:hover": {
                            // cursor: "pointer",
                            "& .MuiTableCell-body": {
                                color: "#05112a",
                            },
                        },
                    },
                })}
                initialState={{ pagination: { pageSize: 5 } }}
            />
        </ThemeProvider>
    );
};

export default MaterialTable;
