import React, { useState } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { apiCall } from "../../service/UserServices.js";
import Loading from "../../components/Loading/Loading.js";

import "./PricingOptions.css";
const PricingOptions = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [subscriptionProduct, setSubscriptionProduct] = useState(null);
    const [perReportProduct, setPerReportProduct] = useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const sub = searchParams.get("sub");
    const navigate = useNavigate();
    let email = props.email;

    React.useEffect(() => {
        const getPricingData = async () => {
            const [products] = await Promise.all([apiCall("/settings/products?")]);

            setPerReportProduct(products.data.products.find((obj) => obj.Id === 1));
            setSubscriptionProduct(products.data.products.find((obj) => obj.Id === 2));
            setIsLoading(false);
        };
        getPricingData().catch(console.error);
    }, []);
    const setPayPerReportSetting = async (sub) => {
        const data = {
            sub: sub,
        };
        let response = await apiCall("/users/pricing-plan/single", data, "POST");
        response.status != 200
            ? navigate(`/error?message=${response.message}`)
            : navigate("/residential-environmental-search");
    };

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return isLoading ? (
        <Loading />
    ) : (
        <div className="pricing-cards">
            {/* <div className="pricing-grid"> */}
                <div className="pricing-card">
                    <div className="card text-center">
                        <div className="title">
                            {/* <i className="fa fa-paper-plane" aria-hidden="true"></i> */}
                            <h2>Pay As You Go</h2>
                        </div>
                        <div className="price">
                            <h4>
                                <sup>$</sup>
                                {perReportProduct.Price}
                            </h4>
                        </div>
                        <div className="option">
                            <ul>
                                <li>
                                    {" "}
                                    <i className="fa fa-check" aria-hidden="true"></i> No monthly requirement
                                </li>
                                <li>
                                    {" "}
                                    <i className="fa fa-check" aria-hidden="true"></i> No hidden fees
                                </li>
                                <li>
                                    {" "}
                                    <i className="fa fa-check" aria-hidden="true"></i> &nbsp;{" "}
                                </li>
                            </ul>
                        </div>
                        {props.page == "Pricing" ? (
                            <a onClick={(e) => setPayPerReportSetting(sub)}>Create Report</a>
                        ) : (
                            <a onClick={(e) => login(e)}>Create Account</a>
                        )}{" "}
                    </div>
                </div>
                <div className="pricing-card">
                    <div className="card text-center">
                        <div className="title">
                            <i className="fa fa-plane" aria-hidden="true"></i>
                            <h2>Standard</h2>
                        </div>
                        <div className="price">
                            <h4>
                                <sup>$</sup>
                                {subscriptionProduct.Price}
                            </h4>
                        </div>
                        <div className="option">
                            <ul>
                                <li>
                                    {" "}
                                    <i className="fa fa-check" aria-hidden="true"></i> Unlimited Searches{" "}
                                </li>
                                <li>
                                    {" "}
                                    <i className="fa fa-check" aria-hidden="true"></i> No hidden fees
                                </li>
                                <li>
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i> &nbsp;{" "}
                                </li>
                            </ul>
                        </div>
                        {props.page == "Pricing" ? (
                            <form
                                action={process.env.REACT_APP_BACKEND_URL + "/stripe/create-checkout-session"}
                                enctype="multipart/form-data"
                                method="POST">
                                <input type="hidden" name="lookup_key" value={subscriptionProduct.ProductId} />
                                <input type="hidden" name="sub" value={sub} />
                                <input type="hidden" name="customer_email" value={email} />
                                <button className="cardButton" type="submit">
                                    Purchase
                                </button>
                            </form>
                        ) : (
                            <a onClick={(e) => login(e)}>Create Account</a>
                        )}
                    </div>
                </div>
            {/* </div> */}
        </div>
    );
};

export default PricingOptions;
