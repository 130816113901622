import React, { useState, useMemo } from "react";
import "./ReportTable.css";
import { BsPlusCircleFill, BsDashCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { getScoreColor } from "../../helpers/helpers.js";
import { getScoreValue } from "../../helpers/helpers.js";
import { reportDescriptions } from "../../consts/ReportDescriptions";
import { OverlayTrigger, Button, Popover } from "react-bootstrap";

const weightPopover = (
    <Popover id="popover-positioned-right" title="Popover right">
        <h1 className="weight-header-popover">How is this weighted?</h1>
        <p className="weight-description-popover">
            With over 30+ years of environemntal engineering experience, we have placed importance on specific
            categories to reflect their impacts on your environmental health.
        </p>
    </Popover>
);

const Table = ({ rows, tableType, handleTabChange, totalPossibleScores }) => {
    return (
        <table className="report-table">
            <thead>
                <tr className="report-table-h">
                    <th></th>
                    <th className="report-table-th" id="report-table-data-category">
                        Category
                    </th>
                    <th className="report-table-th report-table-data-weight" id="report-table-data-weight">
                        <p className="weight-header">Weight</p>
                        <OverlayTrigger trigger="click" placement="left" rootClose overlay={weightPopover}>
                            <Button className="overlay-button-trigger">
                                <IoIosInformationCircleOutline className="overlay-button-icon" />
                            </Button>
                        </OverlayTrigger>
                    </th>
                    <th className="report-table-th report-table-data-score" id="report-table-data-score">
                        Rating
                    </th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <TableRow
                        tableType={tableType}
                        key={index}
                        data={row}
                        handleTabChange={handleTabChange}
                        totalPossibleScores={totalPossibleScores}
                    />
                ))}
            </tbody>
        </table>
    );
};

const TableRow = ({ tableType, data, handleTabChange, totalPossibleScores }) => {
    // console.dir(data)
    const [expanded, setExpanded] = useState(false);
    const handleToggle = () => {
        setExpanded(!expanded);
    };

    let hasPlusOrMinus = data.scoreValue.includes("-") || data.scoreValue.includes("+");

    let topicDescription = reportDescriptions.filter((item) => item.Topic === data.type);
    topicDescription = topicDescription[0];

    let displayResultsCount = data.results !== undefined ? data.results.length : 0;

    let descriptionText;
    if (data.results !== undefined && data.results.length > 0) {
        if (data.type == "Public Drinking Water Systems") {
            descriptionText = "Public Drinking Water Systems Serving Your Zip Code.";
        } else if (data.type == "Radon Gas Exposure") {
            descriptionText = "Radon Gas Exposure by ZIP Code";
        } else {
            switch (data.settings.Category) {
                case "Natural Hazards":
                    if (data.type !== "Earthquake Fault or Folds") {
                        descriptionText = "National Risk Index Data";
                    } else {
                        descriptionText = data.type + " within " + data.settings.Range + " miles.";
                    }
                    break;
                //Test
                // case "Noise Pollution":
                //     descriptionText = "Noise Data";
                //     break;
                default:
                    descriptionText = data.type + " within " + data.settings.Range + " miles.";
                    break;
            }
        }
    } else if (data.settings !== undefined && data.settings.Range === 1) {
        descriptionText = "No " + data.type + " were found within " + data.settings.Range + " mile of your location.";
    } else if (data.settings !== undefined && data.settings.Range !== 1) {
        descriptionText = "No " + data.type + " were found within " + data.settings.Range + " miles of your location.";
    }

    if (tableType == "Summary") {
        return (
            <>
                <tr className="report-table-row">
                    <td className="expand-icon">
                        <div data-value={data.value} onClick={handleTabChange}>
                            <BsArrowRightCircleFill />
                        </div>
                    </td>
                    <td className="report-table-data">{data.type}</td>
                    <td className="report-table-data report-table-data-weight">
                        {/* <p className="visual-numbers"> 
                            {data.settings != undefined
                                ? data.score +
                                  ": " +
                                  data.settings.MinScore +
                                  " min score | " +
                                  data.settings.MaxScore +
                                  " max score "
                                : data.score}
                        </p>  */}

                        {data.weight}
                    </td>
                    <td className="report-table-data report-table-data-score">
                        <div
                            className="score-circle"
                            style={{
                                backgroundColor: data.scoreColor,
                            }}>
                            <span className={hasPlusOrMinus ? "offset" : ""}>{data.scoreValue}</span>
                        </div>
                    </td>
                </tr>
            </>
        );
    } else {
        return (
            <>
                <tr className="report-table-row">
                    <td className="expand-icon" onClick={handleToggle}>
                        {expanded ? <BsDashCircleFill /> : <BsPlusCircleFill />}
                    </td>{" "}
                    {/* New column with plus or minus sign */}
                    <td className="report-table-data">{data.type}</td>
                    <td className="report-table-data report-table-data-weight">{data.weight}</td>
                    <td className="report-table-data report-table-data-score">
                        <div
                            className="score-circle"
                            style={{
                                backgroundColor: data.scoreColor,
                            }}>
                            <span className={hasPlusOrMinus ? "offset" : ""}>{data.scoreValue}</span>
                        </div>
                    </td>
                </tr>
                {expanded && (
                    <tr className="expanded-details-tr">
                        <td colSpan="4">
                            <div className="expanded-details">
                                <p className="expanded-details-description">{descriptionText}</p>
                                <p className="expanded-details-description-notice">
                                    {data.results.length > 1 ? data.results.length + " records" : null}
                                </p>
                                <div
                                    className={`expanded-details-grid ${
                                        data.results.length === 1
                                            ? "single-card-grid"
                                            : data.results.length === 0
                                            ? "no-card-grid"
                                            : ""
                                    }`}>
                                    {data.results.length > 0 ? (
                                        data.results.slice(0, displayResultsCount).map((result, index) => (
                                            <div key={index} className="expanded-details-record">
                                                {Object.keys(result).map(
                                                    (key) =>
                                                        key !== "geojson" && ( // Exclude keys with value "geojson"
                                                            <p className="expanded-details-text" key={key}>
                                                                <b className="expanded-details-text-key">{key}: </b>{" "}
                                                                {key === "Distance" ? (
                                                                    Math.round(result[key] * 100 + Number.EPSILON) /
                                                                        100 +
                                                                    " miles"
                                                                ) : ["Additional Information"].includes(key) ? (
                                                                    <a
                                                                        href={result[key]}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">
                                                                        {new URL(result[key]).hostname}
                                                                    </a>
                                                                ) : (
                                                                    result[key]
                                                                )}
                                                            </p>
                                                        )
                                                )}
                                            </div>
                                        ))
                                    ) : data.settings.Category === "Natural Hazards" ? (
                                        <p className="expanded-details-text">
                                            Insufficient data provided by the National Risk Index
                                        </p>
                                    ) : null}
                                </div>
                                {topicDescription != undefined ? (
                                    <div className="topic-explantion">
                                        <p className="expanded-details-description">{topicDescription.Title}</p>
                                        {topicDescription.Description.map((element, index) =>
                                            React.cloneElement(element, { key: index })
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </td>
                    </tr>
                )}
            </>
        );
    }
};

export default Table;
