import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Asbestos.css";
import jsonData from "./AsbestosCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function Asbestos() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Asbestos Mines and Occurrences Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover asbestos mines and occurrences within 10 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Asbestos Mines and Occurrences Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> asbestos mines and occurrences within a 10 mile radius</strong> of your
                    property by leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into natural gas wells but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics
                    Release Inventory sites to public drinking water systems, air and noise pollution sources, natural
                    hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to make
                    informed decisions about your surroundings. Take control of your environmental awareness today and
                    safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure
                    a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Asbestos Mines & Occurences by State & Development</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">What is an Asbestos Mines & Occurence</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Asbestos mining refers to the extraction of naturally occurring asbestos minerals from
                            deposits found in various geological formations worldwide. Asbestos, a group of six
                            naturally occurring fibrous minerals, was extensively mined for its unique properties,
                            including heat resistance, strength, and insulating capabilities. These characteristics made
                            asbestos a highly sought-after material for various industrial applications, such as
                            insulation, construction, and manufacturing, particularly during the 20th century.
                            <br />
                            <br />
                            Asbestos deposits occur in rock formations, predominantly in metamorphic and ultramafic
                            rocks, where they are found in association with minerals such as serpentine, amphibole, and
                            talc. The primary asbestos minerals mined commercially include chrysotile (white asbestos),
                            crocidolite (blue asbestos), amosite (brown asbestos), anthophyllite, tremolite, and
                            actinolite. Among these, chrysotile is the most commonly mined and accounts for the majority
                            of asbestos production globally.
                            <br />
                            <br />
                            Historically, asbestos mining operations were prevalent in regions with abundant asbestos
                            deposits, such as Canada (particularly Quebec), Russia, China, Brazil, and South Africa.
                            These mines supplied raw asbestos fibers to industries worldwide, driving economic growth
                            but also exposing workers to significant health risks.
                            <br />
                            <br />
                            The mining and processing of asbestos pose severe health hazards due to the inhalation of
                            airborne asbestos fibers, which can cause serious respiratory diseases, including
                            asbestosis, lung cancer, and mesothelioma. Workers involved in asbestos mining, extraction,
                            and manufacturing were particularly vulnerable to asbestos-related diseases due to prolonged
                            exposure to high concentrations of airborne fibers.
                            <br />
                            <br />
                            In recent decades, awareness of the health risks associated with asbestos exposure has led
                            to the decline of asbestos mining activities in many countries. Some countries have
                            implemented strict regulations or banned asbestos mining altogether to protect public health
                            and the environment. However, asbestos mining continues in a few regions where regulations
                            may be less stringent or enforcement is lacking, posing ongoing risks to workers and nearby
                            communities.
                            <br />
                            <br />
                            In summary, asbestos mining involves the extraction of naturally occurring asbestos minerals
                            from geological deposits and has historically been associated with significant health risks
                            due to exposure to airborne asbestos fibers. Increased awareness and regulation have led to
                            a decline in asbestos mining activities, but vigilance is still necessary to prevent further
                            exposure and protect public health.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Health Risks Associated with Living Near an Asbestos Mines & Occurence
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near an asbestos mine or occurrence poses severe health risks due to potential
                            exposure to airborne asbestos fibers, which can cause debilitating health conditions.
                            Asbestos, a naturally occurring mineral known for its durability and heat resistance, is
                            associated with various serious health effects when its microscopic fibers are inhaled or
                            ingested. While asbestos mining operations have declined in many parts of the world due to
                            health concerns, individuals residing in proximity to active or abandoned asbestos mines
                            still face significant health hazards.
                            <br />
                            <br />
                            One of the most serious health effects of asbestos exposure is the development of
                            asbestos-related diseases. Inhalation of asbestos fibers can lead to conditions such as
                            asbestosis, a chronic lung disease characterized by scarring and inflammation of lung
                            tissues, which can cause progressive breathing difficulties and respiratory failure over
                            time. Moreover, asbestos exposure is strongly linked to the development of lung cancer,
                            particularly among individuals with a history of heavy or prolonged exposure.
                            <br />
                            <br />
                            Another devastating consequence of asbestos exposure is the development of mesothelioma, a
                            rare and aggressive form of cancer affecting the lining of the lungs, abdomen, or heart.
                            Mesothelioma has a long latency period, often taking decades to manifest after initial
                            asbestos exposure, making it challenging to diagnose and treat effectively. Individuals
                            living near asbestos mines may face increased risks of developing mesothelioma due to the
                            potential for continued exposure to asbestos fibers released during mining activities.
                            <br />
                            <br />
                            These health risks associated with living near asbestos mines are well-documented in
                            scientific literature and have been extensively studied by researchers and health
                            organizations worldwide. Studies such as those published in journals like Environmental
                            Health Perspectives and Occupational and Environmental Medicine provide evidence of the
                            health effects of asbestos exposure, emphasizing the importance of mitigating exposure risks
                            and protecting public health in communities near asbestos mining sites.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Asbestos;
