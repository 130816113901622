import "./AutocompleteWidget.css";
import useGooglePlaceAutoComplete from "../../service/GooglePlaceAutocomplete";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { apiCall } from "../../service/UserServices.js";

const GooglePlacesWidget = (props) => {
    const address1Ref = useRef();
    const baseInputDivClass = "form-field-container";
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    const [address, setAddress] = React.useState({});
    const [addressLine2, setAddressLine2] = React.useState("");
    const [addressInputDivClass, setAddressInputDivClass] = React.useState(baseInputDivClass);
    const [cityInputDivClass, setCityInputDivClass] = React.useState(baseInputDivClass);
    const [stateInputDivClass, setStateInputDivClass] = React.useState(baseInputDivClass);
    const [postalCodeInputDivClass, setPostalCodeInputDivClass] = React.useState(baseInputDivClass);

    let autoComplete = "";

    const {
        handleSubmit,
        register,
        setFocus,
        setValue,
        formState: { errors },
    } = useForm({});

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);

        address1Ref.current.value = addressObj.address1;
        setValue("address1", addressObj.address1);
        setValue("city", `${addressObj.locality}`);
        setValue("state", `${addressObj.adminArea1Long}`);
        setValue("country", addressObj.countryLong);
        setValue("postalCode", addressObj.postalCode);
        setFocus("address2");
        setValue("formattedAddress", addressObj.formattedAddress);
        setValue("lat", addressObj.lat);
        setValue("long", addressObj.long);
        setAddress(addressObj);
    };
    const navigate = useNavigate();

    const onSubmit = async () => {
        const attributes = await Auth.currentUserInfo();
        let sub = attributes.attributes.sub;
        let addressLine1 = address.address1;
        // let addressLine2 = addressLine2;
        let city = address.locality;
        let state = address.adminArea1Long;
        let postalCode = address.postalCode;
        let lat = address.lat;
        let long = address.long;

        //If user has pay per report, redirect to checkout after confirming this report has not already been purchased
        let pricingPlanId = await apiCall("/users/pricing-plan?", { sub });
        if (pricingPlanId.data.plan == 1) {
            let reportLogged = await apiCall("/users/report?", {sub, addressLine1, addressLine2, city, state, postalCode });
            if (reportLogged.data.reportId != null) {
                alert(
                    "You have already purchased this report. You can find your purchased reports in your account dashboard. Click Ok to view your report."
                );
                navigate({
                    pathname: "/residential-environmental-search/report-summary",
                    search: createSearchParams({
                        id: reportLogged.data.reportId ,
                    }).toString(),
                });
            } else {
                navigate("/checkout", {
                    state: { sub, address, addressLine2 },
                });
            }
        } else {
            //Subscription path
            const data = {
                sub: sub,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                postalCode: postalCode,
                lat: lat,
                long: long
            };
            let reportLogged = await apiCall("/users/reports?", data, "POST");
            navigate({
                pathname: "/residential-environmental-search/report-summary",
                search: createSearchParams({
                    id: reportLogged.data.reportId,
                }).toString(),
            });
        }
    };

    useEffect(() => {
        if (errors.address1) {
            setAddressInputDivClass(baseInputDivClass + " missing-value");
        }

        if (errors.city) {
            setCityInputDivClass(baseInputDivClass + " missing-value");
        }

        if (errors.state) {
            setStateInputDivClass(baseInputDivClass + " missing-value");
        }

        if (errors.postalCode) {
            setPostalCodeInputDivClass(baseInputDivClass + " missing-value");
        }
    }, [errors]);

    useEffect(() => {
        async function loadGoogleMaps() {
            // initialize the Google Place Autocomplete widget and bind it to an input element.
            // eslint-disable-next-line
            autoComplete = await googleAutoCompleteSvc.initAutoComplete(address1Ref.current, handleAddressSelect);
        }
        loadGoogleMaps();
    }, []);

    return (
        <div className="widget-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="address-container">
                    <div className={addressInputDivClass}>
                        <label htmlFor="address1" className="input-label">
                            Address Line 1*
                        </label>
                        <input
                            id="address1"
                            type="text"
                            className="form-field"
                            placeholder="Address"
                            {...register("address1", { required: true })}
                            ref={address1Ref}
                        />
                    </div>
                    <div className={baseInputDivClass}>
                        <label className="input-label">Address Line 2</label>
                        <input
                            onChange={(event) => setAddressLine2(event.target.value, address)}
                            type="text"
                            className="form-field"
                            placeholder="Apartment, Suite, Etc. (Optional)"
                        />
                    </div>
                    <div className={cityInputDivClass} id="cityInputContainer">
                        <label className="input-label">City*</label>
                        <input
                            type="text"
                            className="form-field"
                            placeholder="City"
                            {...register("city", { required: true })}
                        />
                    </div>
                    {/* <div className="form-field-container"> */}
                    <div className="short-form-field-container">
                        <div className={stateInputDivClass}>
                            <label className="input-label">State*</label>
                            <input
                                type="text"
                                className="short-form-field stateField"
                                placeholder="State"
                                {...register("state", { required: true })}
                            />
                        </div>
                        <div className={postalCodeInputDivClass}>
                            <label className="input-label">Postal Code*</label>
                            <input
                                type="text"
                                className="short-form-field postalCodeField"
                                placeholder="Postal Code"
                                {...register("postalCode", { required: true })}
                            />
                        </div>
                        {/* </div> */}
                    </div>
                </div>
                <div className="button-container">
                    <button type="submit" className="search-button">
                        Continue
                    </button>
                </div>
            </form>
        </div>
    );
};

export default GooglePlacesWidget;
