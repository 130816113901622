import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IoCarSportOutline, IoRestaurantOutline } from "react-icons/io5";
import { TbMicrophone2 } from "react-icons/tb";
import { FaDumpsterFire } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import { GiPowerGenerator, GiRefinery } from "react-icons/gi";
import { GiOilPump } from "react-icons/gi";
import { TbToiletPaper } from "react-icons/tb";

function AirPollution() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Air Pollution: Sources, Effects on Health, and Home Impact</title>
                <meta
                    name="description"
                    content="EnviroHomePro dives into the origins and effects of air pollution on health and home environments. Essential insights for informed decision-making."
                />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">Air Pollution: Sources & Impact</h1>
                <h2 className="info-page-pitch">
                    Residing in areas with low air pollution is beneficial for overall well-being. Clean air contributes
                    to respiratory health, reducing the risk of respiratory illnesses and improving overall quality of
                    life. Choosing locations with stringent air quality regulations and monitoring systems ensures
                    minimal exposure to harmful pollutants. Additionally, communities emphasizing sustainable practices,
                    such as reduced emissions from transportation and industry, create a healthier living environment.
                    Prioritizing air quality in residential choices promotes better respiratory health, mitigates
                    environmental impacts, and enhances the overall quality of life for residents.
                </h2>
            </div>

            <div className="info-div">
                <section className="info-section" id="landfills">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/landfills-near-you">
                            {" "}
                            Landfills <IoTrashOutline className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            Living near a landfill presents significant concerns regarding air pollution and
                            environmental well-being. The constant decomposition of waste releases a concoction of
                            harmful gases, including methane and volatile organic compounds, into the air. These
                            pollutants contribute to poor air quality, leading to respiratory issues and long-term
                            health risks for nearby residents. Moreover, the release of airborne particles from the
                            landfill can settle on surfaces, contaminate water sources, and harm local flora and fauna.
                            <br />
                            <br />
                            The environmental impact extends beyond immediate health concerns, affecting ecosystems and
                            biodiversity. Proximity to landfills exacerbates the vulnerability of the surrounding
                            environment, underscoring the critical need for sustainable waste management practices to
                            mitigate these detrimental effects.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Health Risks:</b> The constant release of hazardous gases such as methane and
                                volatile organic compounds (VOCs) from the landfill contributes to poor air quality,
                                resulting in respiratory problems and long-term health risks for nearby residents.
                            </li>

                            <li className="info-list-item">
                                <b>Airborne Particles:</b> The dispersal of airborne particles from the landfill poses a
                                threat to both human health and the environment, settling on surfaces, contaminating
                                water sources, and negatively impacting local flora and fauna.
                            </li>

                            <li className="info-list-item">
                                <b>Poor Air Quality:</b> The decomposition process emits pollutants that degrade air
                                quality in the vicinity, affecting not only residents' health but also contributing to
                                broader environmental concerns related to pollution and climate change.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Contamination:</b> Harmful substances released from the landfill can
                                seep into the soil and water, causing contamination that adversely affects ecosystems
                                and biodiversity in the surrounding areas.
                            </li>

                            <li className="info-list-item">
                                <b>Ecosystem Vulnerability:</b> Proximity to landfills increases the vulnerability of
                                local ecosystems, disrupting the balance of natural habitats and posing long-term
                                threats to the diversity and health of plant and animal life.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="incinerators">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/incinerators-near-you">
                            {" "}
                            Incinerators <FaDumpsterFire className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Airborne Pollutants:</b> Incinerators release a mix of airborne pollutants, including
                                dioxins, heavy metals, and particulate matter, contributing to degraded air quality in
                                the vicinity and posing respiratory and health risks for nearby residents.
                            </li>

                            <li className="info-list-item">
                                <b>Toxic Emissions:</b> The incineration process produces toxic emissions that can
                                contaminate the air, soil, and water, leading to environmental degradation and potential
                                harm to ecosystems and wildlife in the surrounding areas.
                            </li>

                            <li className="info-list-item">
                                <b>Health Impacts:</b> Proximity to incinerators is associated with adverse health
                                effects due to exposure to harmful pollutants, with increased risks of respiratory
                                diseases, cardiovascular issues, and other related ailments among residents.
                            </li>

                            <li className="info-list-item">
                                <b>Waste of Resources:</b> Incinerators consume valuable resources that could be
                                recycled, contributing to a less sustainable waste management approach and hindering
                                efforts to reduce overall environmental impact.
                            </li>

                            <li className="info-list-item">
                                <b>Community Concerns:</b> Incinerators often face community opposition due to the
                                perceived health risks, environmental impact, and the potential decrease in overall
                                quality of life for residents living in close proximity.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Living near incinerators introduces a host of environmental and health concerns. These
                            facilities emit a cocktail of airborne pollutants, including dioxins and heavy metals,
                            compromising air quality and posing risks to respiratory health. Toxic emissions from
                            incineration can contaminate surrounding soil and water, endangering ecosystems and
                            wildlife. Proximity to incinerators correlates with adverse health effects among residents,
                            such as respiratory and cardiovascular issues. Furthermore, the incineration process diverts
                            waste from recycling, hindering sustainable waste management practices. Community opposition
                            often arises due to perceived health risks and environmental impact, highlighting the
                            multifaceted challenges associated with living near incineration facilities.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="power-plants">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/power-plants-near-you">
                            {" "}
                            Power Plants <GiPowerGenerator className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living near power plants presents various environmental concerns. These facilities release
                            air pollutants like sulfur dioxide and nitrogen oxides, contributing to air pollution and
                            respiratory issues. Greenhouse gas emissions from power plants also contribute to climate
                            change and its associated impacts. The substantial water consumption for cooling, coupled
                            with the discharge of heated water and pollutants, can harm local water resources and
                            aquatic ecosystems. Additionally, power plant construction and operation can disrupt
                            habitats and alter landscapes, impacting biodiversity. Noise pollution and visual
                            disturbances further contribute to the challenges, affecting the overall quality of life for
                            nearby residents.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Air Pollutants:</b> Power plants emit various air pollutants, including sulfur
                                dioxide, nitrogen oxides, and particulate matter, contributing to air pollution and
                                potential respiratory health issues for nearby residents.
                            </li>

                            <li className="info-list-item">
                                <b>Greenhouse Gas Emissions:</b> Many power plants release significant amounts of carbon
                                dioxide and other greenhouse gases, contributing to climate change and its associated
                                environmental impacts, such as rising temperatures and extreme weather events.
                            </li>

                            <li className="info-list-item">
                                <b>Water Consumption and Pollution:</b> Power plants often require large amounts of
                                water for cooling, impacting local water resources. Additionally, the discharge of
                                heated water and pollutants back into water bodies can harm aquatic ecosystems.
                            </li>

                            <li className="info-list-item">
                                <b>Land Use and Habitat Disruption:</b> The construction and operation of power plants
                                can lead to habitat disruption, deforestation, and altered landscapes, affecting
                                biodiversity and ecosystems in the surrounding areas.
                            </li>

                            <li className="info-list-item">
                                <b>Noise and Visual Impact:</b> Power plants can generate noise pollution and have a
                                significant visual impact, potentially decreasing the aesthetic quality of the
                                surrounding environment and affecting the well-being of nearby communities.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="refineries">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/refineries-near-you">
                            {" "}
                            Refineries <GiRefinery className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Air Quality Impact:</b> Refineries release a variety of air pollutants, including
                                volatile organic compounds (VOCs), sulfur dioxide, and particulate matter, contributing
                                to poor air quality and respiratory health issues for nearby residents.
                            </li>

                            <li className="info-list-item">
                                <b>Chemical Spills and Leaks:</b> Refineries pose the risk of chemical spills and leaks,
                                leading to soil and water contamination, with potential adverse effects on ecosystems,
                                groundwater, and local flora and fauna.
                            </li>

                            <li className="info-list-item">
                                <b>Greenhouse Gas Emissions:</b> The refining process results in the emission of
                                greenhouse gases, contributing to climate change and its associated environmental
                                impacts, such as global warming and shifts in weather patterns.
                            </li>

                            <li className="info-list-item">
                                <b>Water Usage and Pollution:</b> Refineries often require large amounts of water for
                                cooling and other processes, impacting local water resources. The discharge of heated
                                water and pollutants can harm aquatic ecosystems and compromise water quality.
                            </li>

                            <li className="info-list-item">
                                <b>Visual and Noise Disturbances:</b> Refineries can create visual and noise
                                disturbances, altering the landscape and affecting the well-being of nearby communities
                                by diminishing the aesthetic quality of the environment.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Living near refineries brings forth numerous environmental concerns. These facilities
                            release pollutants like VOCs, sulfur dioxide, and particulate matter, contributing to poor
                            air quality and respiratory issues. The risk of chemical spills and leaks poses threats to
                            soil, water, and local ecosystems. Greenhouse gas emissions from the refining process
                            contribute to climate change, impacting global environmental conditions. Large water usage
                            for cooling and the discharge of heated water and pollutants can harm local water resources
                            and aquatic life. Additionally, refineries may create visual and noise disturbances,
                            affecting the overall quality of life for nearby residents.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="natural-gas-wells">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/natural-gas-wells-near-you">
                            {" "}
                            Natural Gas Wells <GiOilPump className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living near natural gas wells raises environmental considerations. Methane emissions from
                            these wells contribute to climate change, impacting global environmental conditions.
                            Hydraulic fracturing poses the risk of water contamination through the release of chemicals
                            into groundwater, potentially harming local water supplies and ecosystems. The construction
                            and operation of wells can disrupt landscapes, leading to habitat fragmentation and
                            alterations to local ecosystems. Noise pollution and air pollutants generated during
                            drilling and extraction processes can affect nearby communities, impacting the overall
                            quality of life. Additionally, concerns about induced seismicity raise questions about
                            earthquake risks associated with natural gas extraction activities.
                        </div>
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Methane Emissions:</b> Natural gas wells release methane, a potent greenhouse gas,
                                contributing to climate change and environmental impacts such as global warming and
                                shifts in weather patterns.
                            </li>

                            <li className="info-list-item">
                                <b>Water Contamination:</b> Hydraulic fracturing (fracking) used in natural gas
                                extraction poses the risk of water contamination through the release of chemicals into
                                groundwater, potentially harming local water supplies and ecosystems.
                            </li>

                            <li className="info-list-item">
                                <b>Land Disruption:</b> The construction and operation of natural gas wells can disrupt
                                landscapes, leading to habitat fragmentation, deforestation, and alterations to local
                                ecosystems.
                            </li>

                            <li className="info-list-item">
                                <b>Noise and Air Pollution:</b> The drilling and extraction processes generate noise
                                pollution, impacting nearby communities. Additionally, the release of pollutants during
                                extraction can contribute to air pollution and respiratory issues for residents.
                            </li>

                            <li className="info-list-item">
                                <b>Earthquake Risks:</b> Some areas with natural gas extraction activities have
                                experienced induced seismicity, raising concerns about potential earthquake risks
                                associated with the injection and extraction of fluids during the fracking process.
                            </li>
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="oil-wells">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/oil-wells-near-you">
                            {" "}
                            Oil Wells <GiOilPump className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Living near oil wells raises significant environmental concerns. Oil spills pose a
                            considerable risk, causing widespread environmental damage and impacting aquatic ecosystems
                            and wildlife. The extraction and refining of oil release pollutants that contribute to poor
                            air quality, affecting the respiratory health of nearby residents. Water contamination is
                            another risk, with potential adverse effects on local water supplies, aquatic ecosystems,
                            and human health. The construction and operation of oil wells can disrupt habitats, leading
                            to deforestation and alterations to landscapes. Additionally, oil extraction processes
                            contribute to greenhouse gas emissions, further exacerbating climate change and its
                            associated environmental impacts.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Oil Spills:</b> Oil wells pose the risk of spills during extraction, transportation,
                                and storage, causing widespread environmental damage, harming aquatic ecosystems, and
                                impacting wildlife.
                            </li>

                            <li className="info-list-item">
                                <b>Air Pollution:</b> The extraction and refining of oil release pollutants such as
                                sulfur dioxide, nitrogen oxides, and volatile organic compounds, contributing to poor
                                air quality and respiratory health issues for nearby residents.
                            </li>

                            <li className="info-list-item">
                                <b>Water Contamination:</b> Oil extraction activities can lead to water contamination
                                through the release of pollutants and chemicals, posing risks to local water supplies,
                                aquatic ecosystems, and human health.
                            </li>

                            <li className="info-list-item">
                                <b>Land Disruption:</b> The construction and operation of oil wells can result in
                                habitat disruption, deforestation, and alterations to landscapes, impacting biodiversity
                                and local ecosystems.
                            </li>

                            <li className="info-list-item">
                                <b>Greenhouse Gas Emissions:</b> Oil extraction and refining processes contribute to
                                greenhouse gas emissions, exacerbating climate change and its associated environmental
                                impacts, such as rising temperatures and extreme weather events.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div">
                <section className="info-section" id="pulp-and-paper-mills">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/pulp-and-paper-mills-near-you">
                            {" "}
                            Pulp and Paper Mills <TbToiletPaper className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Airborne Pollutants:</b> Pulp and paper mills release airborne pollutants, including
                                particulate matter, sulfur compounds, and volatile organic compounds, contributing to
                                poor air quality and respiratory health issues for nearby residents.
                            </li>

                            <li className="info-list-item">
                                <b>Water Pollution:</b> The discharge of effluents containing chemicals used in the
                                pulping and papermaking process can lead to water pollution, adversely affecting aquatic
                                ecosystems, water quality, and potentially harming human health.
                            </li>

                            <li className="info-list-item">
                                <b>Deforestation:</b> Pulp and paper mills contribute to deforestation as they often
                                rely on wood fibers for paper production, impacting biodiversity, habitats, and
                                disrupting ecosystems.
                            </li>

                            <li className="info-list-item">
                                <b>Noise and Odor:</b> The operations of pulp and paper mills can generate noise and
                                unpleasant odors, affecting the well-being and quality of life for residents living in
                                proximity to these facilities.
                            </li>

                            <li className="info-list-item">
                                <b>Energy Consumption:</b> The energy-intensive nature of pulp and paper production
                                contributes to environmental concerns, as it often relies on non-renewable resources,
                                leading to increased greenhouse gas emissions and resource depletion.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Living near pulp and paper mills raises environmental considerations. These facilities
                            release airborne pollutants, impacting air quality and residents' respiratory health. The
                            discharge of effluents into water bodies can lead to water pollution, affecting aquatic
                            ecosystems and potentially harming human health. Pulp and paper mills' reliance on wood
                            fibers contributes to deforestation, disrupting habitats and biodiversity. Operations
                            generate noise and unpleasant odors, impacting the quality of life for nearby residents. The
                            energy-intensive nature of pulp and paper production raises concerns about environmental
                            sustainability, as it often relies on non-renewable resources, contributing to greenhouse
                            gas emissions and resource depletion.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AirPollution;
