import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IoAirplaneOutline, IoTrainOutline, IoCarSportOutline, IoRestaurantOutline } from "react-icons/io5";
import { TbMicrophone2 } from "react-icons/tb";

function NoisePollution() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <title>Noise Pollution: Sources & Impact on Health & Home</title>
                <meta
                    name="description"
                    content="Uncover the impact of noise pollution on home environments with EnviroHomePro. Essential insights for informed decisions and improved livability."
                />
            </Helmet>

            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">Noise Pollution: Sources & Impact</h1>
                <h2 className="info-page-pitch">
                    Caring about noise pollution in your home is not just a matter of personal comfort; it's a crucial
                    element for overall well-being, affecting physical health, mental balance, social interactions, and
                    even property values. Noise pollution, often underestimated, has profound implications on our daily
                    lives. Learn more about some of the noise pollution sources <b>EnviroHomePro</b> tracks below!
                </h2>
            </div>
            <div className="info-div">
                <section className="info-section" id="airports">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/airports-near-you">
                            {" "}
                            Airports <IoAirplaneOutline className="info-icon" id="airport-icon" />
                        </a>
                    </h2>

                    <div className="info-block">
                        <div className="info-section-text">
                            Residing in close proximity to an airport introduces a distinctive blend of advantages and
                            challenges, particularly concerning noise pollution. This nuanced perspective takes into
                            account both positive and negative aspects related to the environmental impact of aircraft
                            noise.
                            <br />
                            <br />
                            While the convenience of living near an airport is evident, the pronounced impact of
                            aircraft noise on property values and the overall living environment must be thoughtfully
                            considered. Prospective homebuyers are encouraged to assess their priorities, explore noise
                            mitigation measures, and stay informed about local regulations and community-driven
                            initiatives when contemplating properties in proximity to airports.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Positive Aspects:</b> Living near an airport provides undeniable convenience for
                                individuals who frequently engage in air travel. The ease of access to airport
                                facilities can be a notable asset, streamlining the travel experience.
                            </li>
                            <li className="info-list-item">
                                <b>Negative Aspects:</b> The predominant challenge associated with living near an
                                airport revolves around the consistent noise generated by aircraft during takeoff and
                                landing. The pervasive airplane noise can significantly affect the desirability of
                                properties in the vicinity.
                            </li>
                            <li className="info-list-item">
                                <b>Impact on Property Values:</b> Real estate properties in close proximity to airports
                                may face reduced desirability and potential depreciation due to the ongoing noise
                                disturbances. Prospective homebuyers often consider the consequential impact of aircraft
                                noise on their daily lives and overall residential experience.
                            </li>

                            <li className="info-list-item">
                                <b>Weighing Convenience Against Noise Disturbances:</b> Homebuyers are tasked with the
                                critical evaluation of the convenience of residing near an airport in comparison to the
                                potential noise disturbances. This involves a careful examination of individual
                                priorities and lifestyle preferences.
                            </li>
                            <li className="info-list-item">
                                <b>Exploring Noise Mitigation Measures:</b> Investigating available noise mitigation
                                measures becomes essential. Some communities implement strategies such as soundproofing
                                initiatives or comprehensive noise reduction programs to alleviate the impact of
                                airport-related noise on nearby residents.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="train-lines">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/trains-near-you">
                            {" "}
                            Train Lines <IoTrainOutline className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Noise Disturbances:</b> The continuous and often loud sounds of trains passing by can
                                be disruptive to residents, potentially impacting their quality of life and daily
                                activities.
                            </li>
                            <li className="info-list-item">
                                <b>Sleep Disruption:</b> The rhythmic noise of train activity, especially during
                                nighttime hours, may interfere with sleep patterns, causing discomfort for those
                                sensitive to disturbances.
                            </li>
                            <li className="info-list-item">
                                <b>Reduced Property Values:</b> Homes near train lines may experience reduced market
                                desirability and lower property values due to the perceived negative impact of noise
                                pollution.
                            </li>
                            <li className="info-list-item">
                                <b>Potential Safety Concerns:</b> Proximity to train lines may pose safety concerns,
                                especially for households with young children or pets, as there is an increased risk of
                                accidents or incidents.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Proximity to train lines presents unique challenges for real estate. The rhythmic sounds of
                            trains passing can be a comforting background noise for some, but for others, it may pose a
                            disturbance. Homebuyers should consider the frequency and volume of train activity when
                            evaluating properties near train lines. Understanding the balance between accessibility and
                            potential noise disruptions is key to making informed decisions.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="traffic">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/highways-near-you">
                            {" "}
                            Traffic <IoCarSportOutline className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            High traffic areas can significantly impact real estate values. Properties located near busy
                            streets or highways may experience higher noise levels, potentially affecting the quality of
                            life for residents. Homebuyers are advised to consider the time of day when traffic is most
                            intense and evaluate how noise may impact their daily living. Noise reduction measures, such
                            as soundproofing, may also be worth exploring in such locations.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Noise Disturbances:</b> High traffic areas generate continuous and potentially loud
                                noise levels, which can be disruptive to residents, impacting their quality of life and
                                daily activities.
                            </li>
                            <li className="info-list-item">
                                <b>Sleep Disruption:</b> The persistent noise from heavy traffic, especially during
                                nighttime hours, may interfere with residents' sleep patterns, leading to discomfort and
                                potential health issues.
                            </li>
                            <li className="info-list-item">
                                <b>Reduced Property Values:</b> Properties located near busy streets or highways may
                                experience decreased market desirability and lower property values due to the perceived
                                negative impact of noise pollution.
                            </li>
                            <li className="info-list-item">
                                <b>Health Concerns:</b> Prolonged exposure to high traffic noise has been associated
                                with stress-related health issues, including elevated blood pressure and increased
                                levels of stress hormones.
                            </li>
                            <li className="info-list-item">
                                <b>Outdoor Enjoyment Limitations:</b> Residents near high traffic areas may have limited
                                opportunities for peaceful outdoor activities due to the constant noise, impacting their
                                ability to relax and enjoy their surroundings.
                            </li>
                            <li className="info-list-item">
                                <b>Safety Concerns:</b> Increased traffic often correlates with higher accident rates.
                                Residents may face safety concerns, especially if they have young children or pets, due
                                to the potential risks associated with heavy traffic flow.
                            </li>
                            <li className="info-list-item">
                                <b>Air Quality Issues:</b> High traffic areas can contribute to poor air quality,
                                impacting the health of residents through increased exposure to pollutants emitted by
                                vehicles.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default NoisePollution;
