import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import jsonData from "./LandfillCount.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function Landfills() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Open And Closed Landfills Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover both open and closed landfills within 5 miles of your home with EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health"
                />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>
                    Find Open and Closed Landfills Near You and Learn the Risks
                    </strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong>open and closed landfills within a 5-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into landfills but also into 40 other critical environmental
                    factors impacting your home and well-being. From EPA Superfund sites, EPA Brownfield sites, public
                    drinking water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        <a
                            className="category-header-link"
                            target="_blank"
                            href="https://www.epa.gov/landfills/basic-information-about-landfills">
                            What is a Landfill?
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A landfill is a designated area where waste materials are disposed of and buried
                            underground, typically managed by local governments or private waste management companies.
                            These sites serve as the final destination for various types of solid waste generated by
                            households, businesses, industries, and construction projects. Landfills are engineered
                            facilities designed to contain and isolate waste from the surrounding environment, with
                            layers of liner materials such as clay and synthetic membranes to prevent leachate, a liquid
                            formed as water percolates through the waste, from contaminating groundwater.
                            <br />
                            <br />
                            The waste deposited in landfills can include household trash, organic matter, construction
                            debris, industrial byproducts, and hazardous materials. Over time, as waste decomposes, it
                            produces gases such as methane and carbon dioxide, as well as potentially harmful substances
                            like volatile organic compounds (VOCs) and heavy metals. Landfill gas, primarily composed of
                            methane, is a significant contributor to greenhouse gas emissions and can pose fire and
                            explosion risks if not properly managed.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                    Are There Air Pollution Risks Associated with Living Near a Landfill?
                                        </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Landfills are significant sources of air pollution, emitting a variety of gases and
                            particulate matter that can degrade air quality and pose health risks to nearby communities.
                            One of the primary pollutants released from landfills is methane, a potent greenhouse gas
                            that contributes to climate change. Methane is produced during the decomposition of organic
                            waste in anaerobic (oxygen-deprived) conditions within the landfill. Additionally, landfills
                            emit other volatile organic compounds (VOCs), such as benzene, toluene, and formaldehyde,
                            which can react with other pollutants in the atmosphere to form ground-level ozone and smog.
                            <br />
                            <br />
                            Particulate matter (PM), including dust, ash, and aerosols, is another air pollutant
                            generated by landfills, primarily through the disturbance of waste during dumping,
                            compaction, and excavation activities. These fine particles can become airborne and pose
                            respiratory hazards when inhaled, especially for vulnerable populations such as children,
                            the elderly, and individuals with pre-existing respiratory conditions.
                            <br />
                            <br />
                            Furthermore, odorous compounds released from landfills, including hydrogen sulfide, ammonia,
                            and various volatile organic compounds, contribute to nuisance odors that can impact the
                            quality of life for nearby residents and cause discomfort and annoyance. Overall, the air
                            pollution risks associated with landfills underscore the importance of proper waste
                            management practices, including landfill gas capture and control systems, to minimize
                            emissions and protect public health and the environment.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                    Are There Other Health Risks Associated with Living Near a Landfill?
                                        </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a landfill poses various health risks beyond air pollution. The proximity to
                            waste disposal sites can expose residents to contaminants leaching into soil and
                            groundwater, potentially contaminating drinking water sources and agricultural produce.
                            Exposure to hazardous substances found in landfill leachate, such as heavy metals,
                            pesticides, and toxic chemicals, may lead to adverse health effects including
                            gastrointestinal illnesses, neurological disorders, and certain types of cancer.
                            Additionally, the presence of pests attracted to landfill sites, such as rodents and
                            insects, can increase the risk of vector-borne diseases and exacerbate respiratory
                            conditions. Furthermore, the stress and anxiety associated with living near a landfill,
                            including concerns about odors, property devaluation, and environmental degradation, can
                            negatively impact mental health and well-being.
                            <br />
                            <br />
                            In conclusion, while landfills play a crucial role in managing society's waste, they also
                            pose significant risks and challenges for nearby communities. Environmental contamination,
                            odors, property devaluation, and potential health effects are among the concerns associated
                            with living near landfills. Effective waste management practices, including proper site
                            selection, engineering controls, monitoring, and community engagement, are essential to
                            mitigate these risks and ensure the protection of human health and the environment in areas
                            surrounding landfills.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Landfill Count by City, State, and Type</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Landfills;
