import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import jsonData from "./PulpAndPaperMillCount.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function PulpAndPaperMills() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Pulp And Paper Mills Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover pulp and paper mills within 5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>
                        Find Pulp and Paper Mills Near You and Learn the Risks
                    </strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong>pulp and paper mills within a 5-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into pulp and paper mills but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund and Brownfield sites to
                    public drinking water systems, air and noise pollution sources, natural hazards, and livability
                    factors, EnviroHomePro provides comprehensive data to empower you to make informed decisions about
                    your surroundings. Take control of your environmental awareness today and safeguard your health and
                    home with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer
                    environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        What is a{" "}
                        <a
                            className="category-header-link"
                            href="https://www.sciencedirect.com/topics/earth-and-planetary-sciences/pulp-and-paper-industry"
                            target="_blank">
                            Pulp and Paper Mill
                        </a>
                        ?
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A pulp and paper mill is a facility where wood fibers are transformed into paper products
                            through a series of intricate processes. It begins with the raw material – typically wood
                            logs or recycled paper – which undergoes pulping, a procedure that breaks down the wood
                            fibers into a fibrous mass called pulp.
                            <br />
                            <br />
                            The pulp is then refined to achieve desired characteristics such as strength, smoothness,
                            and brightness. This refining process involves various techniques like mechanical grinding
                            or chemical treatments. Once refined, the pulp is mixed with water to form a slurry, which
                            is then spread onto a wire mesh to remove excess water, forming a thin layer of fibers known
                            as the paper sheet.
                            <br />
                            <br />
                            Further steps include pressing to remove more water and drying the sheet to the desired
                            moisture content. Depending on the intended use, the paper may undergo additional treatments
                            such as coating, calendaring (smoothing), or sizing (adding substances to enhance properties
                            like water resistance). Finally, the paper is wound onto large rolls or cut into sheets,
                            ready for distribution to consumers for various applications like packaging, printing, or
                            writing.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                    Are There Air Pollution Risks Living Near a Pulp and Paper Mill
                                        </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a pulp and paper mill poses significant air pollution risks due to the various
                            emissions generated during the manufacturing process. These emissions include volatile
                            organic compounds (VOCs), particulate matter (PM), sulfur dioxide (SO2), nitrogen oxides
                            (NOx), and other hazardous pollutants. VOCs can contribute to the formation of ground-level
                            ozone and pose health risks such as respiratory issues and irritation of the eyes and
                            throat. PM emissions can lead to respiratory problems and cardiovascular issues, especially
                            in vulnerable populations like children and the elderly. Additionally, sulfur dioxide and
                            nitrogen oxides can contribute to acid rain formation, harming ecosystems and corroding
                            buildings and infrastructure. Continuous exposure to these pollutants may increase the risk
                            of respiratory diseases, cardiovascular problems, and other health complications for
                            individuals living in close proximity to pulp and paper mills.
                        </div>
                    </div>
                </section>
            </div>
        
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                    Are There Other Risks Associated with Living Near a Pulp and Paper Mill?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            In addition to air pollution risks, living near a pulp and paper mill can pose other
                            environmental and health hazards. Water pollution is a significant concern, as mills often
                            discharge wastewater containing chemicals used in the pulping and bleaching processes. These
                            chemicals can include chlorine, chlorine dioxide, and various organic compounds, which may
                            contaminate local water sources and harm aquatic ecosystems.
                            <br />
                            <br />
                            Noise pollution is another potential issue, as pulp and paper mills can generate high levels
                            of noise from machinery and transportation activities. Prolonged exposure to excessive noise
                            levels can lead to hearing loss, stress, and other adverse health effects.
                            <br />
                            <br />
                            Furthermore, the transportation of raw materials to and finished products from the mill can
                            increase traffic congestion and contribute to road accidents in the surrounding area.
                            Additionally, the visual impact of large industrial structures and associated infrastructure
                            can detract from the aesthetic appeal of the environment and impact property values in
                            nearby residential areas.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Pulp & Paper Mill Counts by City and State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PulpAndPaperMills;
