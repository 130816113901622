import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./OilWells.css";
import jsonData from "./OilWellCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function OilWells() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Oil Wells Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover oil wells within 1 mile of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Oil Wells Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> oil wells within a 1-mile radius</strong> of your property by leveraging the
                    power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into oil wells but also into 40 other critical environmental
                    factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics Release
                    Inventory sites to public drinking water systems, air and noise pollution sources, natural hazards,
                    and livability factors, EnviroHomePro provides comprehensive data to empower you to make informed
                    decisions about your surroundings. Take control of your environmental awareness today and safeguard
                    your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure a
                    healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Top 100 Counties by Oil Counts</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">What is an Oil Well</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            An oil well is a structure drilled into the Earth's surface to extract crude oil from
                            underground reservoirs. Oil wells are crucial components of the petroleum industry,
                            providing a means of accessing and harvesting this valuable energy resource.
                            <br />
                            <br />
                            The process of drilling and operating an oil well involves several key steps. It begins with
                            site preparation, where the drilling location is selected and cleared of any obstacles. Once
                            the site is ready, the drilling rig is brought in to commence drilling operations. Modern
                            drilling rigs utilize advanced technology and equipment to bore deep into the Earth's crust,
                            reaching depths that can range from a few hundred to several thousand feet.
                            <br />
                            <br />
                            As the drill penetrates the Earth's surface, it encounters various layers of rock and
                            sediment. To prevent the wellbore from collapsing and to control the flow of fluids, such as
                            groundwater and oil, steel casing pipes are inserted and cemented into place. This creates a
                            secure pathway for extracting oil while also protecting the surrounding environment.
                            <br />
                            <br />
                            Once the well reaches the target depth within the oil-bearing rock formation, specialized
                            equipment, such as perforating guns, may be used to stimulate the flow of oil into the
                            wellbore. This may involve techniques such as hydraulic fracturing (fracking) or acidizing,
                            which help to increase the permeability of the rock and enhance oil recovery.
                            <br />
                            <br />
                            Once oil begins to flow to the surface, it is collected and transported via pipelines or
                            tanker trucks to processing facilities known as refineries. At the refinery, the crude oil
                            undergoes various refining processes to separate it into different components, such as
                            gasoline, diesel, jet fuel, and lubricants, which are then further processed and distributed
                            for use in various applications.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Health Affects Associated with Living Near a Oil Well</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near an oil well poses health risks and exposure to air pollution, impacting nearby
                            communities. Oil extraction activities, including drilling, production, and transportation,
                            release various pollutants into the air, which can have adverse effects on human health.
                            <br />
                            <br />
                            One significant concern is the emission of volatile organic compounds (VOCs) during drilling
                            and production operations. VOCs such as benzene, toluene, and xylene are known to cause
                            respiratory irritation, headaches, nausea, and long-term exposure can lead to more severe
                            health effects including neurological disorders and cancer. According to a study published
                            in the journal Environmental Health Perspectives, exposure to emissions from oil extraction
                            activities has been associated with increased rates of respiratory symptoms and hospital
                            admissions among nearby populations [1].
                            <br />
                            <br />
                            Additionally, the release of particulate matter (PM) from oil drilling and production
                            operations is another concern. PM, especially fine particles PM2.5 and ultrafine particles,
                            can penetrate deep into the lungs and enter the bloodstream, causing respiratory and
                            cardiovascular health problems. Research published in Environmental Science & Technology
                            found that PM emissions from oil drilling operations contribute to regional air quality
                            degradation and pose health risks for nearby communities [2].
                            <br />
                            <br />
                            Furthermore, the release of air pollutants from oil production activities can
                            disproportionately affect vulnerable populations such as children, the elderly, and
                            individuals with pre-existing health conditions. Proximity to oil wells has been associated
                            with increased rates of asthma, respiratory issues, and adverse birth outcomes, as
                            demonstrated by various studies [3][4]. <br />
                            <br />
                            <br />
                            In conclusion, living near an oil well exposes individuals to air pollutants that can
                            adversely affect respiratory health, exacerbate existing conditions, and contribute to
                            long-term health problems. Continued research and regulation are essential to mitigate these
                            health risks and protect the well-being of communities near oil extraction sites.
                            <br />
                            <br />
                            [1] Source: Elliott, P., Shaddick, G., Douglass, M., de Hoogh, K., Briggs, D.J., Toledano,
                            M.B., 2001. Adult Cancers near High-Voltage Overhead Power Lines. Epidemiology 12, 583–584.
                            https://doi.org/10.1097/00001648-200109000-00024
                            <br />
                            <br />
                            [2] Source: Moore, C.W., Zielinska, B., Pétron, G., Jackson, R.B., 2014. Air Impacts of
                            Increased Natural Gas Acquisition, Processing, and Use: A Critical Review. Environ. Sci.
                            Technol. 48, 8349–8359. https://doi.org/10.1021/es4053472
                            <br />
                            <br />
                            [3] Source: McKenzie, L.M., Guo, R., Witter, R.Z., Savitz, D.A., Newman, L.S., Adgate, J.L.,
                            2014. Birth Outcomes and Maternal Residential Proximity to Natural Gas Development in Rural
                            Colorado. Environ Health Perspect 122, 412–417. https://doi.org/10.1289/ehp.1306722
                            <br />
                            <br />
                            [4] Source: Moore, C.W., Zielinska, B., Pétron, G., Jackson, R.B., 2014. Air Impacts of
                            Increased Natural Gas Acquisition, Processing, and Use: A Critical Review. Environ. Sci.
                            Technol. 48, 8349–8359. https://doi.org/10.1021/es4053472
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default OilWells;
