import './Loading.css'
import { Helmet } from "react-helmet";

const Loading = (props) => {
    return (
        <div className="loading-div" id="loading-div-h1">
                <Helmet>
                {props.title && <title>{props.title} | EnviroHomePro</title>}
            </Helmet>
            <div className="custom-loader"></div>{" "}
        </div>
    );
};

export default Loading