import { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, Link } from "react-router-dom";
// import "./NavBar.css";
// import Logo from "../../components/NavBar/logo.png";
import "./NavBar.css";

import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log("error signing out: ", error);
    }
}

const Navigation = () => {
    const { route } = useAuthenticator((context) => [context.route]);
    const [expanded, setExpanded] = useState(false);
    const navbarRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            let isNavbarClick = event.target.closest(".navbar") !== null;
            if (event.target.id.toString() == "responsive-navbar-nav") {
                isNavbarClick = false;
            }
            if (!isNavbarClick) {
                setExpanded(false); // Collapse the Navbar when anything outside the Navbar is clicked
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleNavClick = () => {
        setExpanded(false); // Collapse the Navbar when a link is clicked
    };

    const handleDropdownClick = (eventKey) => {
        eventKey.preventDefault(); // Prevent default dropdown behavior
        eventKey.stopPropagation(); // Stop event from propagating to parent elements
    };
    return (
        <Navbar ref={navbarRef} collapseOnSelect className="navbar-light" expand="lg" expanded={expanded}>
            <Container>
                <Navbar.Brand className="navbar-title" href={process.env.REACT_APP_BASE_PATH}></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse className="justify-content-end navbar-light" id="responsive-navbar-nav">
                    <Nav className="nav-bar" onClick={handleNavClick}>
                        <NavLink className="nav-link" to="/" onClick={handleNavClick}>
                            Home
                        </NavLink>
                        <NavLink className="nav-link" to="/blog">
                            Blog
                        </NavLink>
                        <NavDropdown title="Categories" id="nav-dropdown" onClick={handleDropdownClick}>
                            <NavDropdown.Item as={Link} to="/noise-pollution" onClick={handleNavClick}>
                                Noise Pollution
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/air-pollution" onClick={handleNavClick}>
                                Air Pollution
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/health-risks" onClick={handleNavClick}>
                                Health Risks
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/natural-hazards" onClick={handleNavClick}>
                                Natural Hazards
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/livability" onClick={handleNavClick}>
                                Livability
                            </NavDropdown.Item>
                        </NavDropdown>
                        {route === "authenticated" ? (
                            <>
                                <NavLink className="nav-link" to="/residential-environmental-search">
                                    New Report
                                </NavLink>
                                <NavLink className="nav-link" to="/dashboard">
                                    Account
                                </NavLink>
                                <button className="nav-link" onClick={signOut}>
                                    Sign Out
                                </button>{" "}
                            </>
                        ) : null}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
