import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";
import "./HomePage.css";
import PricingOptions from "../../components/PricingOptions/PricingOptions.js";
import { BiVolumeFull } from "react-icons/bi";
import { BiHealth } from "react-icons/bi";
import { MdSecurity } from "react-icons/md";
import { TbMoodAnnoyed, TbCloudStorm } from "react-icons/tb";
import { FaPlus } from "react-icons/fa";
import { FiWind } from "react-icons/fi";

function HomePage() {
    const navigate = useNavigate();

    const login = (event) => {
        let viewId = event.target.id == "login-button" ? "signIn" : "signUp";
        navigate("/login?view=" + viewId, {
            state: {
                loginState: event.target.id,
            },
        });
    };

    return (
        <div className="home-page-description">
            <Helmet>
                <meta
                    name="description"
                    content="EnviroHomePro delivers comprehensive property reports uncovering health risks & livabilty: air & noise pollution, health & safety hazards, and natural threats."
                />
                <title>Environmental Due Diligence | EnviroHomePro</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="hero-section">
                {/* <div className="imageOverlay"> */}
                <h1 className="home-page-h1">
                    {/* In-depth Analysis of Your Surrounding Area */}
                    Environmental Due Diligence
                    <br />
                    Explore Your Surroundings
                </h1>
                {/* <h2 className="home-page-h2">
                    Discover your property's true potential with our cutting-edge <b>EnviroHomePro</b> technology. Our
                    expert environmental and data engineers have meticulously gathered data from reputable sources,
                    including the EPA, Homeland Security, U.S. Energy Information Administration, and FEMA, to create
                    your comprehensive property score. Whether you're looking to purchase, invest, or build, our
                    in-depth analysis empowers you to make confident decisions. Unlock the possibilities today!
                </h2> */}
                <h2 className="home-page-h2">Discover environmental risks and benefits near your property</h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Get My Home Report
                    </a>
                    {/* <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Build My Environmental Home Report
                    </a> */}
                </div>
                <img className="home-page-hero-picture" src={require(`../../images/InteractiveMap.png`)} />{" "}
                <CookieConsent
                    cookieName="consentCookie"
                    buttonClasses="consent-button"
                    buttonWrapperClasses="button-wrapper"
                    containerClasses="consent-container"
                    contentClasses="consent-content">
                    <p
                    // style={{ fontSize: "10px" }}
                    >
                        This website uses essential cookies and analytics cookies to enhance your browsing experience
                        and analyze website traffic. By clicking "I understand" or continuing to use our website, you
                        agree to our use of cookies as described in this notice and in our Privacy Policy.
                    </p>
                </CookieConsent>
                {/* </div> */}
            </div>
            <div className="what-we-do">
                <h2 className="what-we-do-h2">Report Data</h2>
                <p className="what-we-do-p">
                    With over 30 years in the Environmental Engineering field, <b>EnviroHomePro</b> is bringing this
                    experience to the residential sector. Using one of the largest consolidated environmental databases
                    in the country, rest comfortable knowing you have the best information regarding your property.
                </p>
                <div className="what-we-do-cards">
                    <div className="what-we-do-card">
                        <BiHealth className="what-we-do-icon" />
                        <p className="what-we-do-icon-title">Health Risks</p>
                        <p className="what-we-do-icon-desc">
                            <li>
                                <a className="what-we-do-list active-page" href="/tap-water-database">
                                    Drinking Water Quality
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/epa-toxics-release-inventory">
                                    EPA Toxics Release Inventory
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/epa-superfund-sites-near-you">
                                    EPA Superfund Sites
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/brownfield-sites-near-you">
                                    EPA Brownfield Sites
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/nuclear-power-plants-near-you">
                                    Nuclear Power Plants
                                </a>
                            </li>
                            <li>
                                <a
                                    className="what-we-do-list active-page"
                                    href="/asbestos-mines-and-occurences-near-you">
                                    Asbestos Mines and Occurences
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/wastewater-treatment-plants-near-you">
                                    Wastewater Treatment Plants
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/formerly-used-defense-sites-near-you">
                                    Formerly Used Defense Sites
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/cellular-towers-near-you">
                                    Cell Towers
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/transmission-lines-near-you">
                                    Transmission Lines (EMF)
                                </a>
                            </li>
                        </p>
                    </div>
                    <div className="what-we-do-card">
                        <BiVolumeFull className="what-we-do-icon" />
                        <p className="what-we-do-icon-title">Noise Pollution</p>
                        <p className="what-we-do-icon-desc">
                            <li>
                                <a className="what-we-do-list active-page" href="/airports-near-you">
                                    Airports
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/trains-near-you">
                                    Train Lines
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/highways-near-you">
                                    Major Highways
                                </a>
                            </li>
                        </p>
                    </div>
                    <div className="what-we-do-card">
                        <FiWind className="what-we-do-icon" />
                        <p className="what-we-do-icon-title">Air Pollution</p>
                        <p className="what-we-do-icon-desc">
                            <li>
                                <a className="what-we-do-list active-page" href="/landfills-near-you">
                                    Landfills
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/incinerators-near-you">
                                    Incinerators
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/power-plants-near-you">
                                    Power Plants
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/refineries-near-you">
                                    Refineries
                                </a>
                            </li>
                            <li>
                                {" "}
                                <a className="what-we-do-list active-page" href="/natural-gas-wells-near-you">
                                    Natural Gas Wells
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/oil-wells-near-you">
                                    Oil Wells
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/pulp-and-paper-mills-near-you">
                                    Pulp and Paper Mills
                                </a>
                            </li>
                        </p>
                    </div>

                    <div className="what-we-do-card">
                        <FaPlus className="what-we-do-icon" />
                        <p className="what-we-do-icon-title">Livability</p>
                        <p className="what-we-do-icon-desc">
                            <li>
                                <a className="what-we-do-list active-page" href="/parks-near-you">
                                    National, State, and Local Parks
                                </a>
                            </li>

                            <li>
                                <a className="what-we-do-list active-page" href="/hospitals-near-you">
                                    Hospitals
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/police-stations-near-you">
                                    Police Stations
                                </a>
                            </li>

                            <li>
                                <a className="what-we-do-list active-page" href="/emergency-medical-services-near-you">
                                    Emergency Medical Service Stations
                                </a>
                            </li>
                            <li>
                                <a className="what-we-do-list active-page" href="/fire-stations-near-you">
                                    Fire Stations
                                </a>
                            </li>
                        </p>
                    </div>

                    <div className="what-we-do-card">
                        <TbCloudStorm className="what-we-do-icon" />
                        <p className="what-we-do-icon-title">Natural Hazards</p>
                        <p className="what-we-do-icon-desc">
                            <li>
                                <a className="what-we-do-list">Earthquakes</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Wild Fires</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Lightning</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">High Speed Wind</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Hurricanes</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Coastal Flooding</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Cold Waves</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Droughts</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Heat Waves</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Ice Storms</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Tornados</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Tsunamis</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Volcanic Activity</a>
                            </li>
                            <li>
                                <a className="what-we-do-list">Winter Weather</a>
                            </li>
                        </p>
                    </div>
                </div>
            </div>
            <div className="picture-section-wrapper">
                <h2 className="picture-section-h2">Features</h2>
                <div className="picture-section">
                    <div className="picture-item">
                        <img className="home-page-picture" src={require(`../../images/InteractiveMap.png`)} />{" "}
                        <div className="overlay">
                            <h3>Interactive Maps</h3>
                        </div>
                    </div>

                    <div className="picture-item">
                        <img className="home-page-picture" src={require(`../../images/OnlineReports.png`)} />{" "}
                        <div className="overlay">
                            <h3>Online Reports</h3>
                        </div>
                    </div>

                    <div className="picture-item">
                        <img className="home-page-picture" src={require(`../../images/PDFReports.png`)} />{" "}
                        <div className="overlay">
                            <h3>PDF Downloads</h3>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="whoNeeds">
                <h2>
                    Who needs <b className="envirohomepro-logo"></b>?
                </h2>
                <p className="whoNeedsDescription">
                    Environmental issues as well as health and safety concerns are present in any neighborhood. These
                    issues can impact the health and safety of our families as well as deflate the value of our homes
                    and real estate investments.
                </p>
                <div className="whoNeedsDivs">
                    <div className="whoNeeds1stDiv">
                        <h3 className="whoNeeds1stHeader">Home Buyers and Owners</h3>
                        <li className="whoNeeds1stText">
                            <b>Health and Well-being:</b> The surrounding environment can significantly impact
                            residents' health and well-being. Awareness of potential hazards, such as air pollution,
                            water quality, or proximity to industrial sites, can help buyers make informed decisions to
                            protect their family's health.
                        </li>
                        <li className="whoNeeds1stText">
                            <b>Property Value:</b> Knowing about environmental concerns and safety concerns is vital for
                            safeguarding the long-term value of the property. Homes located in areas with a history of
                            environmental contamination or frequent safety issues may see a depreciation in value over
                            time, potentially leading to financial losses for the homeowner.
                        </li>

                        <li className="whoNeeds1stText">
                            <b>Future Maintenance Costs:</b> Understanding environmental issues and safety issues can
                            help buyers estimate future maintenance and repair costs. For instance, if a property is
                            prone to flooding or wildfire risks, buyers can prepare for necessary mitigation measures or
                            budget for future expenses.
                        </li>

                        <li className="whoNeeds1stText">
                            <b>Legal and Regulatory Compliance:</b> Environmental and safety issues can have legal
                            implications. Being aware of any regulatory restrictions or requirements related to the
                            property can save buyers from potential legal troubles.
                        </li>

                        <li className="whoNeeds1stText">
                            <b>Resale Potential:</b> Knowledge of these issues can affect a property's resale potential.
                            Buyers who are well-informed can disclose any concerns to future buyers, ensuring
                            transparent and ethical transactions.
                        </li>

                        <li className="whoNeeds1stText">
                            <b>Lifestyle Considerations:</b> Environmental factors, such as noise pollution or proximity
                            to parks and green spaces, can significantly impact one's quality of life. Buyers should
                            consider these factors to ensure the property aligns with their desired lifestyle.
                        </li>

                        <li className="whoNeeds1stText">
                            <b>Insurance Costs:</b> Safety concerns in the area can affect insurance premiums. Buyers
                            who are aware of safety issues can obtain accurate insurance quotes and budget accordingly.
                        </li>
                        <li className="whoNeeds1stText">
                            <b>Peace of Mind:</b> Having a clear understanding of environmental and safety conditions in
                            the area provides peace of mind, as it helps buyers make a confident and informed decision
                            about one of the most significant investments in their lives.
                        </li>
                    </div>
                    <div className="whoNeeds2ndDiv">
                        <h3 className="whoNeeds2ndHeader">Real Estate Professionals</h3>
                        <li className="whoNeeds2ndText">
                            <b>Legal and Regulatory Compliance:</b> Understanding environmental and safety issues helps
                            real estate professionals, investors, and agents ensure that the properties they deal with
                            comply with relevant laws and regulations, preventing potential legal issues.
                        </li>

                        <li className="whoNeeds2ndText">
                            <b>Property Value:</b> Knowledge of environmental and safety concerns enables these
                            stakeholders to assess the potential risks that could affect property values. This
                            information allows for informed decisions and investment strategies that protect and enhance
                            property values over time.
                        </li>

                        <li className="whoNeeds2ndText">
                            <b>Risk Assessment:</b> Real estate professionals, investors, and agents can conduct
                            thorough risk assessments, taking into account environmental and safety factors. This is
                            crucial for identifying potential liabilities and minimizing risks in property transactions.
                        </li>

                        <li className="whoNeeds2ndText">
                            <b>Market Insights:</b> Awareness of environmental and safety issues in a specific area
                            provides valuable market insights. Real estate professionals and investors can make
                            data-driven decisions and capitalize on emerging trends in the market.
                        </li>

                        <li className="whoNeeds2ndText">
                            <b>Financial Planning:</b> Understanding the impact of environmental and safety issues on a
                            property allows for more accurate financial planning, including budgeting for potential
                            remediation, insurance, and maintenance costs.
                        </li>

                        <li className="whoNeeds2ndText">
                            <b>Client Trust:</b> Real estate professionals and agents who are well-informed about these
                            issues build trust with clients by providing comprehensive information and ensuring a smooth
                            and transparent real estate experience.
                        </li>

                        <li className="whoNeeds2ndText">
                            <b>Legal Protection:</b> Knowledge of environmental concerns and safety concerns offers
                            legal protection to real estate professionals and agents, helping them avoid potential
                            disputes and liabilities in real estate transactions.
                        </li>

                        <li className="whoNeeds2ndText">
                            <b>Investment Portfolio Diversification:</b> Investors can use information about
                            environmental issues and safety issues to diversify their investment portfolios
                            strategically, considering properties in various locations and assessing potential risks.
                        </li>
                    </div>
                </div> */}
            {/* <div className="whoNeedsSummary">
                    <p>
                        The <b>EnviroHomePro</b> database aggregates information collected over the past 25 years and
                        includes data from hundreds of state and federal governmental agencies including the EPA, CDC,
                        NRC, DHS, DOD, DOE, and State and local police departments. We also collect information
                        concerning earthquake fault line maps, USEPA Superfund sites, hazardous waste treatment
                        facilities, nuclear power, plants, environmental issues, and air pollution issues. Our database
                        gives you the information you need to make informed decisions on where to buy, build, or
                        develop, and about the health and safety of your family, tenants, or employees.
                    </p>

                    <p>
                        <b>EnviroHomePro's</b> database and program will provide you with the information you need about
                        your home environment, neighborhood, and local area that may impact your health, home value, or
                        present a safety risk to you and your family.
                    </p>
                </div>
            </div> */}

            <div className="pricing-section">
                <h2 className="pricing-h2">PRICING</h2>
                <PricingOptions page="HomePage" />
            </div>
        </div>
    );
}

export default HomePage;
