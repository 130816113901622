import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./FormerDefenseSites.css";
import jsonData from "./FormerDefenseSiteCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";
// import mapImage from "../../images/BrownfieldSiteMap.png";

function FormerDefenseSites() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Formerly Used Defense Sites Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover formerly used defense sites within 1.5 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">
                    <strong>Find Formerly Used Defense Sites Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> formerly used defense sites within a 1.5 mile radius</strong> of your property
                    by leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into natural gas wells but also into 40 other critical
                    environmental factors impacting your home and well-being. From EPA Superfund, Brownfield, and Toxics
                    Release Inventory sites to public drinking water systems, air and noise pollution sources, natural
                    hazards, and livability factors, EnviroHomePro provides comprehensive data to empower you to make
                    informed decisions about your surroundings. Take control of your environmental awareness today and
                    safeguard your health and home with EnviroHomePro's comprehensive reporting. Explore now and ensure
                    a healthier, safer environment for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            {/* <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src={mapImage}
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div> */}
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Formerly Used Defense Site Counts by County, State & Remediation
                    </h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">What is a Formerly Used Defense Site</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A formerly used defense site (FUDS) refers to any location that was previously owned,
                            leased, possessed, or used by the United States military and is no longer under active
                            military control due to closure, realignment, or other reasons. These sites can vary widely
                            in size, purpose, and condition, ranging from small ammunition storage areas to sprawling
                            military bases. Once military activities cease, FUDS undergo a process of assessment,
                            cleanup, and potential reuse, overseen by various federal agencies such as the U.S. Army
                            Corps of Engineers (USACE) and the Environmental Protection Agency (EPA).
                            <br />
                            <br />
                            The legacy of military activities on FUDS often leaves behind environmental contamination,
                            including hazardous substances such as unexploded ordnance, chemical agents, and heavy
                            metals. Cleaning up these sites is not only crucial for environmental conservation but also
                            for public health and safety. The process typically involves extensive investigations to
                            identify and prioritize risks, followed by remediation efforts to mitigate contamination and
                            restore the land to safe and suitable conditions for future use.
                            <br />
                            <br />
                            Once remediation is complete, FUDS can be repurposed for a variety of purposes, including
                            recreational areas, wildlife refuges, commercial developments, or returned to natural
                            habitats. However, reuse plans must consider the site's history, environmental conditions,
                            and potential residual risks to ensure the safety and suitability of any new activities.
                            <br />
                            <br />
                            Community involvement is often a vital aspect of the FUDS cleanup and reuse process, as
                            local stakeholders may have valuable insights into the site's history, environmental
                            concerns, and desired future uses. Public input can help shape remediation strategies and
                            reuse plans that align with the needs and preferences of surrounding communities.
                            <br />
                            <br />
                            In summary, formerly used defense sites represent a significant challenge and opportunity
                            for land management and environmental stewardship. Through careful assessment, cleanup, and
                            collaboration with stakeholders, these sites can be transformed from symbols of military
                            activity to valuable assets that contribute to the well-being and prosperity of communities.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        Health Risks Associated with Living Near a Formerly Used Defense Site
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a Formerly Used Defense Site (FUDS) poses various health and safety risks due to
                            potential environmental contamination leftover from past military activities. These risks
                            can stem from a range of hazardous substances such as unexploded ordnance (UXO), heavy
                            metals, volatile organic compounds (VOCs), and radioactive materials. Understanding and
                            mitigating these risks is crucial for safeguarding the health and well-being of nearby
                            residents.
                            <br />
                            <br />
                            One of the primary concerns associated with FUDS is the presence of UXO, which includes
                            bombs, grenades, and other munitions that failed to detonate during training or conflicts.
                            These explosives can pose immediate threats if accidentally disturbed, leading to explosions
                            and injuries. Additionally, the chemical compounds contained within UXO can leach into soil
                            and water, contaminating the surrounding environment and potentially exposing residents to
                            toxins.
                            <br />
                            <br />
                            Moreover, FUDS often have a history of industrial activities such as weapon manufacturing,
                            maintenance, and disposal, which can result in the release of hazardous substances into the
                            soil, groundwater, and air. Exposure to these contaminants through inhalation, ingestion, or
                            skin contact can lead to various health problems, including respiratory issues, neurological
                            disorders, cancer, and reproductive harm.
                            <br />
                            <br />
                            Furthermore, the cleanup and remediation process of FUDS can also introduce temporary health
                            risks to nearby residents. Activities such as excavation, soil sampling, and debris removal
                            may release contaminants into the air or disturb UXO, increasing the potential for exposure.
                            Proper safety measures and community outreach are essential during remediation efforts to
                            minimize these risks and ensure the protection of residents.
                            <br />
                            <br />
                            In summary, living near a FUDS carries inherent health and safety risks due to the presence
                            of environmental contaminants and unexploded ordnance. Effective risk management strategies,
                            including thorough site assessments, remediation efforts, and community engagement, are
                            necessary to mitigate these risks and ensure the well-being of individuals residing in
                            proximity to these sites.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default FormerDefenseSites;
