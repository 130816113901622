import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Brownfield.css";
import jsonData from "./BrownfieldCounts.json";
import SEOTable from "../../components/SEOTable/SEOTable.js";

function Brownfield() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Brownfield Sites Near You | EnviroHomePro</title>
                <meta
                    name="description"
                    content="Discover Brownfield sites within 2 miles of your home using EnviroHomePro. Our property report covers 40+ environmental factors that can affect your home and health."
                />
            </Helmet>
            <div className="info-hero-section">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="info-page-h1">
                    <strong>Find Brownfield Sites Near You and Learn the Risks</strong>
                </h1>
                <h2 className="content-page-pitch">
                    Discover all <strong> Brownfield sites within a 5-mile radius</strong> of your property by
                    leveraging the power of{" "}
                    <a className="category-cta-link" href="/">
                        EnviroHomePro
                    </a>
                    . Gain invaluable insights not only into Brownfield sites but also into 40 other critical
                    environmental factors impacting your home and well-being. From Brownfield sites to public drinking
                    water systems, air and noise pollution sources, natural hazards, and livability factors,
                    EnviroHomePro provides comprehensive data to empower you to make informed decisions about your
                    surroundings. Take control of your environmental awareness today and safeguard your health and home
                    with EnviroHomePro's comprehensive reporting. Explore now and ensure a healthier, safer environment
                    for you and your family.
                </h2>
                <div className="cta-div">
                    <a className="button" id="create-account-button" onClick={(e) => login(e)}>
                        Create Account
                    </a>
                    <a className="button" id="login-button" onClick={(e) => login(e)}>
                        Log In
                    </a>
                </div>
            </div>
            <div className="info-div">
                <div className="map-image-div">
                    <h2 className="info-section-h2">Brownfield Site Map</h2>
                    <img
                        title="EnviroHomePro's Brownfield Site Map"
                        className="map-image"
                        src="https://envirohomepro-media.s3.amazonaws.com/BrownfieldSiteMap.png"
                        alt="Brownfield site map by state"
                    />
                    <p className="attribute-text">© Mapbox, © OpenStreetMap</p>
                </div>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">
                        What is a{" "}
                        <a className="category-header-link" href="https://www.epa.gov/Brownfield" target="_blank">
                            Brownfield Site
                        </a>
                        ?
                    </h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            A Brownfield site refers to a piece of land that was previously used for industrial or
                            commercial purposes and is now abandoned, idle, or underutilized, often due to real or
                            perceived contamination. These sites can be found in urban, suburban, and rural areas, and
                            they present significant challenges and opportunities for redevelopment.
                            <br />
                            <br />
                            The term "Brownfield" originated from the United States' Comprehensive Environmental
                            Response, Compensation, and Liability Act (CERCLA), where it was used to distinguish these
                            sites from unspoiled or "greenfield" areas. Brownfields typically have a history of
                            industrial activities such as manufacturing, mining, or waste disposal, which may have left
                            behind hazardous substances like heavy metals, petroleum products, or chemical pollutants in
                            the soil or groundwater.
                            <br />
                            <br />
                            Redeveloping Brownfield sites is crucial for sustainable urban development, as it promotes
                            the reuse of existing infrastructure, reduces urban sprawl, and revitalizes blighted
                            neighborhoods. However, redeveloping Brownfields can be complex and costly due to the need
                            for environmental assessment, cleanup, and regulatory compliance.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">How Does a Property Become Listed?</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Identifying a Brownfield site involves a systematic process of assessment and investigation
                            to determine its history of land use and potential contamination. Several methods and
                            sources of information are typically utilized in this process.
                            <br />
                            <br />
                            Firstly, historical records, including property deeds, land use permits, aerial photographs,
                            and local archives, are examined to trace the site's previous uses and activities. This
                            helps identify industries or activities that may have contributed to contamination.
                            <br />
                            <br />
                            Site visits and visual inspections are then conducted to assess the current condition of the
                            property and identify any visible signs of contamination, such as abandoned infrastructure,
                            chemical storage tanks, or discolored soil.
                            <br />
                            <br />
                            Environmental assessments, including soil and groundwater testing, are often performed to
                            evaluate the extent and severity of contamination on the site. These assessments may involve
                            collecting samples for laboratory analysis to detect the presence of hazardous substances
                            like heavy metals, petroleum hydrocarbons, or volatile organic compounds.
                            <br />
                            <br />
                            Additionally, stakeholders, including government agencies, property owners, and community
                            members, may provide valuable information and insights into the site's history and potential
                            environmental concerns.
                            <br />
                            <br />
                            By combining these methods and sources of information, environmental professionals can
                            effectively identify Brownfield sites and prioritize them for further investigation and
                            remediation efforts.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Remediation of Brownfield Sites</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Remediating Brownfield sites involves a multi-faceted approach aimed at cleaning up
                            contamination and restoring the land to a safe and suitable condition for redevelopment or
                            reuse. The remediation process typically includes several key steps.
                            <br />
                            <br />
                            Firstly, a comprehensive site assessment is conducted to identify the nature and extent of
                            contamination. This involves collecting soil, groundwater, and air samples for laboratory
                            analysis to determine the types and concentrations of hazardous substances present.
                            <br />
                            <br />
                            Based on the assessment findings, remediation strategies are developed to address the
                            contamination effectively. Common remediation techniques include excavation and removal of
                            contaminated soil, groundwater treatment using methods such as pump and treat systems or
                            in-situ remediation technologies, and containment measures such as capping or barrier
                            installation to prevent further spread of contamination.
                            <br />
                            <br />
                            Throughout the remediation process, strict regulatory compliance is maintained to ensure
                            that cleanup activities meet environmental standards and protect human health and the
                            surrounding ecosystem.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Health Risks</h2>
                    <div className="info-block">
                        <div className="info-section-text">
                            <br />
                            Living near a Brownfield site poses various health risks due to potential exposure to
                            contaminants present in the soil, groundwater, and air. These risks can arise from direct
                            contact with contaminated soil, ingestion of contaminated groundwater, inhalation of
                            airborne pollutants, or consumption of food grown in contaminated soil. The health effects
                            of exposure to Brownfield contaminants depend on factors such as the type of contaminants,
                            their concentration, duration of exposure, and individual susceptibility.
                            <br />
                            <br />
                            One significant health risk is the potential for exposure to carcinogenic substances such as
                            heavy metals (e.g., lead, arsenic), volatile organic compounds (VOCs), polycyclic aromatic
                            hydrocarbons (PAHs), and industrial chemicals (e.g., benzene, toluene). Chronic exposure to
                            these contaminants through inhalation or ingestion can increase the risk of developing
                            cancer, respiratory diseases, neurological disorders, and other serious health conditions.
                            <br />
                            <br />
                            Children and pregnant women are particularly vulnerable to the health effects of Brownfield
                            contamination due to their developing bodies and heightened sensitivity to environmental
                            toxins. Exposure to lead, for example, can impair children's cognitive development and lead
                            to learning disabilities, behavioral problems, and reduced IQ.
                            <br />
                            <br />
                            Furthermore, contaminated groundwater from Brownfield sites can contaminate drinking water
                            sources, posing a risk of acute and chronic health effects such as gastrointestinal
                            illnesses, liver and kidney damage, and reproductive disorders.
                            <br />
                            <br />
                            Additionally, Brownfield sites may emit toxic vapors or dust containing hazardous substances
                            into the air, leading to respiratory problems, asthma exacerbation, and cardiovascular
                            diseases among nearby residents.
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="">
                    <h2 className="info-section-h2 left-h2">Brownfield Site Counts by State</h2>
                    <div className="info-block">
                        <SEOTable header="" data={jsonData} />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Brownfield;
