import React, { useState, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { IoAirplaneOutline, IoTrainOutline, IoCarSportOutline, IoRestaurantOutline } from "react-icons/io5";
import { TbMicrophone2 } from "react-icons/tb";
import {
    GiWaterBottle,
    GiDrippingTube,
    GiNuclearPlant,
    GiWaterRecycling,
    GiMilitaryFort,
    GiMagnet,
    GiFuelTank,
    GiHazardSign,
} from "react-icons/gi";
import { MdMasks, MdOutlineCellTower } from "react-icons/md";

function HealthRisks() {
    const navigate = useNavigate();

    const login = (event) => {
        // let viewId = event.target.attributes.id.value;
        navigate("/residential-environmental-search", {
            state: {},
        });
    };

    return (
        <div className="info-page">
            <Helmet>
                <title>Health & Safety Risks: Sources & Impact on Health & Home</title>
                <meta
                    name="description"
                    content="Navigate health and safety risks wisely with EnviroHomePro. Discover optimal locations for informed living decisions away from potential hazards."
                />
            </Helmet>
            <div className="info-hero-section">
                <h1 className="info-page-h1">Health & Safety Risks: Sources & Impact</h1>
                <h2 className="info-page-pitch">
                    Choosing a residence with a strong emphasis on health and safety involves considering the overall
                    quality of drinking water and awareness of potential environmental factors. Communities with robust
                    regulations ensure clean drinking water, reducing health risks. Understanding information from the
                    EPA, including Toxics Release Inventory, Superfund Sites, and Brownfields, aids informed decisions
                    about environmental hazards. Awareness of nuclear power plants, asbestos mines, wastewater treatment
                    plants, former defense sites, cell towers, and transmission lines is crucial for evaluating
                    potential impacts on health and safety in a chosen living environment. Overall, prioritizing health
                    and safety involves being mindful of various environmental factors for a secure and healthy
                    lifestyle.
                </h2>
            </div>
            <div className="info-div">
                <section className="info-section" id="public-drinking-water-systems">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/tap-water-database">
                            Drinking Water Quality <GiWaterBottle className="info-icon" />
                        </a>
                    </h2>

                    <div className="info-block">
                        <div className="info-section-text">
                            Public drinking water systems are essential for delivering safe and clean water to
                            communities, ensuring access to a fundamental resource crucial for human health and
                            well-being. The meticulous management of these systems is vital for identifying and
                            mitigating contaminants, safeguarding residents from potential health risks associated with
                            waterborne diseases and pollutants. The quality of water directly influences community
                            health, serving as a foundation for preventing illnesses and supporting overall well-being.
                            Moreover, public water systems contribute to environmental protection by preserving water
                            sources, safeguarding aquatic ecosystems, and promoting sustainability. Uninterrupted access
                            to clean water enhances the quality of life, supporting daily activities and sanitation
                            while fostering a healthier and more resilient population.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Safe Drinking Water:</b> Public drinking water systems are crucial for providing safe
                                and clean water to communities, ensuring access to a vital resource that is essential
                                for human health, hygiene, and overall well-being.
                            </li>

                            <li className="info-list-item">
                                <b>Contaminant Mitigation:</b> Maintaining the quality of drinking water is paramount,
                                as public water systems implement rigorous measures to identify and mitigate
                                contaminants, safeguarding residents from potential health risks associated with
                                waterborne diseases and pollutants.
                            </li>

                            <li className="info-list-item">
                                <b>Community Health:</b> The quality of water directly impacts community health, with a
                                reliable public drinking water system serving as a cornerstone for preventing waterborne
                                illnesses and supporting the overall health and longevity of residents.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Protection:</b> Public water systems play a vital role in environmental
                                conservation by ensuring that water sources are protected from pollution, preserving
                                aquatic ecosystems, and contributing to overall environmental sustainability.
                            </li>

                            <li className="info-list-item">
                                <b>Quality of Life:</b> Uninterrupted access to clean water enhances the quality of life
                                for communities, supporting daily activities, sanitation, and fostering a healthier and
                                more resilient population.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="epa-toxics-release-inventory">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/epa-toxics-release-inventory">
                            EPA Toxics Release Inventory <GiDrippingTube className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Transparency and Accountability:</b> The EPA Toxics Release Inventory (TRI) fosters
                                transparency by providing accessible information on the release and management of toxic
                                chemicals, empowering communities to hold industries accountable for their environmental
                                impact.
                            </li>

                            <li className="info-list-item">
                                <b>Community Awareness:</b> The TRI enhances community awareness by offering detailed
                                data on the use, release, and transfer of toxic chemicals, enabling residents to make
                                informed decisions about their environment and advocate for improved environmental
                                practices.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Protection:</b> By requiring industries to report their toxic releases,
                                the TRI contributes to environmental protection efforts, promoting the reduction of
                                hazardous substances and encouraging the adoption of cleaner production practices.
                            </li>

                            <li className="info-list-item">
                                <b>Public Health Safeguards:</b> The information provided by the TRI supports public
                                health safeguards, allowing authorities and communities to assess potential risks,
                                implement preventive measures, and address environmental concerns related to toxic
                                chemical exposure.
                            </li>

                            <li className="info-list-item">
                                <b>Encouraging Industry Accountability:</b> The TRI serves as a tool to encourage
                                industry accountability, fostering a culture of responsible chemical management and
                                motivating businesses to adopt more sustainable and environmentally friendly practices.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            The EPA Toxics Release Inventory (TRI) plays a crucial role in promoting transparency and
                            accountability by providing accessible information on the release and management of toxic
                            chemicals. This transparency empowers communities to hold industries accountable for their
                            environmental impact. The TRI enhances community awareness by offering detailed data on the
                            use, release, and transfer of toxic chemicals, enabling residents to make informed decisions
                            and advocate for improved environmental practices. Furthermore, the TRI contributes to
                            environmental protection by encouraging the reduction of hazardous substances and promoting
                            cleaner production practices. The information provided by the TRI supports public health
                            safeguards, allowing authorities and communities to assess potential risks and address
                            environmental concerns related to toxic chemical exposure. Overall, the TRI serves as a tool
                            to encourage industry accountability, fostering a culture of responsible chemical management
                            and motivating businesses to adopt more sustainable and environmentally friendly practices.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="epa-superfund-sites">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/epa-superfund-sites-near-you">
                            EPA Superfund Sites <GiHazardSign className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            EPA Superfund sites represent areas requiring extensive environmental remediation due to
                            hazardous waste contamination. The Superfund program aims to restore these locations to safe
                            and sustainable conditions. These sites pose potential risks to public health and the
                            environment, prompting the EPA to intervene and protect communities through assessment,
                            cleanup, and ongoing monitoring. The Superfund program provides a legal framework for
                            holding responsible parties accountable for contamination, ensuring financial responsibility
                            for cleanup efforts. Collaboration with local communities and stakeholders is a key aspect,
                            encouraging public participation in decision-making processes to address concerns and
                            incorporate community input. Following cleanup, Superfund sites undergo long-term monitoring
                            to assess the effectiveness of remediation efforts and ensure sustained mitigation of
                            environmental and health risks.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Environmental Remediation:</b> EPA Superfund sites represent areas requiring
                                extensive environmental remediation due to hazardous waste contamination, aiming to
                                restore these locations to safe and sustainable conditions.
                            </li>

                            <li className="info-list-item">
                                <b>Community Protection:</b> Superfund sites pose potential risks to public health and
                                the environment, and the EPA intervenes to protect communities by assessing, cleaning
                                up, and monitoring these areas to prevent further harm.
                            </li>

                            <li className="info-list-item">
                                <b>Legal Enforcement:</b> The Superfund program provides a legal framework for holding
                                responsible parties accountable for the contamination, ensuring that those who
                                contribute to environmental damage bear the financial responsibility for cleanup
                                efforts.
                            </li>

                            <li className="info-list-item">
                                <b>Stakeholder Involvement:</b> EPA Superfund sites often involve collaboration with
                                local communities and stakeholders, encouraging public participation in decision-making
                                processes to address concerns and incorporate community input.
                            </li>

                            <li className="info-list-item">
                                <b>Long-Term Monitoring:</b> Following cleanup, Superfund sites undergo long-term
                                monitoring to assess the effectiveness of remediation efforts and ensure that
                                environmental and health risks continue to be mitigated over time.
                            </li>
                        </div>
                    </div>
                </section>
            </div>
            <div className="info-div gray-background">
                <section className="info-section" id="epa-brownfields">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/brownfield-sites-near-you">
                            EPA Brownfield Sites <GiFuelTank className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Site Redevelopment:</b> EPA Brownfield programs focus on the redevelopment of
                                contaminated sites, turning underutilized or abandoned properties into valuable
                                community assets, promoting sustainable land use and revitalization.
                            </li>

                            <li className="info-list-item">
                                <b>Community and Economic Benefits:</b> Brownfield redevelopment enhances local
                                communities by generating economic benefits, creating jobs, and fostering community
                                engagement, contributing to the overall improvement of neighborhoods and urban areas.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Stewardship:</b> Brownfield initiatives emphasize environmental
                                stewardship by facilitating the cleanup and reuse of contaminated sites, preventing
                                further environmental degradation and promoting the responsible management of resources.
                            </li>

                            <li className="info-list-item">
                                <b>Community Engagement:</b> EPA Brownfield programs encourage community involvement in
                                the decision-making process, ensuring that local residents have a voice in the
                                redevelopment and revitalization of their neighborhoods.
                            </li>

                            <li className="info-list-item">
                                <b>Public-Private Partnerships:</b> Brownfield redevelopment often involves
                                public-private partnerships, leveraging resources and expertise from both sectors to
                                efficiently address environmental challenges and promote sustainable development.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            EPA Brownfield programs focus on the redevelopment of contaminated sites, transforming
                            underutilized or abandoned properties into valuable community assets. This approach promotes
                            sustainable land use and revitalization, contributing to the overall improvement of
                            neighborhoods and urban areas. Brownfield redevelopment enhances local communities by
                            generating economic benefits, creating jobs, and fostering community engagement. The
                            initiatives emphasize environmental stewardship by facilitating the cleanup and reuse of
                            contaminated sites, preventing further environmental degradation, and promoting responsible
                            resource management. Community involvement is a key aspect of Brownfield programs, ensuring
                            that local residents have a voice in the decision-making process related to the
                            redevelopment and revitalization of their neighborhoods. Additionally, Brownfield
                            redevelopment often involves public-private partnerships, leveraging resources and expertise
                            from both sectors to efficiently address environmental challenges and promote sustainable
                            development.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="nuclear-power-plants">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/nuclear-power-plants-near-you">
                            Nuclear Power Plants <GiNuclearPlant className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Nuclear power plants contribute to low greenhouse gas emissions, providing a cleaner energy
                            alternative in the global effort to combat climate change. They offer a reliable source of
                            base-load power, ensuring a stable electricity supply to meet constant demand. With high
                            energy density, nuclear energy is an efficient option for power generation, producing a
                            large amount of electricity from a small amount of nuclear fuel. Nuclear power also helps
                            reduce dependence on fossil fuels, offering an alternative that mitigates environmental
                            impacts associated with traditional energy sources. However, nuclear power comes with
                            challenges and safety concerns, including the management of radioactive waste, the potential
                            for accidents, and public perception regarding the safety of nuclear energy.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Low Greenhouse Gas Emissions:</b> Nuclear power plants contribute to low greenhouse
                                gas emissions during electricity generation, providing a cleaner energy alternative and
                                aiding in global efforts to combat climate change.
                            </li>

                            <li className="info-list-item">
                                <b>Base-load Power:</b> Nuclear power plants offer a reliable source of base-load power,
                                providing a consistent and stable supply of electricity to the grid, helping meet the
                                constant demand for energy.
                            </li>

                            <li className="info-list-item">
                                <b>High Energy Density:</b> Nuclear energy has a high energy density, meaning that a
                                small amount of nuclear fuel can produce a large amount of electricity, making it an
                                efficient option for power generation.
                            </li>

                            <li className="info-list-item">
                                <b>Reduced Dependence on Fossil Fuels:</b> Nuclear power helps reduce dependence on
                                fossil fuels, offering an alternative to traditional energy sources and mitigating the
                                environmental impact associated with the extraction and burning of fossil fuels.
                            </li>

                            <li className="info-list-item">
                                <b>Challenges and Safety Concerns:</b> Despite its benefits, nuclear power comes with
                                challenges and safety concerns, including the management of radioactive waste, the
                                potential for accidents, and public perception regarding the safety of nuclear energy.
                            </li>
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="asbestos-mines-and-occurences">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/asbestos-mines-and-occurences-near-you">
                            Asbestos Mines and Occurences <MdMasks className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Health Risks:</b> Asbestos mines and occurrences pose severe health risks, as
                                exposure to asbestos fibers can lead to serious respiratory diseases, including lung
                                cancer, asbestosis, and mesothelioma.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Contamination:</b> Asbestos mining activities can result in
                                environmental contamination, releasing asbestos fibers into the air, water, and soil,
                                affecting ecosystems and potentially harming wildlife.
                            </li>

                            <li className="info-list-item">
                                <b>Occupational Hazards:</b> Workers in asbestos mines face significant occupational
                                hazards due to exposure to asbestos fibers, necessitating strict safety measures to
                                minimize the risk of respiratory illnesses and other health issues.
                            </li>

                            <li className="info-list-item">
                                <b>Global Ban Efforts:</b> Due to the recognized health hazards, there have been global
                                efforts to ban or regulate asbestos use, with many countries phasing out its production
                                and consumption to protect public health and the environment.
                            </li>

                            <li className="info-list-item">
                                <b>Legacy Impact:</b> Despite regulatory measures, past asbestos mining activities have
                                left a legacy of environmental contamination and ongoing health concerns, emphasizing
                                the importance of continued monitoring and remediation efforts.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Asbestos mines and occurrences present significant health risks, as exposure to asbestos
                            fibers can lead to severe respiratory diseases, including lung cancer, asbestosis, and
                            mesothelioma. These activities can result in environmental contamination, releasing asbestos
                            fibers into the air, water, and soil, potentially harming ecosystems and wildlife. Workers
                            in asbestos mines face occupational hazards, necessitating strict safety measures to
                            minimize the risk of respiratory illnesses and other health issues. Recognizing the dangers,
                            global efforts have been made to ban or regulate asbestos use, with many countries phasing
                            out production and consumption to protect public health and the environment. Despite
                            regulatory measures, past asbestos mining activities have left a legacy of environmental
                            contamination and ongoing health concerns, emphasizing the importance of continued
                            monitoring and remediation efforts.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="wastewater-treatment-plants">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/wastewater-treatment-plants-near-you">
                            Wastewater Treatment Plants <GiWaterRecycling className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Despite wastewater treatment efforts, there are inherent risks associated with potential
                            contamination hazards, posing threats to aquatic ecosystems and human health through
                            waterborne pathways. Inadequate wastewater treatment may lead to the dissemination of
                            pathogens, increasing the risk of waterborne diseases and impacting public health. Residual
                            chemicals in treated wastewater pose risks to the environment and public health, with
                            concerns about long-term effects and bioaccumulation in the food chain. The evolving
                            landscape of contaminants, including pharmaceuticals and emerging pollutants, presents
                            ongoing challenges for wastewater treatment plants in effectively mitigating risks to water
                            quality and ecosystem health. Aging or inadequate infrastructure increases the vulnerability
                            of wastewater treatment systems, raising the risk of system failures and compromising
                            pollution control measures.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Contamination Hazards:</b> Despite wastewater treatment efforts, the risk of
                                contamination persists, with the potential release of pollutants into water bodies,
                                posing threats to aquatic ecosystems and potentially affecting human health through
                                waterborne pathways.
                            </li>

                            <li className="info-list-item">
                                <b>Pathogen Dissemination:</b> Inadequate wastewater treatment may lead to the
                                dissemination of pathogens, increasing the risk of waterborne diseases and impacting
                                public health, particularly in communities relying on untreated or poorly treated water
                                sources.
                            </li>

                            <li className="info-list-item">
                                <b>Chemical Residue:</b> The presence of residual chemicals in treated wastewater poses
                                risks to the environment and public health, with the potential for long-term effects on
                                ecosystems and concerns about the bioaccumulation of contaminants in the food chain.
                            </li>

                            <li className="info-list-item">
                                <b>Emerging Contaminants:</b> The evolving landscape of contaminants, including
                                pharmaceuticals and emerging pollutants, presents a challenge for wastewater treatment
                                plants, requiring constant adaptation to effectively mitigate risks to water quality and
                                ecosystem health.
                            </li>

                            <li className="info-list-item">
                                <b>Infrastructure Vulnerability:</b> Aging or inadequate wastewater treatment
                                infrastructure increases the risk of system failures, leading to untreated or partially
                                treated wastewater discharges and compromising the effectiveness of pollution control
                                measures.
                            </li>
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="former-defense-sites">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/formerly-used-defense-sites-near-you">
                            Formerly Used Defense Sites <GiMilitaryFort className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Contaminated Soil and Water:</b> Former defense sites often carry the risk of
                                contaminated soil and water due to the historical use of hazardous materials,
                                explosives, and weaponry, posing risks to ecosystems and potentially impacting local
                                water supplies.
                            </li>

                            <li className="info-list-item">
                                <b>Unexploded Ordnance:</b> The presence of unexploded ordnance and munitions on former
                                defense sites poses a significant safety risk to both the environment and nearby
                                communities, requiring careful management and remediation efforts.
                            </li>

                            <li className="info-list-item">
                                <b>Risk of Soil and Air Pollution:</b> The legacy of military activities may result in
                                soil and air pollution, with the potential release of hazardous substances, heavy
                                metals, and pollutants, impacting both environmental health and the well-being of
                                surrounding communities.
                            </li>

                            <li className="info-list-item">
                                <b>Radioactive Contamination:</b> Some former defense sites may have been involved in
                                nuclear activities, leading to the risk of radioactive contamination. Proper management
                                and cleanup are essential to prevent long-term environmental and public health
                                consequences.
                            </li>

                            <li className="info-list-item">
                                <b>Community Health Concerns:</b> The proximity of communities to former defense sites
                                raises concerns about potential health risks, necessitating thorough assessments,
                                remediation strategies, and community engagement to address and alleviate public health
                                concerns.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Former defense sites present various environmental and health risks, including contaminated
                            soil and water due to historical military activities. The presence of unexploded ordnance
                            poses safety risks, requiring careful management. The legacy of military activities may
                            result in soil and air pollution, impacting both environmental health and nearby
                            communities. Some sites may have been involved in nuclear activities, leading to the risk of
                            radioactive contamination, requiring proper cleanup to prevent long-term consequences. The
                            proximity of communities raises health concerns, necessitating thorough assessments,
                            remediation strategies, and community engagement to address public health risks associated
                            with former defense sites.
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div">
                <section className="info-section" id="cell-towers">
                    <h2 className="info-section-h2 left-h2">
                        <a className="category-info-page-link" href="/cellular-towers-near-you">
                            Cell Towers <MdOutlineCellTower className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-object"></div>
                    <div className="info-block">
                        <div className="info-section-text left-section-text">
                            Cell towers present various considerations, including concerns about electromagnetic
                            radiation and potential health risks, although scientific consensus is still debated. The
                            visual impact of these structures, especially in residential areas, can lead to aesthetic
                            concerns and community opposition. Installation may result in land use and zoning conflicts
                            as communities balance the need for improved connectivity with preservation efforts.
                            Maintenance risks, including structural integrity and potential collapse, raise public
                            safety concerns. Effective community engagement is crucial for addressing these
                            considerations, fostering open communication, and allowing residents to voice their opinions
                            on the location, design, and potential impacts of cell towers.
                        </div>
                        <div className="info-object right-column">
                            <li className="info-list-item">
                                <b>Electromagnetic Radiation:</b> Cell towers emit electromagnetic radiation, raising
                                concerns about potential health risks for individuals living in close proximity to these
                                structures, although scientific consensus on this issue is still debated.
                            </li>

                            <li className="info-list-item">
                                <b>Visual Impact:</b> The visual impact of cell towers, especially in residential areas,
                                can lead to aesthetic concerns and may negatively impact property values, prompting
                                community opposition to their installation.
                            </li>

                            <li className="info-list-item">
                                <b>Land Use and Zoning Issues:</b> The installation of cell towers may result in land
                                use and zoning conflicts, as communities grapple with balancing the need for improved
                                connectivity with the desire to preserve the character of residential or environmentally
                                sensitive areas.
                            </li>

                            <li className="info-list-item">
                                <b>Infrastructure and Maintenance Risks:</b> Cell towers require regular maintenance,
                                and their structural integrity poses potential risks, including the possibility of
                                collapse or damage during extreme weather events, which could impact public safety.
                            </li>

                            <li className="info-list-item">
                                <b>Community Engagement:</b> Effective community engagement is crucial for addressing
                                concerns related to cell towers, fostering open communication, and allowing residents to
                                voice their opinions on the location, design, and potential impacts of these structures.
                            </li>
                        </div>
                    </div>
                </section>
            </div>

            <div className="info-div gray-background">
                <section className="info-section" id="transmission-lines">
                    <h2 className="info-section-h2 right-h2">
                        <a className="category-info-page-link" href="/transmission-lines-near-you">
                            Transmission Lines (EMF) <GiMagnet className="info-icon" />
                        </a>
                    </h2>
                    <div className="info-block">
                        <div className="info-object">
                            <li className="info-list-item">
                                <b>Electromagnetic Fields (EMF):</b> Transmission lines generate electromagnetic fields
                                (EMF), and there is ongoing debate and research about potential health risks associated
                                with prolonged exposure to high levels of EMF, particularly in residential areas located
                                near these lines.
                            </li>

                            <li className="info-list-item">
                                <b>Visual Impact:</b> Transmission lines, especially large high-voltage ones, can have a
                                significant visual impact on the landscape, affecting the aesthetics of the surrounding
                                environment and potentially impacting property values in the vicinity.
                            </li>

                            <li className="info-list-item">
                                <b>Land Use and Zoning Conflicts:</b> The installation of transmission lines may lead to
                                conflicts over land use and zoning regulations, as communities seek to balance the need
                                for reliable energy infrastructure with concerns about preserving the character of
                                residential and environmentally sensitive areas.
                            </li>

                            <li className="info-list-item">
                                <b>Environmental Impact:</b> The construction and maintenance of transmission lines can
                                have environmental consequences, including habitat disruption, deforestation, and
                                impacts on wildlife, which may require careful consideration and mitigation efforts.
                            </li>

                            <li className="info-list-item">
                                <b>Community Awareness and Engagement:</b> Raising community awareness about the
                                potential impacts of transmission lines and fostering engagement in decision-making
                                processes are crucial for addressing concerns, providing information, and incorporating
                                local perspectives into infrastructure planning.
                            </li>
                        </div>
                        <div className="info-section-text right-column">
                            Transmission lines, particularly concerning electromagnetic fields (EMF), prompt ongoing
                            debates about potential health risks from prolonged exposure. The visual impact of these
                            lines, especially high-voltage ones, can affect the aesthetics of the landscape and property
                            values. Installation may lead to land use and zoning conflicts as communities seek to
                            balance energy infrastructure needs with environmental preservation. Environmental impacts,
                            including habitat disruption and deforestation, necessitate careful consideration and
                            mitigation efforts. Community awareness and engagement are crucial for addressing concerns,
                            providing information, and incorporating local perspectives into decision-making processes
                            related to the installation and maintenance of transmission lines.
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default HealthRisks;
