import { Helmet } from "react-helmet";
import "./Terms.css";

function Terms() {
    return (
        <div className="terms-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms of Service | EnviroHomePro</title>
                <meta name="description" content="EnviroHomePro's Terms and Conditions of Service." />{" "}
            </Helmet>
            <div className="">
                {/* <h1 className="home-page-h1"> */}
                <h1 className="terms-header">Terms and Conditions of Service</h1>
                <p className="terms-description">
                    Welcome to EnviroHomePro.com (the “Website”). Environmental Data Solutions, LLC (“EnviroHomePro”,
                    “us”, “we”, or “our”) provides online services through the Website, including, but not limited to
                    access to software, object code, executable code, computer programs, products, services, tools,
                    interfaces, portals, and related features (collectively, the “Platform”) which in turn provide
                    access to databases, documents, text, user guides, supporting documentation, and other materials
                    (collectively “Accessible Content”) together with other materials that are provided by way of the
                    Website (together with Accessible Content, collectively, “Content”).
                </p>
                <ol className="term-number">
                    <li className="terms-item">
                        <strong> Service Conditions</strong>.
                    </li>
                </ol>
                <p className="terms-description">
                    By accessing or using the online services provided by EnviroHomePro, the Website, Platform,
                    Accessible Content, or other Content (collectively, the “Services”), the individual and/or entity
                    accessing or using such online services (“you”, “your”, or “Subscriber”) and each User hereby agrees
                    to be bound to and to abide by these Terms of Service (these “Terms”) and our Privacy Policy.
                </p>
                <ol className="term-number" start="2">
                    <li className="terms-item">
                        <strong> Access</strong>.
                    </li>
                </ol>
                <p className="terms-description">
                    (a) Users. The Platform is password protected so that the Platform and the Accessible Content may
                    only be accessed by way of a username and password activated by EnviroHomePro (“Credentials”). Only
                    individuals that are employees, temporary employees, partners/members of Subscriber, or contractors
                    dedicated to performing work exclusively for Subscriber, are eligible to receive Credentials to use
                    and access the Services (each an “Eligible Individual”). EnviroHomePro and/or Subscriber shall issue
                    Credentials only to Eligible Individuals (collectively, “Users” and each, a “User”). Subscriber and
                    each User agrees that Credentials may only be used by the User to whom it is assigned to and that
                    the Credentials may not be shared with or used by any other individual or entity. Subscriber agrees
                    that Subscriber is responsible for all use of the Services accessed with Credentials issued to
                    Users. Subscriber agrees to use its best efforts to prevent unauthorized use of Credentials assigned
                    to Users and will promptly notify EnviroHomePro if Subscriber suspects that any Credentials are
                    lost, stolen, compromised, or misused and/or if there has been any use of the of the Services that
                    is not authorized by these Terms.
                </p>
                <p className="terms-description">
                    (b) Registration and Credentials. In connection with the Services, Subscriber and each User agrees
                    to provide true, accurate, current and complete information, and to maintain and promptly update the
                    information provided to us in order to keep its information true, accurate, current and complete.
                </p>
                <ol className="term-number" start="3">
                    <li className="terms-item">
                        <strong> Rights and Restrictions</strong>.
                    </li>
                </ol>
                <p className="terms-description">
                    (a) Grant. Subject to the terms and conditions of these Terms, EnviroHomePro hereby grants
                    Subscriber a non-exclusive, non-transferable, non-sublicensable, fee-bearing, limited right to
                    access and use the Services within the United States of America in the ordinary course of
                    Subscriber&rsquo;s business or for Subscriber&rsquo;s personal use. Subject to the terms and
                    conditions of these Terms, the rights granted hereunder provides each User the limited right to: (i)
                    access the Platform; (ii) access, download, view, use, and create derivative works of the Accessible
                    Content; (iii) distribute the Accessible Content internally and externally in support of
                    Subscriber&rsquo;s ordinary course of business or for Subscriber&rsquo;s personal use; and/or (iv)
                    store the Accessible Content in machine-readable form using equipment owned or controlled Subscriber
                    and/or Users.
                </p>
                <p className="terms-description">
                    (b) Term of Rights. The term of the right to use and access Services granted under this Section 3
                    shall commence on the subscription start date and shall terminate on the date identified in writing
                    at the time of the subscription to the Services.
                </p>
                <p className="terms-description">
                    (c) Restrictions. Except as otherwise provided in these Terms, Subscriber shall not and each User
                    shall not: (i) license, sublicense, sell, resell, rent, loan, lease, transfer, assign, distribute,
                    provide for service bureau use, or otherwise make available to any third party all or any portion of
                    the Services and/or of the Content; (ii) alter, reverse engineer, decompile, disassemble, copy or
                    otherwise attempt to derive the source code for, the Services; (iii) remove or alter any copyright
                    or other notice contained in the Accessible Content; (iv) access the Services or collect Content
                    from the Services using any automated means, including, but not limited to, robots, spiders,
                    scrapers or offline readers; (v) store Content in an archival database or other searchable database;
                    (iv) access, use, store, or download Accessible Content to compete with EnviroHomePro and/or with
                    the Services; and/or (vii) emulate, copy, refer to or otherwise use any portion of the Services
                    and/or of the Content as part of any effort to develop or commercialize any product or service
                    having any functional attributes, visual expressions, or other features similar to those of the
                    Services or to compete with EnviroHomePro and/or with the Services.
                </p>
                <p className="terms-description">
                    (d) Ownership and Reservation. Subscriber and each User agrees EnviroHomePro owns all aspects of the
                    Platform and Website and shall retain all patent, copyright, trademark, trade dress, trade name,
                    moral rights, trade secret and other intellectual property rights in and to in all portions of the
                    Platform and/or Website, all object code and source code relating to the Platform and/or Website,
                    all the features of the Platform and/or Website, all elements of the look-and-feel of the Platform
                    and/or Website, all design features involving the presentation of Content, and all modifications,
                    revisions, updates, releases, refinements, improvements, and enhancements related to any of the
                    forgoing. Neither Subscriber nor any User acquires any rights of ownership or title in or to any
                    portion of the Services. EnviroHomePro and its licensors retain all rights, title, and interest in
                    and to the Services not expressly granted hereunder. Subscriber and each User agree not to take any
                    action inconsistent with such title and ownership.
                </p>
                <ol className="term-number" start="4">
                    <li className="terms-item">
                        <strong> Fees and Payment</strong>.
                    </li>
                </ol>
                <p className="terms-description">
                    (a) Fees. In consideration of the rights granted to Subscriber hereunder, Subscriber agrees to pay
                    to EnviroHomePro the fees charged by EnviroHomePro in order for Subscriber to access the Accessible
                    Content. Except as otherwise provided in these Terms, all fees shall be nonrefundable. All fees
                    shall be due and payable by Subscriber in U.S. dollars.
                </p>
                <p className="terms-description">
                    (b) Taxes. All payments required by these Terms are exclusive of federal, state, local and foreign
                    taxes, duties, tariffs, levies and similar assessments and Subscriber agrees to bear and be
                    responsible for the payment of all such charges imposed, excluding taxes based upon
                    EnviroHomePro&rsquo;s income and/or corporate franchise taxes.
                </p>
                <p className="terms-description">
                    (c) Refunds. We have the right, but no obligation, to provide refunds of fees paid to us by
                    Subscriber in connection with the Services, as determined solely by us. If we determine, in our sole
                    discretion, to provide Subscriber with a refund of fees paid by Subscriber in connection with the
                    Services, we will provide Subscriber with a pro rata refund based on the number of days remaining on
                    Subscriber&rsquo;s right to use and access the Platform, as determined exclusively by us.
                </p>
                <ol className="term-number" start="5">
                    <li className="terms-item">
                        <strong> Intellectual Property</strong>
                    </li>
                </ol>
                <p className="terms-description">
                    (a) Trademarks. ENVIROHOMEPRO and all other graphics, logos, page headers, button icons, scripts,
                    service names and other materials that we use, manage or control are trademarks, registered
                    trademarks or trade dress of ours or our subsidiaries, officers, employees, independent contractors,
                    suppliers, representatives, licensors, licensees, successors, assigns, agents, partners, or other
                    affiliate (collectively “Affiliates”) in the United States or other countries or both. No individual
                    or entity may use our trademarks without our express written permission which we may withhold in our
                    sole discretion. All other trademarks that appear on the Services are the property of their
                    respective owners, who may or may not be affiliated with, connected to or sponsored by us or any of
                    our Affiliates.
                </p>
                <p className="terms-description">
                    (b) Copyright. Except in the case of materials under license to us, we claim a copyright and all
                    copyright protection afforded, under international, United States and the State of Florida laws to
                    all Content on the Services, including, but not limited to all text, graphics, logos, button icons,
                    images, audio clips, and video. Access to all information and Content located on the Services is
                    strictly permitted through the rights granted by these Terms. Except as permitted by these Terms,
                    Subscriber and each User is prohibited from modifying, copying, distributing, displaying,
                    publishing, selling, licensing, creating derivative works, or otherwise exploiting any Content
                    available on or through the Services without our prior written permission, or in the case of Content
                    owned by a third party, without first receiving permission from the owner of that Content.
                </p>
                <ol className="term-number" start="6">
                    <li className="terms-item">
                        <strong> Additional Restrictions</strong>. In addition to the restrictions set forth in Section
                        3, Subscriber and each User shall not: (a) infringe on the copyrights or other intellectual
                        property rights of EnviroHomePro or a third party (b) copy, distribute, or modify any part of
                        the Services without our prior written authorization; (c) manipulate or exclude identifiers in
                        order to disguise the origin of any Content; (d) disrupt the networks connected to the Services,
                        including but not limited to by: attempting to probe, scan or test the vulnerability of the
                        Services, attempting to breach security or authentication measures without proper authorization,
                        or attempting to interfere with the Services or a User, by means such as overloading,
                        &lsquo;flooding&rsquo;, &lsquo;mailbombing&rsquo; or &lsquo;crashing&rsquo;; (e) circumvent,
                        disable or otherwise interfere with security-related features of the Services or features that
                        prevent or restrict use limitations on the Services; (f) remove, alter or obscure any
                        proprietary notice or identification, including copyright, trademark, patent or other notices
                        displayed on the Services; or (g) use the Services to violate any applicable laws, rules or
                        regulations, or for any unlawful, harmful, or inappropriate purpose, or in any manner that
                        breaches these Terms or is otherwise objectionable, as determined by us in our sole discretion.
                    </li>
                </ol>

                <ol className="term-number" start="7">
                    <li className="terms-item">
                        <strong> Termination, Restriction, and Suspension</strong>.
                    </li>
                </ol>
                <p className="terms-description">
                    (a) EnviroHomePro may: (i) terminate any Credentials, and/or any and all rights granted by these
                    Terms, in whole or in part, on ten (10) days&rsquo; advanced written notice to Subscriber upon
                    breach of any provision of these Terms other than a provision set forth in Section 3 by Subscriber
                    and/or any User; (ii) immediately terminate any Credentials, and/or any and all rights granted by
                    these Terms, in whole or in part, upon the insolvency, institution of bankruptcy, liquidation
                    proceedings (by or against Subscriber), appointment of a trustee or receiver for Subscriber&rsquo;s
                    property or business, and/or any assignment, reorganization or arrangement by Subscriber for the
                    benefit of its creditors; and/or (iii) immediately terminate any Credentials, and/or any and all
                    rights granted by these Terms, in whole or in part, upon breach of any provision set forth in
                    Section 3 of these terms by Subscriber and/or any User.
                </p>
                <p className="terms-description">
                    (b) After Termination. Upon expiration of the term of the order: (i) the rights to use and access
                    the Services granted under Section 3, and all other rights and licenses granted by EnviroHomePro to
                    Subscriber (and each User) set forth in these Terms, shall terminate immediately, (ii) Subscriber
                    and each User shall immediately cease using and/or accessing the Platform, and (ii) EnviroHomePro
                    may deactivate all Credentials and/or take such other actions as EnviroHomePro may determine in its
                    sole discretion is desirable to prevent Subscriber and/or each User from using or accessing the
                    Services. See our Privacy Policy for more information regarding storage of Personal Data which was
                    collected by us.
                </p>
                <p className="terms-description">
                    (c) EnviroHomePro may, without advanced notice to Subscriber, temporally suspend or discontinue
                    providing access to the Platform and/or any Accessible Content in the event that any User breaches,
                    or in the event that EnviroHomePro has reason to believe (supported by reasonably sufficient
                    evidence) that any User has breached any provision of these Terms, in addition to any other remedies
                    available to EnviroHomePro at law or equity. For the avoidance of doubt, any such suspension or
                    discontinuance of access to the Platform and/or any Accessible Content shall only apply to any such
                    individual Users that have breached, or are believed to have breached any provision of these Terms.
                </p>
                <ol className="term-number" start="8">
                    <li className="terms-item">
                        <strong> DISCLAIMERS</strong>.
                    </li>
                </ol>

                <p className="terms-description">
                    (a) Statements. THE SERVICES ARE NOT, AND ARE NOT INTENDED TO BE CONSTRUED AS, LEGAL, FINANCIAL,
                    INVESTMENT, ACCOUNTING, REAL ESTATE, TAX, OR OTHER ADVICE. THE SERVICES ARE A RESOURCE ONLY.
                    EnviroHomePro is not an Investment Adviser (as that term is defined by the Investment Advisers Act
                    of 1940, as amended), a financial adviser, a law firm, a tax firm, an accounting firm, a real estate
                    brokerage firm, or any other provider of advice. The Services do not constitute legal advertising or
                    solicitation. None of the availability, operation, transmission, receipt nor use of the Services is
                    intended to create, or constitutes formation of, an attorney-client relationship, real estate
                    agent-client or any other special relationship or privilege. &nbsp;ENVIROHOMEPRO STRONGLY ENCOURAGES
                    THE SUBSCRIBER AND EACH USER TO SEEK THE ADVICE OF THE APPROPRIATE PROFESSIONAL IN THE APPLICABLE
                    JURISDICTION PRIOR TO USING THE SERVICES, AND SUBSCRIBER AND EACH USER HEREBY ASSUMES THE RISKS
                    ASSOCIATED WITH ANY FAILURE TO DO THE FOREGOING.
                </p>
                <p className="terms-description">
                    (b) Services. THE SERVICES AND ALL THIRD PARTY CONTENT ARE PROVIDED &lsquo;AS IS&rsquo;, &lsquo;AS
                    AVAILABLE&rsquo; AND &lsquo;WITH ALL FAULTS&rsquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
                    IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF PERFORMANCE, MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, ACCURACY, SECURITY, QUALITY, OMISSIONS, COMPLETENESS, CURRENTNESS, TIMELINESS,
                    NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. SUBSCRIBER
                    AND EACH USER ASSUMES THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE
                    SERVICES AND ANY THIRD PARTY CONTENT. ENVIROHOMEPRO MAKES NO WARRANTY THAT THE SERVICES AND/OR THIRD
                    PARTY CONTENT WILL MEET SUBSCRIBER&rsquo;S OR A USER&rsquo;S NEEDS OR THAT THE SERVICES WILL BE
                    AVAILABLE ON AN UNINTERRUPTED, ERROR-FREE, OR BUG-FREE BASIS, OR REGARDING THE RELIABILITY OF ANY
                    CONTENT, THIRD PARTY CONTENT, OR ANY PART OF THE SERVICES.<strong>&nbsp;</strong>
                </p>

                <ol className="term-number" start="9">
                    <li className="terms-item">
                        <strong> Limitation of Liability</strong>. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN
                        NO EVENT SHALL ENVIROHOMEPRO BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY,
                        PUNITIVE OR SPECIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO LOST PROFITS, REGARDLESS OF THE FORM
                        OF THE ACTION OR THE THEORY OF RECOVERY, EVEN IF ENVIROHOMEPRO HAS BEEN ADVISED OF THE
                        POSSIBILITY OF THOSE DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                        SHALL THE TOTAL LIABILITY OF ENVIROHOMEPRO TO SUBSCRIBER, A USER, AND/OR ANY THIRD PARTY
                        RELATING TO ANY ASPECT OF THESE TERMS, THE PLATFORM, THE CONTENT, THE ACCESSIBLE CONTENT, AND/OR
                        THIRD PARTY CONTENT, HOWEVER CAUSED, REGARDLESS OF THE FORM OF THE ACTION OR THE THEORY OF
                        RECOVERY, EXCEED AN AMOUNT EQUAL TO THE LESSER OF (A) THE TOTAL AMOUNT OF FEES ACTUALLY PAID TO
                        ENVIROHOMEPRO BY SUBSCRIBER HEREUNDER, OR (B) ONE THOUSAND AND 00/100 DOLLARS ($1,000.00). THE
                        FORGOING LIMITATION OF LABILITY SHALL APPLY EVEN IF ENVIROHOMEPRO HAS BEEN ADVISED OF THE
                        POSSIBILITY OF THOSE DAMAGES AND EVEN IF THIS LIMITED REMEDY FAILS ITS ESSENTIAL PURPOSE.
                    </li>
                </ol>

                <ol className="term-number" start="10">
                    <li className="terms-item">
                        <strong> Dispute Resolution</strong>.
                    </li>
                </ol>
                <p className="terms-description">
                    (a) For purposes of any claim, suit, action or proceedings arising out of or relating to these
                    Terms, our Privacy Policy, or any aspect of the Services, each of the parties hereby irrevocably
                    submits to the exclusive jurisdiction of and venue in the federal and state courts located in the
                    County of [&bull;] in the State of Florida.
                </p>
                <p className="terms-description">
                    (b) SUBSCRIBER AND EACH USER AGREES THAT IT MAY BRING CLAIMS AGAINST US ONLY ON AN INDIVIDUAL BASIS
                    AND NOT AS A PLAINTIFF IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS WE
                    AGREE OTHERWISE IN WRITING, THE DECISION-MAKER MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE
                    PERSON&rsquo;S OR PARTY&rsquo;S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                    CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE DECISION-MAKER MAY AWARD RELIEF ONLY TO
                    THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY&rsquo;S INDIVIDUAL CLAIMS.
                </p>
                <ol className="term-number" start="11">
                    <li className="terms-item">
                        <strong> Indemnification</strong>. Subscriber agrees to save, defend, indemnify, and hold
                        harmless EnviroHomePro and each of our affiliates, directors, officers, managers, members,
                        shareholders, employees, agents, independent contractors, licensors, licensees, successors and
                        permitted assigns, from and against, any and all liabilities, obligations, claims, demands,
                        losses, expenses, actions, causes, suits, debts, sums of money, accounts, reckonings, bonds,
                        bills, specialties, covenants, controversies, agreements, promises, damages, judgments,
                        executions, costs and expenses whatsoever, including, but not limited to court costs and
                        reasonable attorneys&rsquo; fees, at law or in equity, now existing or hereafter arising,
                        foreseeable and unforeseeable, insured and uninsured, resulting from or arising out of or
                        relating to: (a) any breach or alleged breach of any of Subscriber&rsquo;s and/or each
                        User&rsquo;s warranties, representations, covenants or obligations to us these Terms, (b) any
                        violation or alleged violation of any rights of a third party or our rights by Subscriber and/or
                        any User relating to these Terms, including, but not limited to intellectual property rights,
                        (c) use of the Website, Platform, Accessible Content, and/or Third Party Content, by Subscriber
                        and/or any User, and/or (d) violation of any applicable law, rule, regulation, ordnance, or
                        treaty relating to the Services by Subscriber and/or any User.
                    </li>
                </ol>

                <ol className="term-number" start="12">
                    <li className="terms-item">
                        <strong> Assignment</strong>. Neither Subscriber nor any User may assign any rights or delegate
                        any obligations or rights by these Terms without the prior written consent of EnviroHomePro,
                        which consent may be withheld in EnviroHomePro&rsquo;s discretion. We may assign these Terms
                        and/or any obligations of ours under these Terms, in whole or in part, for any reason or no
                        reason, as determined solely by us.
                    </li>
                </ol>

                <ol className="term-number" start="13">
                    <li className="terms-item">
                        <strong> Updates </strong>. Notwithstanding any provision of these Terms to the contrary,
                        EnviroHomePro may modify or otherwise remove any feature of the Platform and/or the Services at
                        any time, as determined solely by EnviroHomePro, without compensation to Subscriber or any User
                        or off-set of amounts owed by Subscriber to EnviroHomePro hereunder. EnviroHomePro shall have no
                        obligation to update, alter, or modify the Services or any portion of the Platform.
                    </li>
                </ol>

                <ol className="term-number" start="14">
                    <li className="terms-item">
                        <strong> Export Control</strong>. The Services and certain technical information that may be
                        provided in connection with these Terms are subject to United States of America export control
                        laws. Subscriber and each User represents and warrants to EnviroHomePro that it is now and will
                        remain compliant with all export control statutes and regulations of the United States of
                        America and of any country in which Subscriber conducts its business. This includes, but is not
                        limited to, the United States Export Administration Act, and any other national or international
                        export laws, restrictions, or regulations that apply to the Services.
                    </li>
                </ol>

                <ol className="term-number" start="15">
                    <li className="terms-item">
                        <strong> Force Majeure</strong>. Notwithstanding any provision of these Terms to the contrary,
                        any delay or failure of Subscriber or EnviroHomePro to perform its obligations, other than
                        Subscriber&rsquo;s obligation to remit payment to EnviroHomePro according to the terms hereof,
                        will be excused if and to the extent that it is caused by an event or occurrence beyond the
                        reasonable control of the party and without its fault or negligence, including, but not limited
                        to: acts of God; restrictions, prohibitions, priorities or allocations imposed or actions taken
                        by a governmental authority; pandemics or epidemics (including COVID-19), embargoes; fires;
                        explosions; natural disasters; riots; wars; sabotage; inability to obtain power; or court
                        injunction or order.
                    </li>
                </ol>

                <ol className="term-number" start="16">
                    <li className="terms-item">
                        <strong> Survival</strong>. The terms of the provisions contained in Sections 3(d), 4, 5, 7
                        through 14, and 16 through 29, as well as the terms of all provisions of these Terms which in
                        order to give effect to their meaning need to survive termination or expiration of these Terms,
                        any Credentials, and/or any and all rights granted by these Terms shall survive any such
                        termination or expiration.
                    </li>
                </ol>

                <ol className="term-number" start="17">
                    <li className="terms-item">
                        <strong> Entire Agreement</strong>. These Terms, our Privacy Policy and any other terms
                        incorporated herein by reference constitutes the entire agreement between EnviroHomePro and
                        Subscriber and each User with respect to the subject matter hereof and supersedes and cancels
                        all prior agreements and understandings, whether written and oral, with respect thereto.
                    </li>
                </ol>

                <ol className="term-number" start="18">
                    <li className="terms-item">
                        <strong> Amendment and No Implied Waiver</strong>. Subscriber agrees that we may amend, modify,
                        or alter these Terms and/or our Polices at any time in our sole discretion. We will notify
                        Subscriber about changes to these Terms by placing the updated Terms on the Website. Subscriber
                        and each User agrees that its use of the Services after such notification will constitute
                        acceptance of such changes to the Terms. Waiver of any breach of any provision of these Terms by
                        the other party shall not operate, or be construed, as a waiver of any subsequent or other
                        breach.
                    </li>
                </ol>

                <ol className="term-number" start="19">
                    <li className="terms-item">
                        <strong> No Agency</strong>. EnviroHomePro and Subscriber are independent contractors with
                        respect to each other, and nothing herein shall create any association, partnership, joint
                        venture or agency relationship between them.
                    </li>
                </ol>

                <ol className="term-number" start="20">
                    <li className="terms-item">
                        <strong> Severability</strong>. If any provision of these Terms is inoperative or unenforceable
                        for any reason in any jurisdiction, such circumstances shall not have the effect of rendering
                        the provision in question inoperative or unenforceable in any other case, circumstance or
                        jurisdiction, or of rendering any other provision or provisions herein contained invalid,
                        inoperative, or unenforceable to any extent whatsoever. The invalidity of any one or more
                        phrases, sentences, clauses, sections or subsections of these Terms in any jurisdiction shall
                        not affect the remaining portions in such jurisdiction or in any other jurisdiction.
                    </li>
                </ol>

                <ol className="term-number" start="21">
                    <li className="terms-item">
                        <strong> Waiver of Jury Trial</strong>. EACH PARTY HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVES
                        ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LITIGATION DIRECTLY OR INDIRECTLY
                        ARISING OUT OF OR RELATING TO THESE TERMS, OR THE BREACH, TERMINATION OR VALIDITY OF THESE
                        TERMS, OR THE TRANSACTIONS CONTEMPLATED BY THESE TERMS. EACH PARTY CERTIFIES THAT IT MAKES THIS
                        WAIVER VOLUNTARILY, AND IT HAS BEEN INDUCED TO ENTER INTO THESE TERMS BY, AMONG OTHER THINGS,
                        THE MUTUAL WAIVERS AND CERTIFICATIONS IN THIS SECTION 21.
                    </li>
                </ol>

                <ol className="term-number" start="22">
                    <li className="terms-item">
                        <strong> Governing Law and Situs of Services</strong>. Subscriber and each User agrees that: (a)
                        the Services shall be deemed solely based in [&bull;], Florida, and (b) the Services shall be
                        deemed passive which does not give rise to personal jurisdiction over us, either specific or
                        general, in jurisdictions other than [&bull;], Florida. These Terms and our Privacy Policy are
                        governed by the laws of the State of Florida and of the United States of America, and without
                        regard to conflicts of law principles.
                    </li>
                </ol>

                <ol className="term-number" start="23">
                    <li className="terms-item">
                        <strong> Equitable Relief</strong>. Subscriber and each User acknowledges that EnviroHomePro
                        will suffer irreparable harm as a result of the material breach by such party of any covenant or
                        obligation to be performed or observed by Subscriber by these Terms, and acknowledges that
                        EnviroHomePro shall be entitled to apply for and, if granted, receive from any court or
                        administrative body of competent jurisdiction a temporary restraining order, preliminary
                        injunction and/or permanent injunction, without any necessity of proving damages or posting bond
                        or other security, enjoining Subscriber from further breach of these Terms or further
                        infringement or impairment of the rights of EnviroHomePro.
                    </li>
                </ol>

                <ol className="term-number" start="24">
                    <li className="terms-item">
                        <strong> Construction</strong>. The headings contained in these Terms are for purposes of
                        convenience only and shall not affect the meaning or interpretation of these Terms. In the event
                        of any inconsistency or conflict between the provisions of these Terms and the provisions of our
                        Privacy Policy, these Terms shall prevail as to the subject matter of such inconsistency.
                    </li>
                </ol>

                <ol className="term-number" start="25">
                    <li className="terms-item">
                        <strong>Binding Effect</strong>. By accessing the Website or any portion of the Services, any
                        and all individuals and/or entities that are not Users or the Subscriber (i.e. visitors)
                        nevertheless hereby agree to be bound by Sections 3(c), 3(d), 5, 6, 7(c), 8 through 13, and 16
                        through 29 of these Terms as if they/it were the “Subscriber” and/or the “Owner” (as applicable)
                        and hereby represent and warrant that they/it has the authority and capacity to do so.
                    </li>
                </ol>

                <ol className="term-number" start="26">
                    <li className="terms-item">
                        <strong> Third Parties</strong>. From time to time, we may engage third parties or Affiliates to
                        assist us in providing certain aspects of the Services, including but not limited to marketing
                        functions. Subscriber and each User agrees that we may engage such third parties in providing
                        Services to Subscriber and/or any User, as determined by us.
                    </li>
                </ol>

                <ol className="term-number" start="27">
                    <li className="terms-item">
                        <strong> Privacy Policy</strong>. By using the Services, Subscriber and each User agrees to the
                        provisions of our Privacy Policy, which is hereby incorporated by reference. Subscriber and each
                        User agrees that we may disclose Personal Data (as defined in our Privacy Policy) according to
                        our Privacy Policy, as determined by us. Please see our Privacy Policy for more information.
                    </li>
                </ol>

                <ol className="term-number" start="28">
                    <li className="terms-item">
                        <strong> Confidentiality</strong>. EnviroHomePro may disclose to Subscriber and each User
                        information that EnviroHomePro considers to be confidential and/or proprietary (“Confidential
                        Information”). Notwithstanding any provision of these Terms to the contrary, “Confidential
                        Information” shall be deemed to include information relating to the Platform, the nature of the
                        Accessible Content, the amount of any fees paid to EnviroHomePro in connection with the Services
                        (unless such fees are the list price for the Services), and technical and business information
                        relating to EnviroHomePro&rsquo;s business operations, including, but not limited to inventions,
                        software, research and development, future product specifications, implementation methodologies,
                        engineering processes, costs, profit information, and marketing and future business plans.
                        Subscriber and each User agrees, unless required by law, not to use or make each
                        EnviroHomePro&rsquo;s Confidential Information available to any third party for any purpose
                        other than as necessary to perform by these Terms. Subscriber and each User agrees shall protect
                        the Confidential Information of EnviroHomePro by using no less than a reasonable degree of care
                        to prevent its unauthorized use, dissemination or publication by Users and/or its employees or
                        agents. “Confidential Information” shall not include information that: (a) is or becomes
                        publicly available through no act or omission of Subscriber and/or a User; (b) was in the lawful
                        possession Subscriber and/or a User prior to the disclosure by EnviroHomePro and was not
                        obtained by Subscriber and/or a User either directly or indirectly from EnviroHomePro; (c) is
                        lawfully disclosed to Subscriber and/or a User by a third party without restriction on further
                        disclosure, and where the discloser was not aware that the information was the Confidential
                        Information of EnviroHomePro; and/or (d) is independently developed by Subscriber and/or a User
                        without violation of these Terms. Notwithstanding any provision of these Terms to the contrary,
                        disclosure of Confidential Information shall not be precluded if such disclosure is: (x) in
                        response to a valid order of a court or other governmental body, provided, however, that
                        Subscriber and/or the applicable User shall first promptly provide EnviroHomePro written notice
                        of the order and make a reasonable effort to obtain a protective order requiring that the
                        Confidential Information so disclosed be used only for the purposes for which the order was
                        issued; (y) otherwise required by law; and/or (z) necessary to establish the rights of
                        Subscriber by these Terms.
                    </li>
                </ol>

                <ol className="term-number" start="29">
                    <li className="terms-item">
                        <strong> Notices</strong>. All notices required or permitted hereunder shall be in writing and
                        deemed sufficiently given for all purposes hereof if (a) delivered in person, by courier, or by
                        registered or certified United States Mail to the party to be notified, return receipt
                        requested, with a copy sent by electronic mail; or (b) sent by electronic mail, in each case to
                        the appropriate party at the addresses set forth below or such other address as a party may
                        provide to the other party according to this Section 30. Each notice: (x) sent by United States
                        Mail shall be deemed effective three (3) business days after being mailed; (y) delivered in
                        person or by courier shall be deemed effective on receipt by the receiving party; and (z) sent
                        by electronic mail shall be deemed effective upon transmission by the sending party (as
                        evidenced by the time and date stamp thereon); provided, however, that notice transmitted by
                        electronic mail after 5:00 p.m. at the location of the receiving party shall be deemed received
                        on the first business day following the date of such electronic transmission.
                    </li>
                </ol>
                <p className="terms-description">
                    <strong>&nbsp;</strong>
                </p>
                <p className="terms-description">
                    <strong>EnviroHomePro:</strong>
                </p>

                <p className="terms-address">Attn: Legal Department</p>
                <p className="terms-address">2336 SE Ocean Blvd #180</p>
                <p className="terms-address">Stuart, FL 34996</p>
                <p className="terms-address">info@envirohomepro.com</p>
                <p className="terms-address">Subscriber: As provided to EnviroHomePro.</p>

                <p className="terms-effective-date">Effective Date: January 1, 2024</p>
            </div>
        </div>
    );
}

export default Terms;
